import { Divider, Grid, Typography, styled } from "@mui/material";

import { useConfigSelector } from "../../Redux/utility/useSelector";

const CopyrightTypography = styled(Typography)(({ theme }) => ({
  color: "#55657e",
  fontSize: "12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "8px",
  },
}));

const Copyright = () => {
  const config = useConfigSelector();

  return (
    <Grid alignItems="center" container direction="column">
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        <CopyrightTypography variant="subtitle1">
          Copyright © {new Date().getFullYear()}{" "}
          {config?.siteSettings?.site_name}. All rights reserved.
        </CopyrightTypography>
      </Grid>
    </Grid>
  );
};

export default Copyright;
