import { useState } from "react";
import { useNavigate } from "react-router";
import { Avatar, Box, Chip, Grid, Typography, styled } from "@mui/material";

import diamondIcon from "../../../Assets/icons/diamond.png";
import profileIcon from "../../../Assets/icons/profile-icon.png";
import userWithStarIcon from "../../../Assets/icons/user-with-star.png";
import { CURRENCY_SYMBOL } from "../../../Constants";
import { handleApiError } from "../../../Helpers";
import useUserSelector from "../../../Redux/user/useSelector";
import useUtilityAction from "../../../Redux/utility/useAction";
import { useConfigSelector } from "../../../Redux/utility/useSelector";
import routes from "../../../Routes/Constants";
import userApi from "../../../Service/api/user";
import socket from "../../../Utilities/Websocket";
import { customTheme } from "../../../Utilities/theme";

import Button from "../../Common/Button";
import Modal, { ContentWrapper, SIZES } from "../../Common/Modal";
import GradientDivider from "../../Common/GradientDivider";
import GradientTypography from "../../Common/GradientTypography";

import { BorderLinearProgress } from "./ProfileDetails/ProfileHeader";

type LocalProps = {
  open: boolean;
  onClose: () => void;
};

function Rebates({ open, onClose }: LocalProps) {
  const { user } = useUserSelector();
  const { toggleSnackBar } = useUtilityAction();
  const config = useConfigSelector();
  const navigate = useNavigate();

  const [claiming, setClaiming] = useState(false);

  const _onEarnMore = (evt: any) => {
    evt.preventDefault();
    onClose();
    navigate(routes.VIP_RANKING);
  };

  const _onClaim = async () => {
    setClaiming(true);
    const { ok, data }: any = await userApi.claimRebates();
    if (ok) {
      socket.emit(config.webSocketEvents.UPDATE_USER_BALANCE);
      toggleSnackBar("success", "You successfully claimed your rebates");
    } else {
      toggleSnackBar("error", handleApiError(data));
    }
    setClaiming(false);
  };

  return (
    <Modal
      open={open}
      size={SIZES.MEDIUM}
      title="Rebates"
      onClose={onClose}
      withGradient
    >
      <ContentWrapper>
        <Grid container>
          <Grid alignItems="center" spacing={2} container item>
            <Grid
              container
              item
              xs={12}
              sm={3}
              direction="column"
              alignItems="center"
            >
              <Avatar
                alt="profile-photo"
                src={config?.siteAssets?.user_profile_image || profileIcon}
                sx={{ width: 80, height: 80, marginBottom: 2 }}
              />

              <Chip
                label={
                  <GradientTypography fontWeight="bold">
                    {user.username}
                  </GradientTypography>
                }
                style={{ border: `1px solid ${customTheme.basicBorderColor}` }}
                size="small"
              />
            </Grid>

            <Grid xs={12} sm={9} item>
              <Box display="flex" mb={2}>
                <Icon src={diamondIcon} />
                <GradientTypography mr={1}>VIP Rank Level:</GradientTypography>
                <Typography>{user.vip_benefits.level}</Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={2}
              >
                <Icon src={userWithStarIcon} />
                <GradientTypography mr={2}>EXP: </GradientTypography>
                <BorderLinearProgress
                  variant="determinate"
                  value={user.vip_benefits.nextProgress}
                />
                <Typography ml={2}>
                  {user.vip_benefits.nextProgress}%
                </Typography>
              </Box>
            </Grid>

            <Grid xs={12} item>
              <GradientDivider />
            </Grid>

            <Grid xs={12} sm={6} item>
              <CardContainer>
                <Typography
                  color={customTheme.basicBorderColor}
                  fontSize={25}
                  fontWeight="bold"
                >
                  {user.vip_benefits.rebates}
                </Typography>
                <Typography fontSize={12} color="#D9D9D980" mt={0.5} mb={1}>
                  Percentage Rebates
                </Typography>
                <Button
                  variant="contained"
                  onClick={_onEarnMore}
                  filled
                  fullWidth
                >
                  Earn More
                </Button>
              </CardContainer>
            </Grid>

            <Grid xs={12} sm={6} item>
              <CardContainer>
                <Typography
                  color={customTheme.basicBorderColor}
                  fontSize={25}
                  fontWeight="bold"
                >
                  {CURRENCY_SYMBOL} {user.unclaimed_rebates}
                </Typography>
                <Typography fontSize={12} color="#D9D9D980" mt={0.5} mb={1}>
                  Unclaimed Rebates
                </Typography>
                <Button
                  variant="contained"
                  onClick={_onClaim}
                  loading={claiming}
                  disabled={claiming}
                  filled
                  fullWidth
                >
                  Claim
                </Button>
              </CardContainer>
            </Grid>
          </Grid>
        </Grid>
      </ContentWrapper>
    </Modal>
  );
}

const CardContainer = styled("div")(({ theme }) => ({
  border: `1px solid ${customTheme.basicBorderColor}`,
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: 10,
  backgroundColor: customTheme.modalCardBg,
}));

const Icon = styled("img")(({ theme }) => ({
  width: 20,
  height: 20,
  marginRight: theme.spacing(1),
}));

export default Rebates;
