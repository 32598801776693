import { Box, Grid, Typography, styled } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import lodashIsEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";

import logo from "../../Assets/Logos/logo.png";

import { GAME_MAPPER } from "../../Constants/game";
import { resolveThirdPartyGameImage } from "../../Helpers";
import { useConfigSelector } from "../../Redux/utility/useSelector";
import Balance from "../Common/Balance";

import GradientTypography from "../Common/GradientTypography";
import useLiveBetsController from "./useController";

import recentWinnersIcon from "../../Assets/icons/trophy-icon.png";

const LiveBetsBar = () => {
  const config = useConfigSelector();
  const navigate = useNavigate();
  const [state] = useLiveBetsController();

  const defaultImage = config?.siteAssets?.web_logo || logo;

  const _renderBox = (data: any) => {
    if (lodashIsEmpty(data)) {
      return null;
    }

    const gameData = GAME_MAPPER[data.game_code as keyof typeof GAME_MAPPER];
    const gameImage = config?.siteAssets?.game_image?.[data.game_code]; // from site settings

    return (
      <BoxContainer onClick={() => navigate(`/${gameData.link}`)}>
        <Image
          src={
            gameImage ||
            gameData?.image ||
            resolveThirdPartyGameImage(defaultImage, data?.image)
          }
        />
        <TextContainer>
          <Typography>{data?.username}</Typography>
          <Typography>won</Typography>
          <Balance value={data.payout} normal />
        </TextContainer>
      </BoxContainer>
    );
  };

  return (
    <Grid container direction="column">
      {state.liveBets.length ? (
        <Grid container alignItems="center" item>
          <Grid item>
            <img
              height={20}
              width={20}
              src={recentWinnersIcon}
              alt="recent-winners-icon"
            />
          </Grid>
          <Grid item>
            <GradientTypography
              sx={{ marginBottom: "6px" }}
              fontSize={20}
              fontWeight="bold"
            >
              Recent Winners
            </GradientTypography>
          </Grid>
        </Grid>
      ) : null}

      <Grid item>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          maxWidth="90vw"
          overflow="auto"
          className="no-scroll"
        >
          <motion.div
            key={state.liveBets[0]?.bet_id}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 50 }}
            transition={{ duration: 1 }}
          >
            {_renderBox(state.liveBets[0])}
          </motion.div>

          <AnimatePresence>
            {state.liveBets.map((item: any, index: number) => (
              // {state.liveBets.slice(1).map((item: any, index: number) => (
              <motion.div
                key={item.bet_id}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
                transition={{ duration: 0.5 }}
              >
                {_renderBox(item)}
              </motion.div>
            ))}
          </AnimatePresence>
        </Box>
      </Grid>
    </Grid>
  );
};

const BoxContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  cursor: "pointer",
  height: 170,
  marginRight: 16,
  padding: 5,
  width: 150,
});

const TextContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  position: "relative",
  textShadow: "2px 2px 10px #000",
  width: "100%",
  overflow: "hidden",
});

const Image = styled("img")({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "contain",
  top: 0,
  opacity: 0.6,
  borderRadius: 10,
});

export default LiveBetsBar;
