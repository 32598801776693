import { Box, Grid, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import playButtonIcon from "../../Assets/icons/play-button.png";
import GradientTypography from "../../Components/Common/GradientTypography";
import { getMaintenanceMapper } from "../../Helpers";
import colors from "../../Utilities/colors";

import { useIsLoginSelector } from "../../Redux/user/useSelector";
import useUtilityAction, {
  LOGIN_SIGNUP_FORMS,
} from "../../Redux/utility/useAction";
import { useConfigSelector } from "../../Redux/utility/useSelector";
import themeStyles from "../../Utilities/styles";
import { customTheme } from "../../Utilities/theme";
import { resolveGameImage, resolveGamePlayer } from "./utils";
import Button from "../Common/Button";

interface Game {
  image: string;
  route: string;
  game_id?: number;
  vendor: string;
}

const Games = ({ games }: { games: any[] }) => {
  const [comingSoon, setComingSoon] = useState(false);
  const { setLoginSignupForm } = useUtilityAction();
  const navigate = useNavigate();
  const isLogin = useIsLoginSelector();
  const config = useConfigSelector();
  const gameProviderNames = config?.gameProviderNames;

  // const [isLoadingJiliGame, setIsLoadingJiliGame] = useState<boolean>(false);
  const maintenanceMapper = getMaintenanceMapper(config);

  const _goToGames = async (gameData: Game) => {
    const gamePlayerData: any = resolveGamePlayer(gameData);

    if (gameData.vendor) {
      // return setComingSoon(true);
      //eslint-disable-next-line
      if (!isLogin) {
        return setLoginSignupForm(LOGIN_SIGNUP_FORMS.SIGNIN);
      }

      return navigate(`/${gamePlayerData.route}`, {
        state: gamePlayerData.state,
      });
    } else {
      return navigate(`/${gameData.route}`);
    }
  };

  return (
    <Fragment>
      <Grid wrap="wrap" spacing={2} container>
        {games.length === 0 && (
          <Grid justifyContent="center" xs={12} container item>
            <Typography>No games to display.</Typography>
          </Grid>
        )}
        {games.slice(0, 20).map((game, index) => {
          // prettier-ignore
          const gameImage = resolveGameImage(game.vendor, game, gameProviderNames) ?? game.image
          // prettier-ignore
          const isGameMaintenance = game.isInhouseGame ? maintenanceMapper[game.gameCode] : false;
          // prettier-ignore
          const gameSettingsImage = config?.siteAssets?.game_image?.[game.gameCode]; // from site settings

          return (
            <Grid key={index} xs={true} item container justifyContent="center">
              <Grid position="relative" item>
                <StyleIconButton
                  onClick={() => _goToGames(game)}
                  disabled={isGameMaintenance}
                >
                  <GameImage
                    className="game-image"
                    src={
                      // prettier-ignore
                      game.isInhouseGame ? gameSettingsImage || game.image : gameImage
                    }
                    alt={`game-${index}`}
                    isJili={game.image ? false : true}
                  />
                  {/* Show play on StyledIconButton hover */}
                  <HoverPlay className="play">
                    {/* <PlayArrow fontSize="large" /> */}
                    <PlayIcon />
                    <GradientTypography fontWeight="bold">
                      Play
                    </GradientTypography>
                  </HoverPlay>
                  {isGameMaintenance && (
                    <Box style={themeStyles.underMaintenance(200)}>
                      <Typography fontWeight="bold" color="error">
                        Under
                      </Typography>
                      <Typography fontWeight="bold" color="error">
                        Maintenance
                      </Typography>
                    </Box>
                  )}
                </StyleIconButton>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
};

const GameImage = styled("img")<any>(({ theme, isJili }) => ({
  height: "200px",
  maxWidth: "140px",
  borderRadius: "15px",
  // transition: "scale 0.1s linear",
  [theme.breakpoints.down("sm")]: {
    height: "110px",
    maxWidth: "80px",
  },
}));

export const StyleIconButton = styled(IconButton)({
  position: "relative",
  "&:hover > .game-image": {
    scale: "1.1",
  },
  "&:hover > .play": {
    visibility: "visible",
    opacity: 1,
  },
});

export const HoverPlay = styled("div")({
  backgroundColor: `${colors.background}80`,
  transition: "opacity 0.5s linear",
  visibility: "hidden",
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  opacity: 0,
  scale: "1.1",
  border: "2px solid " + customTheme.basicBorderColor,
  borderRadius: "15px",
});

export const PlayIcon = styled("div")({
  background: `url("${playButtonIcon}")`,
  backgroundRepeat: "no-repeat",
  width: 27,
  height: 27,
  marginBottom: 8,
});

export default Games;
