import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

import { snakeKeysToCamelKeys } from "../../Helpers";

import { LOGIN_SIGNUP_FORMS } from "./constants";

export const utilitySlice = createSlice({
  name: "utility",
  initialState: {
    activeAuthForm: LOGIN_SIGNUP_FORMS.NONE, // for opening login and signup modal, empty means close
    showWalletModal: false, // for opening wallet modal to deposit or withdraw
    snackBar: {
      show: false,
      type: "",
      message: "",
    },
    config: {},
  },
  reducers: {
    hideSnackBar: (state) => {
      state.snackBar = {
        show: false,
        type: "",
        message: "",
      };
    },
    setConfig: (state, action) => {
      state.config = snakeKeysToCamelKeys(action.payload);
    },
    setLoginSignupModalForm: (state, action) => {
      state.activeAuthForm = action.payload;
    },
    showSnackBar: (state, action) => {
      state.snackBar = {
        show: true,
        type: action.payload.type,
        message: action.payload.message,
      };
    },
    toggleWalletModal: (state, action) => {
      if (typeof action.payload === "boolean") {
        state.showWalletModal = action.payload;
      } else {
        state.showWalletModal = !state.showWalletModal;
      }
    },
  },
});

export interface State {
  utility: {
    activeAuthForm: LOGIN_SIGNUP_FORMS;
    showWalletModal: boolean;
    snackBar: {
      show: boolean;
      type: AlertColor;
      message: string;
    };
    config: {
      defaultCommissionV2: number;
      depositGcashMaintenance: boolean;
      depositPaymayaMaintenance: boolean;
      depositV3PaymentStatuses: {
        EXPIRED: String;
        SUCCESS: String;
        WAITING: String;
        SUCCESS_DONE: String;
        FAILED: String;
      };
      freeSpinData: Array<any>;
      commissions: Array<{ min: number; max: number; percent: number }>;
      vipRanking: Array<{
        lvl: number;
        image: string;
        title: string;
        cashback: string;
        totalWager: number;
        freeSpin: number;
      }>;
      disableLimbo: boolean;
      disableWof: boolean;
      disableMines: boolean;
      gameCodes: { [key: string]: string };
      webSocketEvents: { [key: string]: string };
      gcashV2: {
        name: string;
        number: string;
        qr_code: string;
      };
      isMaintenance: boolean;
      minBet: number;
      maxBet: number;
      gameProviderNames: { [key: string]: string };
      mines: {
        data: any[][];
        legends: {
          bomb: number;
          diamond: number;
          showDiamond: number;
          showBomb: number;
        };
        multipliers: {
          [key: number]: number[];
        };
      };
      siteSettings: { [key: string]: string };
      siteAssets: {
        web_logo: string;
        mobile_logo: string;
        peso_icon: string;
        banner_above_image: string;
        vip_ranking_maskot: string;
        user_profile_image: string;
        auth_image: string;
        game_image: { [key: string]: string };
        game_side_image: { [key: string]: string };
        banner_right_content: {
          one: { text: string; image: string };
          two: { text: string; image: string };
          three: { text: string; image: string };
        };
        sliding_banners: string[];
        partners: string[];
      };
      wof: {
        colors: {
          "2": { name: string; value: string };
          "3": { name: string; value: string };
          "5": { name: string; value: string };
          "30": { name: string; value: string };
        };
        data: Array<any>;
      };
      mixedProviders: boolean;
    };
  };
  user: {
    data: any;
  };
}

export const {
  hideSnackBar,
  setConfig,
  setLoginSignupModalForm,
  showSnackBar,
  toggleWalletModal,
} = utilitySlice.actions;

export default utilitySlice.reducer;
