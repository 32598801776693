import { Grid } from "@mui/material";

export const createCountryOptionLabelRender = (country: string, icon?: any) => {
  return (
    <Grid container alignItems="center" gap={1}>
      {icon ? <Grid item>{icon}</Grid> : null}
      <Grid item>{country}</Grid>
    </Grid>
  );
};
export const checkSessionBeforeNavigate = (
  isLogin: Boolean,
  label: string,
  navigate: () => void,
  promptLogin: () => void
) => {
  switch (label) {
    case "Affiliates":
      return () => {
        if (!isLogin) {
          promptLogin();
        } else {
          navigate();
        }
      };
    default:
      return navigate;
  }
};
