import { Fragment, useEffect, useState } from "react";
import { Grid, Typography, styled, useMediaQuery } from "@mui/material";
import lodashFilter from "lodash/filter";

import affiliateLinkIcon from "../../Assets/icons/affiliate-link-icon.png";
import commisionsIcon from "../../Assets/icons/commisions-icon.png";
import copyIcon from "../../Assets/icons/copy-icon.png";
import Button from "../../Components/Common/Button";
import DataTable from "../../Components/Common/Datatable";
import GradientTypography from "../../Components/Common/GradientTypography";
import { commafyNumber, copyToClipboard, handleApiError } from "../../Helpers";
import useDatatablePagination from "../../Hooks/useDatatablePagination";
import useUserSelector from "../../Redux/user/useSelector";
import useUtilityAction from "../../Redux/utility/useAction";
import { useConfigSelector } from "../../Redux/utility/useSelector";
import userApi from "../../Service/api/user";
import { customTheme } from "../../Utilities/theme";
import socket from "../../Utilities/Websocket";

// import Card from "./Card";
// import SpecialOffer from "./SpecialOffer";
import { columns } from "./constants";

const statsGridProps = {
  display: "flex",
  flexDirection: "column" as any,
  alignItems: "center",
  xs: 12,
  sm: 6,
  md: 4,
  lg: 2,
  item: true,
};

const statsLabelProps = {
  textAlign: "center" as any,
  marginBottom: "12px",
  variant: "caption" as any,
};

const CustomBalanceRender = ({
  value,
  isMoney,
}: {
  value: string;
  isMoney?: boolean;
}) => {
  return (
    <GradientTypography fontWeight="bold" fontSize={20}>
      {isMoney ? "₱" : null}{" "}
      {isMoney ? commafyNumber(Number(value), true) : value}
    </GradientTypography>
  );
};

const LabelWithCustomIcon = ({
  label,
  iconSrc,
}: {
  label: string;
  iconSrc: string;
}) => {
  return (
    <GradientTypography
      fontWeight="bold"
      textTransform="capitalize"
      align="center"
      fontSize={12}
      style={{ display: "flex", alignItems: "center", gap: 8 }}
    >
      <img src={iconSrc} alt={label} /> <span>{label}</span>
    </GradientTypography>
  );
};

const AffiliateProgram = () => {
  const { isLogin, user } = useUserSelector();
  const { toggleSnackBar } = useUtilityAction();
  const config = useConfigSelector();
  const downMediumScreen = useMediaQuery("(max-width:900px)");
  const [state, handler] = useDatatablePagination((page: number) => {
    const params = [{ key: "page", value: page }];
    return userApi.getReferrals(params);
  });

  const [claiming, setClaiming] = useState(false);
  const [data, setData] = useState() as any;

  const referralLink = `${window.location.origin}/?ref=${user?.username}`;
  const active = data?.active || 0;
  const inActive = data?.inactive || 0;
  const totalDeposit = user?.total_deposit || 0;
  const totalEarned = user?.total_commissions || 0;
  const unclaimedBalance = user?.unclaimed_commissions || 0;
  const commissions = config?.commissions;

  useEffect(() => {
    if (isLogin) {
      _getReferralCounts();
    }
  }, [isLogin]);

  const _onCopy = () => {
    copyToClipboard(referralLink);
    toggleSnackBar("success", "Successfully copied to clipboard");
  };

  const _getReferralCounts = async () => {
    const { ok, data }: any = await userApi.getReferralCounts();
    if (ok) {
      setData(data);
    } else {
      toggleSnackBar("error", handleApiError(data));
    }
  };

  const _onClaim = async () => {
    setClaiming(true);
    const { ok, data }: any = await userApi.claimCommission();
    if (ok) {
      toggleSnackBar("success", "Successfully claimed the commission");
      socket.emit(config.webSocketEvents.UPDATE_USER_BALANCE);
    } else {
      toggleSnackBar("error", handleApiError(data));
    }
    setClaiming(false);
  };

  const _getCommission = () => {
    if (user?.commission_percent) {
      return `${user.commission_percent}%`;
    } else {
      // find all max that is greater than active
      const result = lodashFilter(config.commissions, (c) => c.max >= active);
      return `${result[0].percent}%`;
    }
  };

  if (!isLogin) {
    return null;
  }

  return (
    <Fragment>
      <MainGridContainer padding={{ xs: 0, sm: "24px", md: 0 }}>
        <Grid gap={{ xs: 2, lg: 0 }} container direction="column">
          {/* Title */}
          <Grid sx={{ marginBottom: 4 }} xs={12} item>
            <GradientTypography
              variant="h5"
              textAlign="center"
              textTransform="capitalize"
              fontWeight="bold"
            >
              Affiliate dashboard
            </GradientTypography>
          </Grid>

          {/* Stats row */}
          <Grid xs={12} item>
            <StatsContainer>
              <Grid
                alignItems="center"
                justifyContent="center"
                padding="16px"
                spacing={1}
                container
              >
                <Grid {...statsGridProps}>
                  <CustomBalanceRender isMoney value={totalDeposit || 0} />
                  <Typography {...statsLabelProps}>Total Deposits</Typography>
                </Grid>

                <Grid {...statsGridProps}>
                  <CustomBalanceRender isMoney value={totalEarned} />
                  <Typography {...statsLabelProps}>Total Earned</Typography>
                </Grid>

                <Grid {...statsGridProps}>
                  <CustomBalanceRender isMoney value={unclaimedBalance} />
                  <Typography {...statsLabelProps}>
                    Unclaimed Balance
                  </Typography>
                </Grid>

                <Grid {...statsGridProps}>
                  <CustomBalanceRender value={active + inActive || 0} />
                  <Typography {...statsLabelProps}>Total Affiliates</Typography>
                </Grid>

                <Grid {...statsGridProps}>
                  <CustomBalanceRender value={active || 0} />
                  <Typography {...statsLabelProps}>Active Users</Typography>
                </Grid>

                <Grid {...statsGridProps}>
                  <Button
                    variant="contained"
                    onClick={_onClaim}
                    loading={claiming}
                    disabled={claiming}
                    filled
                    fullWidth
                  >
                    Claim
                  </Button>
                </Grid>
              </Grid>
            </StatsContainer>
          </Grid>

          {/* Commission percent */}
          <Grid
            wrap={downMediumScreen ? "wrap" : "nowrap"}
            gap={2}
            container
            xs={12}
            item
          >
            {/* Commission Percent */}
            <Grid xs={12} md={6} item>
              <MyCommisionsContainer>
                <Typography fontWeight="bold" fontSize={20}>
                  {_getCommission()}
                </Typography>
                <LabelWithCustomIcon
                  label="My commission"
                  iconSrc={commisionsIcon}
                />
                {/* {!!user?.commission_percent && (
                <Typography align="center" color="green" variant="body2">
                  Your commission percent is set by the admin
                </Typography>
              )} */}
              </MyCommisionsContainer>
            </Grid>

            {/* Affiliate Link */}
            <AffiliateLinkGrid item xs={12} md={6}>
              <AffiliateLinkItemsFlexWrapper>
                <ReferralLinkContainer>
                  <span>{referralLink}</span>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={_onCopy}
                    src={copyIcon}
                    alt="copy-icon"
                  />
                </ReferralLinkContainer>
                <LabelWithCustomIcon
                  label="My Affiliate Link"
                  iconSrc={affiliateLinkIcon}
                />
              </AffiliateLinkItemsFlexWrapper>
            </AffiliateLinkGrid>
          </Grid>
        </Grid>
      </MainGridContainer>

      {/* Referral list */}
      <Grid xs={12} mb={2}>
        <DataTable
          columns={columns}
          data={state.data}
          error={state.error}
          loading={state.loading}
          page={state.page}
          totalPages={state.totalPage}
          onPageChange={handler.setPage}
        />
      </Grid>

      {/* Text below */}
      <Grid spacing={2}>
        {!!commissions.length && (
          <Grid xs={12} item>
            <Typography variant="caption">
              Earn {commissions?.[0]?.percent}% up to{" "}
              {commissions?.[commissions.length - 1]?.percent}% for every user
              deposit and money will flow!
            </Typography>
          </Grid>
        )}

        {!!commissions.length && (
          <Grid xs={12} item>
            {commissions.map((commission, index: number) => (
              <Typography display="block" variant="caption" key={index}>
                {commission.min}-{commission.max} active users:{" "}
                {commission.percent}% commision on all deposits
              </Typography>
            ))}
          </Grid>
        )}

        {/* <Grid xs={12} sm={6} item>
          <Card
            image="https://betfury.io/_nuxt/in-house.987f6303.jpg"
            title="15%"
            subTitle="From pure profit"
            heading="(based on house edge 2%)"
            description="15% from platform's house edge will be paid you immediately after each referrals winning bet."
            name="for in-house games"
          />
        </Grid> */}

        {/* <Grid xs={12} sm={6} item>
          <Card
            image="https://betfury.io/_nuxt/slots.068730fd.jpg"
            title="1.5%"
            subTitle="From all winnings"
            heading="(based on house edge 3,333%)"
            description="1.5% from platform's house edge will be paid you immediately after each referrals winning bet."
            name="for slots and sports"
          />
        </Grid> */}

        {/* <Grid xs={12} item>
          <SpecialOffer />
        </Grid> */}
      </Grid>
    </Fragment>
  );
};

const MainGridContainer = styled(Grid)(() => ({
  color: "#fff",
  width: "100%",
  borderRadius: 24,
}));
const StatsContainer = styled("div")({
  backgroundColor: customTheme.transparentBg,
  borderRadius: 24,
});
const MyCommisionsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,
  alignItems: "center",
  justifyContent: "center",
  background: customTheme.transparentBg,
  borderRadius: 24,
  minHeight: 100,
}));
const AffiliateLinkGrid = styled(Grid)(() => ({
  background: customTheme.transparentBg,
  borderRadius: 24,
  minHeight: 100,
}));
const AffiliateLinkItemsFlexWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));
const ReferralLinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  [theme.breakpoints.down("sm")]: {
    span: {
      fontSize: 14,
    },
  },
}));

export default AffiliateProgram;
