import { Typography } from "@mui/material";
import moment from "moment";

import Balance from "../../Components/Common/Balance";

const colors = {
  rejected: "#d1063f",
  cancelled: "#d1063f",
  pending: "#ffd525",
  approved: "#1eb83e",
};

const types = {
  withdraw: "Withdrawal",
  deposit: "Deposited",
  claim_rebates: "Claimed Rebates",
  claim_commissions: "Claimed Commissions",
  release_commissions: "Released Commissions",
};

export const columns = [
  {
    name: "amount",
    label: "Amount",
    customBodyRender: (amount: any) => <Balance value={amount} normal />,
  },
  {
    name: "status",
    label: "Status",
    customBodyRender: (status: any) => (
      <Typography
        color={colors[status as keyof typeof colors]}
        textTransform="capitalize"
      >
        {status}
      </Typography>
    ),
  },
  {
    name: "type",
    label: "Type",
    customBodyRender: (type: keyof typeof types) => types[type],
  },
  {
    name: "method",
    label: "Method",
  },
  {
    name: "account_number",
    label: "Number",
  },
  {
    name: "account_name",
    label: "Name",
  },
  {
    name: "date",
    label: "Date",
    customBodyRender: (date: any) => (
      <Typography>{moment(date).format("LLL")}</Typography>
    ),
  },
  {
    name: "transaction_id",
    label: "TXID",
  },
];
