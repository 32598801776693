import { Box, Grid, Typography } from "@mui/material";
import Validation from "../../../../Helpers/Validation";
import Form from "../../../Form";
import useGlobalTrustPayWithdrawController from "../hooks/useGlobalTrustPayWithdrawController";
import Input from "../../../Form/Input";
import Button from "../../../Common/Button";
import Modal, { SIZES } from "../../../Common/Modal";
import Submit from "../../../Form/Submit";

const initialValues = {
  bank: "",
  account_number: "",
  account_name: "",
  amount: "",
};
const validationSchema = Validation.createSchema({
  account_number: Validation.fieldRequired("Account Number", "number"),
  account_name: Validation.fieldRequired("Account Name"),
  amount: Validation.fieldRequired("Amount", "number"),
});
const fontColor = "#888888 !important";

interface Localprops {
  gateway: string;
}

const GlobalTrustPayWithdraw = ({ gateway }: Localprops) => {
  const [state, handlers] = useGlobalTrustPayWithdrawController(gateway);

  return (
    <Box padding={{ xs: "20px", sm: "20px 100px", md: "40px 150px 20px" }}>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handlers.onWithdraw}
      >
        <Grid container gap={1}>
          <Grid xs={12} item>
            <Input
              name="account_number"
              label="Enter account number"
              type="number"
              variant="outlined"
              labelColor={fontColor}
              containerStyle={{ marginBottom: 10 }}
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              name="account_name"
              label="Enter GCash number"
              type="text"
              variant="outlined"
              labelColor={fontColor}
              containerStyle={{ marginBottom: 10 }}
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              name="amount"
              label="Enter amount"
              type="number"
              variant="outlined"
              labelColor={fontColor}
              containerStyle={{ marginBottom: 10 }}
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <Submit variant="contained" size="small" filled fullWidth>
              Withdraw
            </Submit>
          </Grid>
        </Grid>

        <Modal
          size={SIZES.EXTRA_SMALL}
          open={false}
          title="Are you sure?"
          onClose={() => {}}
          withGradient
        >
          <div style={{ padding: "0 24px 24px" }}>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Typography>
                  Are you sure you enter correct details? we are not responsible
                  for your mistake
                </Typography>
              </Grid>

              <Grid xs={6} item>
                <Submit
                  variant="contained"
                  color="success"
                  showError={false}
                  fullWidth
                >
                  Yes
                </Submit>
              </Grid>

              <Grid xs={6} item>
                <Button
                  variant="contained"
                  color="error"
                  // onClick={toggleConfirmation}
                  fullWidth
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </Form>
    </Box>
  );
};

export default GlobalTrustPayWithdraw;
