import { Grid, Typography } from "@mui/material";

import Button from "../../../Common/Button";

interface LocalProps {
  providersList: any[];
  setProvider: (provider: string) => void;
}

const ProviderSelection = ({ providersList, setProvider }: LocalProps) => {
  return (
    <Grid container>
      <Grid
        alignItems="center"
        spacing={2}
        justifyContent="center"
        container
        item
      >
        {providersList.map((bd, index) => (
          <Grid key={index} sm={6} item>
            <Grid xs={12} justifyContent="center" container item>
              <Button
                onClick={() => setProvider(bd.label)}
                disabled={bd.isMaintenance}
                style={{
                  width: bd.size.width + 10,
                  height: bd.size.height + 10,
                }}
              >
                <img alt={bd.label} src={bd.image} style={bd.size} />
              </Button>
            </Grid>
            <Grid xs={12} justifyContent="center" container item>
              {bd.isMaintenance && (
                <Typography color="error" variant="caption">
                  Under Maintenance
                </Typography>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ProviderSelection;
