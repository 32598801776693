import { Divider as MuiDivider, styled } from "@mui/material";

const Divider = () => <CustomDivider />;

const CustomDivider = styled(MuiDivider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  background: "#202a39",
}));

export default Divider;
