import affiliatesIcon from "../../Assets/icons/affiliates.png";
import gamesIcon from "../../Assets/icons/bottom-nav-games-icon.png";
import depositIcon from "../../Assets/icons/deposit-icon.png";
import menuMobileIcon from "../../Assets/icons/menu-mobile-icon.png";

import routes from "../../Routes/Constants";

export const profileModalNav = {
  MY_PROFILE: "my_profile",
  TRANSACTION_HISTORY: routes.TRANSACTION_HISTORY,
  GAME_HISTORY: routes.GAME_HISTORY,
  CHANGE_PASSWORD: "change_password",
  FREE_SPIN: "free_spin",
  REBATES: "rebates",
  CLAIM_COUPON: "claim_coupon",
};