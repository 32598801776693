import { Box, styled } from "@mui/material";
import { Route, Routes } from "react-router-dom";

import { PAGE_MAX_WIDTH } from "../Constants";
import AffiliateProgram from "../Pages/AffiliateProgram";
import AffiliateProgramV2 from "../Pages/AffiliateProgramV2";
import AllGames from "../Pages/AllGames";
import HeadsOrTails from "../Pages/AllGames/HeadsOrTails";
import GameLimbo from "../Pages/AllGames/Limbo";
import GameMines from "../Pages/AllGames/Mines";
import GameWheelOfFortune from "../Pages/AllGames/WheelOfFortune";
import CMS from "../Pages/CMS";
import GameHistory from "../Pages/GameHistory";
import Home from "../Pages/Home/";
import TransactionHistory from "../Pages/TransactionHistory";
import VIPRanking from "../Pages/VIPRanking";

import routes from "./Constants";
// import Plinko from "../Pages/AllGames/Plinko";
import Snake from "../Pages/AllGames/Snake";
import ThirdPartyGamePlayer from "../Pages/ThirdPartyGamePlayer";

const isAffiliateV1 = process.env.REACT_APP_AFFILIATE_VERSION === "v1";

const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: PAGE_MAX_WIDTH,
  padding: "5vh 24px 0",
  overflow: "hidden",
  [theme.breakpoints.down("xl")]: {
    marginTop: "70px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0px 16px",
  },
}));

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/snake" element={<Snake />} />
      <Route path={routes.HOME} element={<Home />} />
      <Route path={routes.ALL_GAMES} element={<AllGames />} />
      <Route
        path={routes.AFFILIATE}
        element={isAffiliateV1 ? <AffiliateProgram /> : <AffiliateProgramV2 />}
      />
      <Route path={routes.VIP_RANKING} element={<VIPRanking />} />
      <Route
        path={routes.TRANSACTION_HISTORY}
        element={<TransactionHistory />}
      />
      <Route path={routes.GAME_HISTORY} element={<GameHistory />} />
      <Route
        path={routes.GAME.WHEEL_OF_FORTUNE}
        element={<GameWheelOfFortune />}
      />
      <Route path={routes.GAME.MINES} element={<GameMines />} />
      <Route path={routes.GAME.LIMBO} element={<GameLimbo />} />
      <Route path={routes.GAME.HEADS_OR_TAILS} element={<HeadsOrTails />} />
      {/* <Route path={routes.GAME.PLINKO} element={<Plinko />} /> */}
      <Route path={`${routes.BLOG}/:path`} element={<CMS />} />
      <Route
        path={routes.GAME.THIRD_PARTY_GAME_PLAYER}
        element={<ThirdPartyGamePlayer />}
      />
    </Routes>
  );
};

export default MainRoutes;
