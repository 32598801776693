import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import caretDownIcon from "../../Assets/icons/caret-down.png";

import GradientTypography from "./GradientTypography";

type LocalProps = {
  title: string;
  titleProps?: any;
  content: string;
  contentProps?: any;
};

const Accordion = ({
  title,
  titleProps,
  content,
  contentProps,
}: LocalProps) => {
  return (
    <MuiAccordion sx={{ backgroundColor: "transparent" }}>
      <AccordionSummary expandIcon={<img src={caretDownIcon} />}>
        <GradientTypography {...titleProps}>{title}</GradientTypography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography {...contentProps}>{content}</Typography>
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
