import ArxGcashGateway from "../ArxGcashGateway";
import GcashV2 from "../GcashV2";
import GlobalTrustPayDeposit from "../GlobalTrustPayDeposit";
import { bankOptions } from "../banks";

import ProviderSelection from "../Components/ProviderSelection";
import GlobalTrustPayWithdraw from "../Components/GlobalTrustPayWithdraw";

export const renderDepositProvider = (
  selectedProvider: String,
  setPaymentProvider: (provider: string) => void,
  isGcashMaintenance: boolean,
  isPaymayaMaintenance: boolean
) => {
  switch (selectedProvider) {
    case "GCASH":
      return <ArxGcashGateway />;
    case "GCASH v2":
      return <GcashV2 />;
    case "GCASH v3":
      return <GlobalTrustPayDeposit gateway="gcash_qr" />;
    case "Maya":
      return <GlobalTrustPayDeposit gateway="paymaya" />;
    default:
      return (
        <ProviderSelection
          setProvider={setPaymentProvider}
          providersList={bankOptions.map((b) => {
            if (b.label === "GCASH v3" && isGcashMaintenance) {
              b.isMaintenance = true;
            }
            if (b.label === "Maya" && isPaymayaMaintenance) {
              b.isMaintenance = true;
            }
            return b;
          })}
        />
      );
  }
};
export const renderWithdrawProvider = (
  selectedProvider: string,
  setSelectedProvider: (provider: string) => void
) => {
  switch (selectedProvider) {
    case "GCASH v3":
      return <GlobalTrustPayWithdraw gateway="gcash" />;
    case "Maya":
      return <GlobalTrustPayWithdraw gateway="paymaya" />;
    default:
      return (
        <ProviderSelection
          setProvider={setSelectedProvider}
          providersList={bankOptions}
        />
      );
  }
};
export const formatNumberWithLeadingZero = (number: number) => {
  return number.toString().padStart(2, "0");
};
