import { styled } from "@mui/material";
import { customTheme } from "../../Utilities/theme";

const GradientDivider = (props: any) => {
  return <CustomDivider {...props} />;
};

export default GradientDivider;

const CustomDivider = styled("div")((props) => ({
  height: "2px",
  width: "100%",
  background: customTheme.primaryGradientColor,
  ...props,
}));
