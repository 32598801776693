import { Grid, styled } from "@mui/material";

import { PAGE_MAX_WIDTH } from "../../Constants";
import logo from "../../Assets/Logos/logo.png";
import { useConfigSelector } from "../../Redux/utility/useSelector";

import AboutUs from "./AboutUs";
import Copyright from "./Copyright";
import Links from "./Links";
import Providers from "./Providers";
import Socials from "./Socials";

const MainGridContainer = styled(Grid)(({ theme }) => ({
  padding: "0px 24px",
  height: "100%",
  maxWidth: PAGE_MAX_WIDTH,
}));

const FooterDetails = () => {
  const config = useConfigSelector();
  const siteWebLogo = config?.siteAssets?.web_logo;
  const partners = config?.siteAssets?.partners;

  return (
    <MainGridContainer container spacing={2}>
      <Grid xs={12} md={3} item container alignItems="center">
        <Grid item>
          <img height="60px" src={siteWebLogo || logo} alt="logo" />
        </Grid>
      </Grid>

      <Grid xs={12} md={3} item>
        <AboutUs />
      </Grid>

      <Grid xs={6} md={3} item>
        <Socials />
      </Grid>

      <Grid xs={6} md={3} item>
        <Links />
      </Grid>

      <Grid xs={12} item>
        <Providers data={partners} />
      </Grid>

      <Grid xs={12} item>
        <Copyright />
      </Grid>
    </MainGridContainer>
  );
};

export default FooterDetails;
