import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  styled,
  ListItemButton,
} from "@mui/material";
import menuOpenIcon from "../../Assets/icons/menu-open.png";

import { useConfigSelector } from "../../Redux/utility/useSelector";
import { getMaintenanceMapper } from "../../Helpers";

import { NavIcon, menu } from "./Constants";
import AdditionalAccess from "./AdditionalAccess";
import GameQuickAccess from "./GamesQuickAccess";
import { customTheme } from "../../Utilities/theme";
import useUtilityAction, {
  LOGIN_SIGNUP_FORMS,
} from "../../Redux/utility/useAction";
import { checkSessionBeforeNavigate } from "./utils";
import useUserSelector from "../../Redux/user/useSelector";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const SiderbarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxHeight: "100%",
  paddingBottom: theme.spacing(2),
  overflowY: "auto",
}));

export interface SidebarContentProps {
  handleDrawerClose: () => void;
}

export function SidebarContent({ handleDrawerClose }: SidebarContentProps) {
  const config = useConfigSelector();
  const { setLoginSignupForm } = useUtilityAction();
  const { isLogin } = useUserSelector();

  const navigate = useNavigate();

  const maintenanceMapper = getMaintenanceMapper(config);
  const sideImages = config?.siteAssets?.game_side_image || {};

  const onMenuClick = (selected: any, callback?: () => void) => {
    handleDrawerClose();
    if (callback) {
      callback();
    } else {
      navigate(selected);
    }
  };

  const openLoginSignUp = () => setLoginSignupForm(LOGIN_SIGNUP_FORMS.SIGNIN);

  return (
    <SiderbarContainer className="no-scroll">
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <NavIcon src={menuOpenIcon} alt="menu-open-icon" />
        </IconButton>
      </DrawerHeader>

      <GameQuickAccess
        maintenanceMapper={maintenanceMapper}
        sideImages={sideImages}
        onClick={handleDrawerClose}
      />

      <List>
        {menu(config?.siteSettings?.site_telegram_link).map(
          (menuItem, index) => {
            const { icon: Icon, pathname, callback } = menuItem;

            return (
              <ListItem
                onClick={() =>
                  checkSessionBeforeNavigate(
                    isLogin,
                    menuItem.label,
                    () => onMenuClick(pathname, callback),
                    openLoginSignUp
                  )()
                }
                key={index}
                disablePadding
              >
                <ListItemButton>
                  <CustomGridContainer alignItems="center" container gap={1}>
                    <Grid item>
                      <Icon />
                    </Grid>
                    <Grid item>
                      <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                        {menuItem.label}
                      </Typography>
                    </Grid>
                  </CustomGridContainer>
                </ListItemButton>
              </ListItem>
            );
          }
        )}
        <ListItem disablePadding>
          <AdditionalAccess />
        </ListItem>
      </List>
    </SiderbarContainer>
  );
}

export const CustomGridContainer = styled(Grid)(() => ({
  border: "0.5px",
  ":hover": {
    border: "0.5px solid " + customTheme.basicBorderColor,
    borderRadius: "10px",
    background: customTheme.hoverBg,
  },
}));
