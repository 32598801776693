import {
  Box,
  Grid,
  IconButton,
  AppBar as MuiAppBar,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import homeIcon from "../../Assets/icons/home-icon.png";
import menuIcon from "../../Assets/icons/menu-icon.png";

import useUserSelector from "../../Redux/user/useSelector";
import useUtilityAction, {
  LOGIN_SIGNUP_FORMS,
} from "../../Redux/utility/useAction";

import Button from "../Common/Button";
import { Sidebar } from "../Sidebar";

import theme, { customTheme } from "../../Utilities/theme";
import MainLogo from "./Logo";
import Notification from "./Notification";
import Profile from "./Profile";
import Wallet from "./Wallet";

import affiliateIcon from "../../Assets/icons/affiliates.png";
import gamesIcon from "../../Assets/icons/allGamesIcon.png";
import depositIcon from "../../Assets/icons/deposit-icon.png";
import mobileMenuIcon from "../../Assets/icons/menuIcon.png";
import routes from "../../Routes/Constants";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  background: "none",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

interface NavigationProps {
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  open: boolean;
}

export const MobileTabNavigation = ({
  handleDrawerOpen,
  handleDrawerClose,
  open,
}: NavigationProps) => {
  const { isLogin } = useUserSelector();
  const { toggleWallet, setLoginSignupForm } = useUtilityAction();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const _onClick = (route: string) => {
    if (!isLogin && route !== "all-games") {
      return setLoginSignupForm(LOGIN_SIGNUP_FORMS.SIGNIN);
    }
    navigate(route);
  };

  if (!isMobile || !isLogin) return null;

  return (
    <MobileTabNavContainer id="test">
      <MobileTabNavButton
        onClick={() => {
          _onClick(routes.HOME);
        }}
      >
        <img src={gamesIcon} />
        <MobileTabNavButtonLabel>All Games</MobileTabNavButtonLabel>
      </MobileTabNavButton>
      <MobileTabNavButton
        onClick={() => {
          _onClick(routes.AFFILIATE);
        }}
      >
        <img src={affiliateIcon} />
        <MobileTabNavButtonLabel>Affiliate</MobileTabNavButtonLabel>
      </MobileTabNavButton>
      <MobileTabNavButton onClick={toggleWallet}>
        <img src={depositIcon} />
        <MobileTabNavButtonLabel>Deposit</MobileTabNavButtonLabel>
      </MobileTabNavButton>
      <MobileTabNavButton onClick={handleDrawerOpen}>
        <img src={mobileMenuIcon} />
        <MobileTabNavButtonLabel>Menu</MobileTabNavButtonLabel>
      </MobileTabNavButton>
    </MobileTabNavContainer>
  );
};

const Navigation = ({
  handleDrawerOpen,
  handleDrawerClose,
  open,
}: NavigationProps) => {
  const { setLoginSignupForm } = useUtilityAction();
  const { isLogin, user } = useUserSelector();

  const mobileViewPort = useMediaQuery("(max-width: 800px)");
  // const hideBladeBox = useMediaQuery("(max-width: 400px)");

  const _renderNavRight = () => {
    if (isLogin) {
      return (
        <Fragment>
          <Grid item>
            <Wallet />
          </Grid>

          <Grid item>
            <Notification data={user?.notifications} />
          </Grid>

          <Grid item>
            <Profile />
          </Grid>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Grid item>
            <Button
              filled
              variant="contained"
              color="primary"
              onClick={() => setLoginSignupForm(LOGIN_SIGNUP_FORMS.SIGNIN)}
            >
              Login
            </Button>
          </Grid>
          <Grid item>
            <Button
              defaultOutlineBorder
              color="error"
              onClick={() => setLoginSignupForm(LOGIN_SIGNUP_FORMS.SIGNUP)}
            >
              Sign Up
            </Button>
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <>
      <AppBar position="sticky">
        <AppBarBackground />
        <Grid sx={{ p: 1.5 }} container alignItems="center">
          <Grid item>
            <LogoButtonContainer>
              <Grid container alignItems="center" gap={1}>
                <Grid item>
                  <IconButton size="small" onClick={handleDrawerOpen}>
                    <img src={menuIcon} alt="menu-icon" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <MainLogo />
                </Grid>
              </Grid>
              {mobileViewPort ? null : <HomePillButton />}
            </LogoButtonContainer>
          </Grid>

          <Grid xs={true} item>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              {_renderNavRight()}
            </Grid>
          </Grid>
        </Grid>
      </AppBar>

      <Sidebar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
    </>
  );
};

export default Navigation;

const HomeIcon = () => <img src={homeIcon} alt="home-icon" />;
const HomeButton = styled(Button)(() => ({
  height: "40px",
}));
const HomePillButton = () => {
  const navigate = useNavigate();

  return (
    <HomeButton
      filled={true}
      onClick={() => navigate("/")}
      color="primary"
      variant="contained"
      startIcon={<HomeIcon />}
      size="small"
      sx={{ padding: "0 20px", marginRight: "-80px" }}
    >
      Home
    </HomeButton>
  );
};

const LogoButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
`;
const AppBarBackground = styled("div")(() => ({
  height: "100%",
  background: customTheme.appbarBackground,
  width: "100%",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: -1,
}));

const MobileTabNavContainer = styled(Box)(() => ({
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
  background: "#2A412F8F",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "24px 20px",
}));

const MobileTabNavButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "between",
  flexDirection: "column",
  cursor: "pointer",
}));

const MobileTabNavButtonLabel = styled(Box)(() => ({
  background:
    "linear-gradient(253.36deg, #8c6b28 0%, #ffeba8 48.94%, #94681d 100%)",
  backgroundClip: "text",
  color: "transparent",
  fontWeight: 700,
  font: "12px",
}));
