import { Grid } from "@mui/material";

import { useConfigSelector } from "../../Redux/utility/useSelector";

import SectionHeader from "./SectionHeader";

import facebookIcon from "../../Assets/icons/facebook.png";
import instagramIcon from "../../Assets/icons/instagram.png";
import telegramIcon from "../../Assets/icons/telegram-plane.png";

const Socials = () => {
  const config = useConfigSelector();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <SectionHeader>Social Media</SectionHeader>
      </Grid>
      <Grid item container alignItems="center" spacing={2}>
        {!!config?.siteSettings?.site_facebook_link && (
          <Grid item>
            <a
              rel="noreferrer"
              href={config?.siteSettings?.site_facebook_link}
              target="_blank"
            >
              <img src={facebookIcon} alt="facebook-icon" />
            </a>
          </Grid>
        )}

        {!!config?.siteSettings?.site_instagram_link && (
          <Grid item>
            <a
              rel="noreferrer"
              href={config?.siteSettings?.site_instagram_link}
              target="_blank"
            >
              <img src={instagramIcon} alt="instagram-icon" />
            </a>
          </Grid>
        )}

        {!!config?.siteSettings?.site_telegram_link && (
          <Grid item>
            <a
              rel="noreferrer"
              href={config?.siteSettings?.site_telegram_link}
              target="_blank"
            >
              <img src={telegramIcon} alt="telegram-icon" />
            </a>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Socials;
