import { useRef } from "react";
import {
  Box,
  Link,
  Typography,
  Grid,
  InputAdornment,
  styled,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

import Validation from "../../../Helpers/Validation";
import routes from "../../../Routes/Constants";

import Form from "../../Form";
import Checkbox from "../../Form/Checkbox";
import Input from "../../Form/Input";
import Submit from "../../Form/Submit";
import { FormikContext } from "../../Form/Constants";

import useController from "./hooks/useController";
import GradientTypography from "../../Common/GradientTypography";
import userLoginIcon from "../../../Assets/icons/user-login-icon.png";
import passwordLockIcon from "../../../Assets/icons/password-lock-icon.png";
import GradientDivider from "../../Common/GradientDivider";

const fontColor = "#888888 !important";
const inputVariant = "outlined";

const validationSchema = Validation.createSchema({
  emailOrNumber: Validation.fieldRequired("Email or Number"),
  password: Validation.passwordSchema,
  confirmPassword: Validation.confirmPasswordSchema,
  legalAge: Validation.requiredTrue("Terms of Service is required"),
});

const initialValues = {
  emailOrNumber: "",
  password: "",
  confirmPassword: "",
  code: "",
  legalAge: false,
};

function SignUp({ code }: { code?: string }) {
  const captchaRef = useRef(null);
  const { onSignUp } = useController();

  if (code) {
    initialValues.code = code;
  }

  return (
    <Box
      style={{
        height: "95%",
      }}
      mb="24px"
    >
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSignUp(captchaRef)}
      >
        {({ values }: FormikContext) => (
          <MainGridContainer wrap="nowrap" direction="column" gap={1} container>
            <Grid item>
              <Input
                containerStyle={{ marginBottom: 10 }}
                placeholder="Enter your email or number"
                label={
                  values["emailOrNumber"] ? "Enter your email or number" : ""
                }
                name="emailOrNumber"
                type="text"
                labelColor={fontColor}
                variant={inputVariant}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={userLoginIcon} alt="user-login-icon" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Input
                containerStyle={{ marginBottom: 10 }}
                label={values["password"] ? "Enter your password" : ""}
                placeholder="Enter your password"
                name="password"
                type="password"
                labelColor={fontColor}
                variant={inputVariant}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={passwordLockIcon} alt="password-lock-icon " />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Input
                containerStyle={{ marginBottom: 10 }}
                label={values["confirmPassword"] ? "Confirm your password" : ""}
                placeholder="Confirm your password"
                name="confirmPassword"
                type="password"
                labelColor={fontColor}
                variant={inputVariant}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={passwordLockIcon} alt="password-lock-icon " />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {!!code && (
              <Grid item>
                <Input
                  containerStyle={{ marginBottom: 10 }}
                  label="Referral Code"
                  name="code"
                  type="text"
                  labelColor={fontColor}
                  variant={inputVariant}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item>
              {/* <StyledCaptcha
                ref={captchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ""}
              /> */}
            </Grid>
            <Grid item>
              <Box style={{ display: "block" }}>
                <Checkbox
                  name="legalAge"
                  label={
                    <Typography fontWeight="bold" variant="caption">
                      I confirm that I am 18 years old and I have read the{" "}
                      <Link
                        display="inline-block"
                        href={`/${routes.BLOG}/terms-of-service`}
                        variant="caption"
                        style={{ textDecoration: "none" }}
                      >
                        <StyledUnderlineTextDecoration
                          fontWeight="bold"
                          fontSize={12}
                        >
                          <span>Terms of Service</span>
                          <GradientTextDecoration />
                        </StyledUnderlineTextDecoration>
                      </Link>
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid style={{ marginTop: "auto" }} item>
              <Submit filled variant="contained" fullWidth>
                Create account
              </Submit>
            </Grid>
          </MainGridContainer>
        )}
      </Form>
    </Box>
  );
}

export default SignUp;

const MainGridContainer = styled(Grid)(({ theme }) => ({
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    height: "98%",
  },
}));
export const StyledUnderlineTextDecoration = styled(GradientTypography)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
}));
export const GradientTextDecoration = styled(GradientDivider)(() => ({
  position: "absolute",
  left: "1px",
  top: "15px",
}));

const StyledCaptcha = styled(ReCAPTCHA)(() => ({
  width: "50px",
}));
