import { FormikHelpers, FormikValues } from "formik";

import userApi from "../../../../Service/api/user";

import useObjectState from "../../../../Hooks/useObjectState";
import useUtilityAction from "../../../../Redux/utility/useAction";

const initialState = {
  showConfirmation: false,
};

const useGlobalTrustPayWithdrawController = (gateway: string) => {
  const [state, setState] = useObjectState(initialState);

  const { toggleSnackBar, toggleWallet } = useUtilityAction();

  const _onWithdraw = async (
    formData: FormikValues,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<FormikValues>
  ) => {
    const payload = {
      account_number: formData.account_number,
      account_name: formData.account_name,
      amount: formData.amount,
      gateway,
    };

    const { ok, data }: any = await userApi.withdraw(payload);

    if (ok) {
      toggleWallet();
      toggleSnackBar("success", "Withdraw requested successfully");
      resetForm();
    } else {
      setState({
        showConfirmation: false,
      });

      // setErrors({ api: handleApiError(data) });
      toggleSnackBar("error", data.message);
    }
    setSubmitting(false);
  };

  const handlers = {
    onWithdraw: _onWithdraw,
  };

  return [state, handlers];
};

export default useGlobalTrustPayWithdrawController;
