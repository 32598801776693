import { Typography, styled } from "@mui/material";

import GradientTypography from "../../Components/Common/GradientTypography";

const GradientTitle = styled(GradientTypography)(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "37px",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  margin: "0 auto",
  textAlign: "center",
  fontSize: 20,
  maxWidth: 810,
}));

export { GradientTitle, SubTitle };
