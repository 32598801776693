import { Typography } from "@mui/material";
import { COLUMNS } from "../../../Constants/game";

const breakpoint = 755;

const headOrTailsMapper = (data: string) => {
  const isHead = data === "0";
  return (
    <Typography color={isHead ? "primary" : "error"}>
      {isHead ? "Top" : "Bottom"}
    </Typography>
  );
};

const columns = [
  COLUMNS.result,
  COLUMNS.payout,
  COLUMNS.amount,
  {
    name: "betted_on",
    label: "Selected",
    customBodyRender: headOrTailsMapper,
  },
  {
    name: "bet_result",
    label: "Landed on",
    customBodyRender: headOrTailsMapper,
  },
  { name: "bet_id", label: "Bet ID" },
];

export { breakpoint, columns };
