import React, { forwardRef, useImperativeHandle } from "react";
import { Grid } from "@mui/material";

import useDatatablePagination from "../../Hooks/useDatatablePagination";
import { useIsLoginSelector } from "../../Redux/user/useSelector";
import userApi from "../../Service/api/user";

import DataTable from "../Common/Datatable";
import LiveBets from "../LiveBets";

type LocalProps = {
  children: React.ReactNode;
  gameCode: string;
  tableColumn?: Array<any>;
};

const GameContainer = forwardRef(
  ({ children, gameCode, tableColumn }: LocalProps, ref) => {
    const isLogin = useIsLoginSelector();

    const [state, handler] = useDatatablePagination((page: number) => {
      const params = [
        { key: "page", value: page },
        { key: "game_code", value: gameCode },
      ];
      return userApi.getGameHistories(params);
    });

    useImperativeHandle(ref, () => ({
      refreshHistory: handler.refresh,
    }));

    return (
      <Grid container gap={2}>
        <Grid item xs={12}>
          <LiveBets />
        </Grid>

        <Grid xs={12} mt={4} item>
          {children}
        </Grid>

        {isLogin && tableColumn && (
          <Grid item xs={12}>
            <DataTable
              columns={tableColumn}
              data={state.data}
              error={state.error}
              loading={state.loading}
              page={state.page}
              totalPages={state.totalPage}
              onPageChange={handler.setPage}
            />
          </Grid>
        )}
      </Grid>
    );
  }
);

export type GCRef = {
  refreshHistory: () => void;
};

export default GameContainer;
