import { Grid } from "@mui/material";
import PageIntro from "./PageIntro";
import Features from "./Features";
import InHouseGames from "../../Components/InHouseGames";
import BetStatistics from "../../Components/BetStatistics";
import HomepageV2 from "./HomepageV2";

const Home = (props: any) => {
  console.log("env", process.env.REACT_APP_NODE_ENV);

  return (
    <HomepageV2 />
    // <Grid container spacing={4}>
    //   <Grid xs={12} item>
    //     <PageIntro />
    //   </Grid>
    //   <Grid xs={12} item>
    //     <Features />
    //   </Grid>
    //   <Grid xs={12} item>
    //     <InHouseGames />
    //   </Grid>
    //   <Grid xs={12} item>
    //     <BetStatistics />
    //   </Grid>
    // </Grid>
  );
};

export default Home;
