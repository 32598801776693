import Balance from "../../Components/Common/Balance";

export const columns = [
  {
    name: "username",
    label: "Username",
  },
  {
    name: "commissions_statistic",
    label: "Total Deposit",
    customBodyRender: (data: any) => (
      <Balance value={data?.deposit || 0} normal />
    ),
  },
  {
    name: "commissions_statistic",
    label: "Total Withdraw",
    customBodyRender: (data: any) => (
      <Balance value={data?.withdraw || 0} normal />
    ),
  },
  {
    name: "balance",
    label: "Current Balance",
    customBodyRender: (value: any) => <Balance value={value} normal />,
  },
  {
    name: "commissions",
    label: "Commissions",
    customBodyRender: (value: any) => <Balance value={value || 0} normal />,
  },
];
