import { Grid, Typography, styled } from "@mui/material";

import PesoSignImage from "../../Assets/Images/peso-sign.png";

import { CURRENCY_SYMBOL } from "../../Constants";
import { commafyNumber } from "../../Helpers";

const Balance = ({
  normal,
  pesoIcon,
  value,
  ...rest
}: {
  normal?: boolean;
  pesoIcon?: string;
  value: number | string;
  [key: string]: any;
}) => {
  return (
    <Grid
      container
      justifyContent="center"
      flexWrap="nowrap"
      width="fit-content"
    >
      <Grid item display="flex" alignItems="center">
        {normal ? (
          <Text {...rest}>{CURRENCY_SYMBOL}</Text>
        ) : (
          <Image alt="balance" src={pesoIcon || PesoSignImage} />
        )}
      </Grid>
      <Grid
        item
        display="flex"
        alignItems="center"
        ml={normal ? "5px" : "10px"}
      >
        <Text {...rest}>
          {typeof value === "string" ? value : commafyNumber(value, true)}
        </Text>
      </Grid>
    </Grid>
  );
};

const Image = styled("img")(({ theme }) => ({
  width: 26,
  height: 26,
  [theme.breakpoints.down("md")]: {
    width: 20,
    height: 20,
  },
  [theme.breakpoints.down("sm")]: {
    width: 16,
    height: 16,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

Balance.defaultProps = {
  value: 0,
};

export default Balance;
