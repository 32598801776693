import { io } from "socket.io-client";

const resolveSocketUrl = (): any => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "development":
      return `http://localhost:${process.env.REACT_APP_WEBSOCKET_PORT}`;
    case "staging":
      return `${process.env.REACT_APP_DOMAIN_STAGING}/socket.io`;
    case "production":
      return `${process.env.REACT_APP_DOMAIN_PROD}/socket.io`;
    default:
      return null;
  }
};

const socket = io(resolveSocketUrl(), {
  withCredentials: true,
});

export default socket;
