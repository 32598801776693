import {
  Box,
  Grid,
  InputAdornment,
  Link,
  Typography,
  styled,
} from "@mui/material";

import Validation from "../../../Helpers/Validation";
import useNoSessionSelector from "../../../Redux/noSession/useSelector";

import Form from "../../Form";
import Checkbox from "../../Form/Checkbox";
import Input from "../../Form/Input";
import Submit from "../../Form/Submit";

import useController from "./hooks/useController";

import userLoginIcon from "../../../Assets/icons/user-login-icon.png";
import passwordLockIcon from "../../../Assets/icons/password-lock-icon.png";
import { FormikContext } from "../../Form/Constants";
import routes from "../../../Routes/Constants";
import {
  GradientTextDecoration,
  StyledUnderlineTextDecoration,
} from "./SignUp";
import GradientTypography from "../../Common/GradientTypography";

type Props = {
  onForgotPassword: () => void;
};

const fontColor = "#888888 !important";
const inputVariant = "outlined";

const validationSchema = Validation.createSchema({
  emailOrNumber: Validation.fieldRequired("Email or Number"),
  password: Validation.passwordSchema,
  remember: Validation.typeSchema("Remember me", "boolean"),
});

function SignIn({ onForgotPassword }: Props) {
  const { loginInformation } = useNoSessionSelector();
  const { onSignIn } = useController();

  const initialValues = loginInformation || {
    emailOrNumber: "",
    password: "",
    remember: false,
  };

  return (
    <Box
      style={{
        height: "95%",
      }}
      mb="24px"
    >
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSignIn}
      >
        {({ values }: FormikContext) => (
          <StyledGridContainer
            wrap="nowrap"
            direction="column"
            gap={1}
            container
          >
            <Grid item>
              <Input
                containerStyle={{ marginBottom: 10 }}
                // label="Enter your email or number"
                placeholder="Enter your email or number"
                name="emailOrNumber"
                type="text"
                label={
                  values["emailOrNumber"] ? "Enter your email or number" : ""
                }
                labelColor={fontColor}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={userLoginIcon} alt="user-login-icon" />
                    </InputAdornment>
                  ),
                }}
                variant={inputVariant}
                fullWidth
              />
            </Grid>

            <Grid item>
              <Input
                containerStyle={{ marginBottom: 10 }}
                label={values["password"] ? "Enter your password" : ""}
                placeholder="Enter your password"
                name="password"
                type="password"
                labelColor={fontColor}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={passwordLockIcon} alt="password-lock-icon " />
                    </InputAdornment>
                  ),
                }}
                variant={inputVariant}
                fullWidth
              />
            </Grid>
            {/* remember and forgot password */}
            <RememberAndForgotPassword onForgotPassword={onForgotPassword} />

            <Grid item>
              <Typography
                display="block"
                marginBottom="1rem"
                variant="caption"
                fontWeight="bold"
              >
                By accessing the site, I confirm that I am 18 years old and I
                have read the{" "}
                <Link
                  display="inline-block"
                  href={`/${routes.BLOG}/terms-of-service`}
                  variant="caption"
                  style={{ textDecoration: "none" }}
                >
                  <StyledUnderlineTextDecoration
                    fontSize={12}
                    fontWeight="bold"
                  >
                    <span>Terms of Service</span>
                    <GradientTextDecoration />
                  </StyledUnderlineTextDecoration>
                </Link>
              </Typography>
            </Grid>
            <Grid style={{ marginTop: "auto" }} item>
              <Submit filled fullWidth>
                Login
              </Submit>
            </Grid>
          </StyledGridContainer>
        )}
      </Form>
    </Box>
  );
}

export default SignIn;

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    height: "85%",
  },
}));

//eslint-disable-next-line
const RememberAndForgotPassword = ({
  onForgotPassword,
}: {
  onForgotPassword: () => void;
}) => {
  return (
    <Grid marginBottom="1rem" xs={12} item>
      <Grid container alignItems="center">
        <Grid xs={6} item>
          {/* <Checkbox
            name="remember"
            label={<Typography variant="caption">remember me</Typography>}
          /> */}
        </Grid>

        <Grid display="flex" justifyContent="flex-end" xs={6} item>
          <Link
            href="#"
            variant="caption"
            onClick={(e) => {
              e.preventDefault();
              onForgotPassword();
            }}
            style={{ textDecoration: "none" }}
            textAlign="end"
          >
            <GradientTypography fontSize={12} fontWeight="bold">
              Forgot you password?
            </GradientTypography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
