import { Grid, Typography, styled } from "@mui/material";

import vipStep1Image from "../../Assets/icons/vip-step-1.png";
import vipStep2Image from "../../Assets/icons/vip-step-2.png";
import vipStep3Image from "../../Assets/icons/vip-step-3.png";
import Button from "../../Components/Common/Button";
import GradientTypography from "../../Components/Common/GradientTypography";
import { useConfigSelector } from "../../Redux/utility/useSelector";
import styles from "../../Utilities/styles";

import { GradientTitle, SubTitle } from "./styles";

function HowDoesItWork() {
  const config = useConfigSelector();
  const appName = config?.siteSettings?.site_name;

  return (
    <Grid container gap={4}>
      <Grid xs={12} item>
        <GradientTitle textAlign="center">How does it work?</GradientTitle>
      </Grid>

      <Grid xs={12} item>
        <SubTitle textAlign="center">
          You start your gaming way from the 1st Rank – NEWCOMER. The more you
          play – the higher your Rank is. It goes up according to the wagered
          amount while playing on {appName}
        </SubTitle>
      </Grid>

      <Grid xs={12} item>
        <Grid justifyContent={{ xs: "center", lg: "space-between" }} container>
          <StepImage src={vipStep1Image} />
          <StepImage src={vipStep2Image} />
          <StepImage src={vipStep3Image} />
        </Grid>
      </Grid>

      <Grid xs={12} display="flex" justifyContent="center" item>
        <Button variant="contained" filled>
          Play now
        </Button>
      </Grid>
    </Grid>
  );
}

const StepImage = styled("img")(() => ({
  width: 341,
  height: 341,
}));

export default HowDoesItWork;
