import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLogin: false,
    data: undefined,
  },
  reducers: {
    logout: (state) => {
      state.data = undefined;
      state.isLogin = false;

      if (sessionStorage.getItem("token")) {
        sessionStorage.removeItem("token");
      }
    },
    reduceFreeSpin: (state: any) => {
      if (state.data?.free_spins) {
        state.data.free_spins -= 1;
      }
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export interface State {
  user: {
    isLogin: boolean;
    data: any;
  };
}

export const { logout, reduceFreeSpin, setIsLogin, setUser } =
  userSlice.actions;

export default userSlice.reducer;
