import { TextLoaderProps } from ".";
import "./Loader.css"; // Create a separate CSS file for styling

const Loader = ({ text }: TextLoaderProps) => {
  return (
    <div className="loader">
      <span style={{ color: "white" }}>{text}</span>
      <div className="dots-container">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
    </div>
  );
};

export default Loader;
