import React from "react";

/**
 *
 * @param initialState (object)
 * Used to manage object state to not always provide function to update state, but simply provide new object
 * which will be merged to an old state
 */
//eslint-disable-next-line
export default (initialState: any): any => {
  const [state, setState] = React.useState(initialState);
  const updateState = React.useCallback(
    (newState: any) =>
      setState((prevState: any) => ({ ...prevState, ...newState })),
    [setState]
  );
  return [state, updateState];
};
