import React, { useState, useEffect } from "react";

interface CountdownProps {
  fromDate: Date;
  toDate: Date;
  onCountdownEnd?: () => void;
}

const Countdown: React.FC<CountdownProps> = ({
  fromDate,
  toDate,
  onCountdownEnd,
}) => {
  const [countdown, setCountdown] = useState<string>("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const differenceFrom = fromDate.getTime() - now;
      const differenceTo = toDate.getTime() - now;

      if (differenceTo <= 0) {
        clearInterval(interval);
        setCountdown("Countdown Over");
        if (onCountdownEnd) {
          onCountdownEnd();
        }
      } else if (differenceFrom > 0) {
        setCountdown("Countdown Not Started Yet");
      } else {
        const difference = differenceTo;
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        let countdownString = "";

        if (days > 0) {
          countdownString += `${days} : `;
        }
        if (hours > 0 || days > 0) {
          countdownString += `${hours} : `;
        }
        if (minutes > 0 || hours > 0 || days > 0) {
          countdownString += `${minutes} : `;
        }
        countdownString += `${seconds}`;

        setCountdown(countdownString);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [fromDate, toDate, onCountdownEnd]);

  return <div>{countdown}</div>;
};

export default Countdown;
