import { styled } from "@mui/material";

import { customTheme } from "../../Utilities/theme";

export const BettingContainer = styled("div")({
  backgroundColor: customTheme.transparentBgForBlurred,
  borderRadius: 10,
  border: `1px solid ${customTheme.basicBorderColor}`,
  paddingTop: 20,
});
