import { Grid } from "@mui/material";

import { useConfigSelector } from "../../Redux/utility/useSelector";

import SectionHeader from "./SectionHeader";
import Subtitle from "./Subtitle";

const AboutUs = () => {
  const config = useConfigSelector();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <SectionHeader>About Us</SectionHeader>
      </Grid>
      <Grid item>
        <Subtitle>
          {config?.siteSettings?.site_about_us || "Win with Pleasure"}
        </Subtitle>
      </Grid>
    </Grid>
  );
};

export default AboutUs;
