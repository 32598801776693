import colors from "./colors";

const styles = {
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
  },
  underMaintenance: (height?: number) => ({
    position: "absolute" as const,
    backgroundColor: `${colors.background}cc`,
    width: "100%",
    height: height || "100%",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
  }),
};

export default styles;
