import { useState } from "react";

export interface PaginationState {
  disableNext: boolean;
  disablePrev: boolean;
  elements: Array<React.ReactNode>;
  page: number;
  onNextPage: () => void;
  onPrevPage: () => void;
}

const usePaginate = (
  limit: number,
  data: Array<any>,
  renderItem: (item: any, index: number) => React.ReactNode
): PaginationState => {
  const [currentPage, setCurrentPage] = useState(1);

  const dataElements: React.ReactNode[] = data.map(renderItem);

  const onNextPage = (): void => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const onPrevPage = (): void => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * limit;
    const endIndex = startIndex + limit;
    return dataElements.slice(startIndex, endIndex);
  };

  return {
    disableNext: currentPage * limit >= data.length,
    disablePrev: currentPage === 1,
    elements: getPaginatedData(),
    page: currentPage,
    onNextPage,
    onPrevPage,
  };
};

export default usePaginate;
