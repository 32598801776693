import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import lodashFind from "lodash/find";
import lodashIsEmpty from "lodash/isEmpty";

import { stringToPath } from "../Helpers";
import listingApi from "../Service/api/listing";

function CMS() {
  const { state, pathname } = useLocation();
  const [data, setData] = useState({
    title: state?.title,
    content: state?.content,
  });

  useEffect(() => {
    setData({ title: "Loading...", content: "" });
    listingApi.getBlogs().then(({ ok, data }: any) => {
      if (ok) {
        const blogs = data.results;
        const blogTitle = pathname.replace("/blog", "");
        const blogData = lodashFind(blogs, (blog) => {
          console.log(stringToPath(blog.title), blogTitle);
          return stringToPath(blog.title) === blogTitle;
        });
        if (!lodashIsEmpty(blogData)) {
          setData(blogData);
        } else {
          setData({
            title: "Not found",
            content: "The page you are trying to find is not exist.",
          });
        }
      } else {
        setData({
          title: "Not found",
          content: "The page you are trying to find is not exist.",
        });
      }
    });
    // eslint-disable-next-line
  }, [state]);

  // eslint-disable-next-line
  const _getBlogs = () => {};

  return (
    <Grid spacing={5} container>
      <Grid xs={12} item>
        <Typography variant="h3" fontWeight="bold">
          {data.title}
        </Typography>
      </Grid>

      <Grid xs={12} item>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </Grid>
    </Grid>
  );
}

export default CMS;
