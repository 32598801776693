export const enum LOGIN_SIGNUP_FORMS {
  SIGNIN = "signin",
  SIGNUP = "signup",
  NONE = "",
}

export const enum WALLET_NAVIGATION {
  DEPOSIT = "deposit",
  WITHDRAW = "withdraw",
}
