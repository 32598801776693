import userApi from "../../../Service/api/user";

import useDatatablePagination from "../../../Hooks/useDatatablePagination";

const useGameHistories = (): any => {
  const result = useDatatablePagination((page: number) => {
    const params = [{ key: "page", value: page }];
    return userApi.getGameHistories(params);
  });

  return result;
};

export default useGameHistories;
