import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Link,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Button from "../../Common/Button";
import Modal, { SIZES } from "../../Common/Modal";
import useUserSelector from "../../../Redux/user/useSelector";
import { LOGIN_SIGNUP_FORMS } from "../../../Redux/utility/constants";
import useUtilityAction from "../../../Redux/utility/useAction";
import { useConfigSelector } from "../../../Redux/utility/useSelector";

import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";

import loginSignUpModalBanner from "../../../Assets/Images/signup-login-banner.png";
import GradientTypography from "../../Common/GradientTypography";
import GradientDivider from "../../Common/GradientDivider";

const SignInOrSignUp = ({
  defaultForm,
  open,
  onClose,
}: {
  defaultForm: LOGIN_SIGNUP_FORMS;
  open: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const isSmallScreenOrMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isLogin } = useUserSelector();
  const config = useConfigSelector();
  const { setLoginSignupForm } = useUtilityAction();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralCode = params.get("ref") as string;

  const [form, setForm] = useState(defaultForm);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const isSignIn = form === LOGIN_SIGNUP_FORMS.SIGNIN;

  useEffect(() => {
    if (!isLogin && referralCode) {
      setForm(LOGIN_SIGNUP_FORMS.SIGNUP);
      setLoginSignupForm(LOGIN_SIGNUP_FORMS.SIGNUP);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setForm(defaultForm);
  }, [defaultForm]);

  return (
    <Fragment>
      <Modal
        boxStyleProps={{ minWidth: isSmallScreenOrMobile ? 0 : 800 }}
        size={SIZES.LARGE}
        open={open}
        onClose={onClose}
      >
        <Grid wrap="nowrap" container>
          {/* This will be hidden on small screen */}
          <Grid
            sx={{
              display: { xs: "none", sm: "block" },
            }}
            sm={5}
            item
          >
            <IntroImage bg={config?.siteAssets?.auth_image} />
          </Grid>

          {/* Always show (Signin & Signup form) */}
          <Grid xs={12} sm={7} item>
            <SignInSignUpWrapper>
              {/* Form Selector */}
              <SignInSignUpRowContainer>
                <CustomButton
                  filled={isSignIn ? true : false}
                  defaultOutlineBorder={isSignIn ? false : true}
                  onClick={() => setForm(LOGIN_SIGNUP_FORMS.SIGNIN)}
                >
                  Login
                </CustomButton>
                <CustomButton
                  filled={isSignIn ? false : true}
                  defaultOutlineBorder={isSignIn ? true : false}
                  onClick={() => setForm(LOGIN_SIGNUP_FORMS.SIGNUP)}
                >
                  Sign Up
                </CustomButton>
              </SignInSignUpRowContainer>
              {/* Gradient Divider  */}
              <StyledGradientDivider />
              {/* Form Content */}

              <FormWrapper>
                {isSignIn ? (
                  <SignIn
                    onForgotPassword={() => setShowForgotPassword(true)}
                  />
                ) : (
                  <SignUp code={referralCode} />
                )}
              </FormWrapper>

              <AccountInfo
                fontWeight="bold "
                variant="caption"
                textAlign="center"
                display="block"
              >
                {isSignIn
                  ? "Don't you have an account? "
                  : "Do you have an account? "}
                <Link
                  style={{ textDecoration: "none" }}
                  href="#"
                  display="inline-block"
                  variant="caption"
                  onClick={(e) => {
                    e.preventDefault();
                    setForm(
                      isSignIn
                        ? LOGIN_SIGNUP_FORMS.SIGNUP
                        : LOGIN_SIGNUP_FORMS.SIGNIN
                    );
                  }}
                >
                  <GradientTypography fontSize={12} fontWeight="bold">
                    {isSignIn ? "Sign up" : "Login"}
                  </GradientTypography>
                </Link>
              </AccountInfo>
            </SignInSignUpWrapper>
          </Grid>
        </Grid>
      </Modal>

      <ForgotPassword
        open={showForgotPassword}
        onClose={() => setShowForgotPassword(false)}
      />
    </Fragment>
  );
};

const AccountInfo = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "85%",
    position: "absolute",
    bottom: "25px",
  },
}));
const IntroImage = styled("div")(({ bg }: { bg?: string }) => ({
  background: `url("${bg || loginSignUpModalBanner}")`,
  backgroundSize: "cover",
  width: "100%",
  aspectRatio: "1/2",
  padding: "40px 20px 0",
  textAlign: "center",
  borderTopLeftRadius: 15,
  borderBottomLeftRadius: 15,
  zIndex: 2,
  position: "relative",
}));

const SignInSignUpWrapper = styled("div")(() => ({
  padding: 32,
  height: "100%",
  marginTop: 25,
}));

const SignInSignUpRowContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  marginBottom: 24,
  gap: 10,
}));

const CustomButton = styled(Button)(() => ({
  height: "40px",
  width: "100%",
}));
const StyledGradientDivider = styled(GradientDivider)(() => ({
  position: "absolute",
  left: 0,
}));
const FormWrapper = styled("div")(() => ({
  marginTop: "50px",
  width: "100%",
  height: "80%",
}));

export default SignInOrSignUp;
