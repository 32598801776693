import { forwardRef, useImperativeHandle, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Email } from "@mui/icons-material";
import { FormikValues, FormikHelpers } from "formik";

import Form from "../Components/Form";
import Input from "../Components/Form/Input";
import Submit from "../Components/Form/Submit";
import Modal, { ContentWrapper, SIZES } from "../Components/Common/Modal";
import { handleApiError } from "../Helpers";
import Validation from "../Helpers/Validation";
import useUtilityAction from "../Redux/utility/useAction";
import userApi from "../Service/api/user";

const fontColor = "#888888 !important";
const inputVariant = "outlined";

const validationSchema = Validation.createSchema({
  email: Validation.emailSchema,
});

const initialValues = {
  email: "",
};

const RecoveryEmailPrompt = forwardRef((props, ref) => {
  const { toggleSnackBar } = useUtilityAction();

  const [show, setShow] = useState(false);

  const _onClose = () => setShow(false);

  const _show = () => setShow(true);

  const _onSubmit = async (
    formData: FormikValues,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<FormikValues>
  ) => {
    const { ok, data }: any = await userApi.setRecoveryEmail({
      email: formData.email,
    });
    if (ok) {
      toggleSnackBar("success", handleApiError(data));
      resetForm();
      _onClose();
    } else {
      setErrors({ api: handleApiError(data) });
    }
    setSubmitting(false);
  };

  useImperativeHandle(ref, () => ({
    show: _show,
  }));

  return (
    <Modal
      open={show}
      size={SIZES.MEDIUM}
      title="Setup Recovery Email"
      onClose={_onClose}
      withGradient
      disableAutoFocus
    >
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        <ContentWrapper>
          <Grid spacing={2} container>
            <Grid xs={12} item>
              <Typography variant="caption">
                Note: make sure to set this up so you can recover your account
                in case you forgot your password.
              </Typography>
            </Grid>

            <Grid xs={12} item>
              <Input
                containerStyle={{ marginBottom: 10 }}
                label="Email"
                name="email"
                type="email"
                labelColor={fontColor}
                LeftIcon={Email}
                leftIconColor={fontColor}
                variant={inputVariant}
                fullWidth
              />
            </Grid>

            <Grid xs={12} item>
              <Submit variant="contained" color="success" filled fullWidth>
                Save
              </Submit>
            </Grid>
          </Grid>
        </ContentWrapper>
      </Form>
    </Modal>
  );
});

export type RecoveryEmailRef = {
  show: () => void;
};

export default RecoveryEmailPrompt;
