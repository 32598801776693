import { Grid } from "@mui/material";

import Accordion from "../../Components/Common/Accordion";
import { useConfigSelector } from "../../Redux/utility/useSelector";

import { GradientTitle } from "./styles";

function About() {
  const config = useConfigSelector();
  const appName = config?.siteSettings?.site_name;

  const faqs = [
    {
      title: `What is ${appName} VIP Club?`,
      content: `${appName} VIP Club is the place where the most active users are gathered and get exclusive rewards for it.`,
    },
    {
      title: `What is ${appName} VIP Club?`,
      content: `In order to become a member of the VIP Club you need to reach Rank 10 & your personal host will send you your invitation right away.`,
    },
    {
      title: "What are the benefits of being in the VIP Club?",
      content: `${appName} VIP Club unlocks the following benefits: -first hand information directly from the team; -weekly, birthday and other bonuses; -higher referral rate; -special VIP events&battles; -dedicated personal manager with an individual approach to solve any problem that you might be facing.`,
    },
    {
      title: "What does affect the weekly bonus?",
      content: `The only thing that affects your bonuses is your gaming activity - the more you play, the more you get. Exception is the Birthday bonus of course it is sent to the user regardless.`,
    },
    {
      title: `I want to become a part of the VIP Club, but I haven’t reached Rank 10. What should I do?`,
      content: `You need to contact one of the VIP managers. If high activity is detected the team could consider a possibility of making you a part of the VIP Club before reaching the necessary rank.`,
    },
    {
      title: "Are there any special offers for VIP Club members?",
      content: `Yes, there are a lot of them. Your manager will inform you about everything else personally in detail.`,
    },
    {
      title: `I am a member and I haven’t received any bonus yet. Why? When are they sent to the users and how often?`,
      content: `The are several reasons for it. It’s either you haven’t played on ${appName} for a long period of time or you became a VIP Club member less than a week ago and it didn’t accumulate yet. Weekly bonuses are sent three times a week - on Monday, Wednesday and Friday.`,
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid xs={12} mb={4} item>
        <GradientTitle textAlign="center">About {appName}</GradientTitle>
      </Grid>

      {faqs.map((faq, item) => (
        <Grid key={item} sm={12} md={6} item>
          <Accordion
            title={faq.title}
            titleProps={{ variant: "subtitle2", fontSize: 20 }}
            content={faq.content}
            contentProps={{ variant: "subtitle2" }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default About;
