import * as Yup from "yup";

const emailSchema = Yup.string()
  .email("Please input a valid email")
  .required("Email is required");

const passwordSchema = Yup.string()
  .required("Password is required")
  .min(6)
  .label("Password");

const confirmPasswordSchema = passwordSchema
  .nullable()
  .oneOf([Yup.ref("password"), null], "Please make sure your passwords match");

const oldPasswordSchema = Yup.string()
  .required("Old password is required")
  .min(6)
  .label("Old Password");

const newPasswordSchema = confirmPasswordSchema.label("New Password");

const confirmNewPasswordSchema = confirmPasswordSchema.nullable().oneOf(
  [Yup.ref("newPassword"), null], // newPassword is the object key of field in validation schema
  "Please make sure your passwords match"
);

/**
 * Generates a Yup schema that applies conditional required validation based on the value of another field.
 *
 * @param label - The label or name of the field being validated.
 * @param type - The type of the field. It should correspond to a valid Yup schema type.
 * @param toField - The name of the field to which the conditional validation applies.
 * @param equalToValue - The value that `toField` should match for the validation to be applied.
 * @returns The generated Yup schema with conditional required validation.
 */
function conditionalRequired(
  label: string,
  type: string,
  toField: string,
  equalToValue: string
) {
  return (Yup as any)[type]().when(toField, {
    is: (field: string) => field === equalToValue,
    then: fieldRequired(label, type),
    otherwise: (Yup as any)[type]()(),
  });
}

/**
 * Creates a Yup schema using the provided object shape.
 *
 * @param object - The object representing the shape of the schema.
 * @returns The generated Yup schema with the specified object shape.
 */
function createSchema(object: Record<string, any>) {
  return Yup.object().shape(object);
}

/**
 * Generates a Yup schema that specifies a required field with a label.
 *
 * @param label - The label or name of the field.
 * @param type - The type of the field. It should correspond to a valid Yup schema type (default: "string").
 * @returns The generated Yup schema with the required validation and label.
 */
function fieldRequired(label: string, type = "string") {
  return (Yup as any)[type]().required().label(label);
}

/**
 * Creates a Yup schema for a boolean field that is required to be `true`.
 * @param label - The label or error message for the field.
 * @returns The Yup schema for the required true field.
 */
function requiredTrue(label: string) {
  return Yup.boolean().oneOf([true], label);
}

/**
 * Generates a Yup schema based on the specified `type` with a labeled field.
 *
 * @param label - The label or name of the field.
 * @param type - The type of the Yup schema to generate.
 * @returns The generated Yup schema with the specified label.
 */
function typeSchema(label: string, type: string) {
  return (Yup as any)[type]().label(label);
}
//eslint-disable-next-line
export default {
  emailSchema,
  passwordSchema,
  confirmPasswordSchema,
  oldPasswordSchema,
  newPasswordSchema,
  confirmNewPasswordSchema,
  conditionalRequired,
  createSchema,
  fieldRequired,
  requiredTrue,
  typeSchema,
};
