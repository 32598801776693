import { Box, Grid } from "@mui/material";

import { fontColor, validationSchema } from "./Configs";
import PaymentOngoingModal from "./PaymentOngoingModal";
import useGlobalTrustPayDepositController from "./hooks/useGlobalTrustPayDepositController";

import Form from "../../Form";
import Input from "../../Form/Input";
import Submit from "../../Form/Submit";

const initialValues = {
  amount: "",
};

const GlobalTrustPay = ({ gateway }: { gateway: string }) => {
  const isDev = process.env.REACT_APP_NODE_ENV !== "production" ? true : false;
  const [state, handlers] = useGlobalTrustPayDepositController(gateway);

  return (
    <Box padding={{ xs: "20px", sm: "20px 100px", md: "40px 150px 20px" }}>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={isDev ? handlers.mockDeposit : handlers.onDeposit}
      >
        <Grid container>
          <Grid xs={12} mb={1} item>
            <Input
              name="amount"
              label="Enter amount"
              variant="outlined"
              labelColor={fontColor}
              containerStyle={{ marginBottom: 10 }}
              fullWidth
            />
          </Grid>

          <Grid xs={12} item>
            <Submit variant="contained" filled fullWidth>
              Submit
            </Submit>
          </Grid>
        </Grid>
      </Form>

      <PaymentOngoingModal
        payment_url={state.activePaymentUrl}
        open={state.isProcessing}
        isDepositComplete={state.isDepositComplete}
        depositStatus={state.depositStatus}
        depositTimestamp={state.depositTimestamp}
        onClose={handlers.closeModal}
      />
    </Box>
  );
};

export default GlobalTrustPay;
