import Validation from "../../../../Helpers/Validation";
import * as Yup from "yup";

export const fontColor = "#888888 !important";

export const validationSchema = Validation.createSchema({
  // bank: Validation.fieldRequired("Bank", "object"),
  amount: Yup.number()
    .required("This field is required!")
    .typeError("A number is required!"),
});
