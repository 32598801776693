import { useEffect, useRef } from "react";
import { Grid, styled } from "@mui/material";

import Button from "../../../Components/Common/Button";
import GameBet from "../../../Components/Games/Bet";
import GameContainer, { GCRef } from "../../../Components/Games/Container";
import TextField from "../../../Components/Common/TextField";
import { GAME_CODES } from "../../../Constants/game";
import { handleApiError, numberOnly } from "../../../Helpers";
import useBetController from "../../../Hooks/useBetController";
import useObjectState from "../../../Hooks/useObjectState";
import useUtilityAction from "../../../Redux/utility/useAction";
import { useConfigSelector } from "../../../Redux/utility/useSelector";
import gameApi from "../../../Service/api/game";
import colors from "../../../Utilities/colors";
import socket from "../../../Utilities/Websocket";

import { BettingContainer } from "../styles";

import { columns } from "./data";
import LimboGame, { LimboRef } from "./Game";

let randomMultiplierTimer: NodeJS.Timer;
const initialState = {
  target: 2,
  placingBet: false,
  placedBet: false,
  winMultiplier: undefined,
  isWin: false,
};
function Limbo() {
  const gameContainerRef = useRef<GCRef | null>(null);
  const limboRef = useRef<LimboRef | null>(null);
  const config = useConfigSelector();
  const { toggleSnackBar } = useUtilityAction();
  const [betState, betHandler] = useBetController();

  const [state, updateState] = useObjectState(initialState);
  const { target, placingBet, placedBet, winMultiplier, isWin } = state;

  useEffect(() => {
    let percentPerTick = 0;
    if (placedBet) {
      // show incremental number of winMultiplier
      randomMultiplierTimer = setInterval(() => {
        const percentInDecimal = percentPerTick / 100;
        const result = (winMultiplier * percentInDecimal).toFixed(2);
        const color = colors.white;
        _changeText(result, color);
        percentPerTick += 5.263; // 5.263% per tick
      }, 50);
    } else if (winMultiplier) {
      // show result when rocket animation end
      const result = winMultiplier.toFixed(2);
      const color = isWin ? colors.green : colors.red;
      _changeText(result, color);
      clearInterval(randomMultiplierTimer);
    }
    return () => {
      clearInterval(randomMultiplierTimer);
    }; // eslint-disable-next-line
  }, [placedBet]);

  const _placeBet = async () => {
    const isOk = betHandler.validate([
      { condition: target < 1.01, message: "Minimum target is 1.01" },
    ]);
    if (isOk) {
      updateState({ placingBet: true });
      const payload = { bet_amount: betState.bet, target };
      const { ok, data }: any = await gameApi.getCrashpoint(payload);
      if (ok) {
        const { is_win, multiplier } = data;
        limboRef.current!.start();
        updateState({
          placingBet: false,
          placedBet: true,
          winMultiplier: multiplier,
          isWin: is_win,
        });
      } else {
        updateState({ placingBet: false });
        toggleSnackBar("error", handleApiError(data));
      }
    }
  };

  const _onDone = () => {
    updateState({ placedBet: false });
    limboRef.current!.pushMultiplier({
      value: winMultiplier,
      win: isWin,
    });
    socket.emit(config.webSocketEvents.UPDATE_USER_BALANCE);
    gameContainerRef.current?.refreshHistory(); // refetch game history
  };

  const _changeText = (value: string, color: string) => {
    limboRef.current!.multiplierText.style.color = color;
    limboRef.current!.multiplierText.textContent = `${value}x`;
  };

  return (
    <GameContainer
      ref={gameContainerRef}
      gameCode={GAME_CODES.LIMBO}
      tableColumn={columns}
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12} lg={9} item>
          <LimboGame ref={limboRef} onDone={_onDone} />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={3} mt={2} item>
          <BettingContainer>
            <Grid container pl={2} pr={2} pb={2} spacing={2}>
              <GameBet
                bets={[20, 50, 100, 500]}
                containerProps={{ item: true, spacing: 2 }}
                disabled={placedBet}
                itemProps={{ xs: 3, sm: 3, md: 3, lg: 6 }}
                onChange={betHandler.onBetChange}
                value={betState.bet}
              />
              <Grid alignItems="center" xs={12} spacing={2} container item>
                <Grid xs={4} lg={12} item>
                  <TextField
                    label="Target"
                    labelColor="#fff"
                    value={target}
                    onChange={numberOnly((val: any) => {
                      updateState({ target: val });
                    })}
                    disabled={placedBet}
                    fullWidth
                  />
                </Grid>
                <Grid xs={8} lg={12} item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={_placeBet}
                    loading={placingBet}
                    disabled={placingBet || placedBet}
                    filled
                    fullWidth
                  >
                    Bet
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </BettingContainer>
        </Grid>
      </Grid>
    </GameContainer>
  );
}

export default Limbo;
