import { Grid, Typography, styled } from "@mui/material";

import Logo from "../Assets/Logos/logo.png";

function MaintenanceMode({ customLogo }: { customLogo?: any }) {
  return (
    <Container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      pt={5}
      pb={5}
      container
    >
      <Grid direction="column" spacing={2} container item>
        <Grid item>
          <Typography variant="h6" fontWeight="bold" color="secondary">
            Under Maintenance
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h2" fontWeight="bold" color="primary">
            We'll Be Back Soon.
          </Typography>
        </Grid>

        <Grid item>
          <Typography align="center" variant="caption">
            We appoligize for the inconvenience but we are currently undergoing
            planned maintenance.
          </Typography>
        </Grid>
      </Grid>

      <Grid justifyContent="flex-end" container item>
        <Grid item>
          <Image src={customLogo || Logo} />
        </Grid>
      </Grid>
    </Container>
  );
}

const Container = styled(Grid)({
  height: "100vh",
  paddingLeft: "10vw",
  paddingRight: "10vw",
});

const Image = styled("img")(({ theme }) => ({
  height: 55,
  width: "auto",
  maxWidth: "100%",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    width: "100%",
  },
}));

export default MaintenanceMode;
