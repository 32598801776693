import { styled } from "@mui/material";

import freeSpinRoulette from "../../../Assets/icons/free-spin-roulette.png";
export const Wrapper = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  marginBottom: 10,
});

export const Container = styled("div")({
  transform: "rotate(-47deg)",
});

export const Overlay = styled("div")({
  width: "100%",
  height: "100%",
  position: "absolute",
  background: `url("${freeSpinRoulette}")`,
  backgroundSize: "100% 100%",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  zIndex: 1,
  top: -1,
  left: 1,
});

export const ImageContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "17%",
  height: "17%",
  transform: "translate(-50%, -50%)",
  borderRadius: "50%",
  overflow: "hidden",
});

export const BetContainer = styled("div")(({ theme }) => ({
  position: "relative",
  transform: "rotate(-45deg)",
  // ".started-spinning": {
  //   transformStyle: "preserve-3d",
  //   backfaceVisibility: "hidden",
  //   willChange: "animation",
  //   animationFillMode: "forwards",
  //   transform: "translate3d(0,0,0)",
  //   backgroundColor: "transparent",
  // },
  "[alt='roulette-static']": {
    width: "10%",
    right: 35,
    top: 40,
  },
  [theme.breakpoints.down(500)]: {
    "[alt='roulette-static']": {
      width: "7%",
      right: 35,
      top: 40,
    },
  },
  [theme.breakpoints.down(380)]: {
    "[alt='roulette-static']": {
      width: "7%",
      right: 30,
      top: 33,
    },
  },
  [theme.breakpoints.down(300)]: {
    "[alt='roulette-static']": {
      width: "6%",
      right: 25,
      top: 28,
    },
  },
}));

export const Placeholder = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
});

export const ModelContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  right: "64%",
  top: 0,
  bottom: 0,
  zIndex: 1,
  img: {
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
