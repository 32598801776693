import { Fragment, useState } from "react";
import { Grid, styled, useMediaQuery } from "@mui/material";
import GradientTypography from "../Common/GradientTypography";
import topGamesIcon from "../../Assets/icons/game-history-icon.png";
import { HoverPlay, PlayIcon, StyleIconButton } from "../InHouseGames/Games";
import ComingSoonModal from "../ComingSoonModal";
import { inhouseGames } from "../InHouseGames/Constants";
import { useNavigate } from "react-router-dom";
import useUserSelector from "../../Redux/user/useSelector";
import SnakeImpactImage from "../../Assets/Images/snake-impact.jpg";

const GameIcon = ({ game, index }: any) => {
  const navigate = useNavigate();

  return (
    <StyleIconButton>
      <GameImage
        className="game-image"
        src={game?.image}
        alt={`game-${index}`}
      />
      <HoverPlay onClick={() => navigate(game?.route)} className="play">
        <PlayIcon />
        <GradientTypography fontWeight="bold">Play</GradientTypography>
      </HoverPlay>
    </StyleIconButton>
  );
};

const SnakeGameIcon = ({ game, index }: any) => {
  const {user} = useUserSelector();
  
  const email = window.btoa(user?.email)

  return (
    <StyleIconButton>
      <GameImage
        className="game-image"
        src={game?.image}
        alt={`game-${index}`}
      />
      <HoverPlay
        onClick={() => window.open(`https://lucky175.site?username=${email}`)}
        className="play"
      >
        <PlayIcon />
        <GradientTypography fontWeight="bold">Play</GradientTypography>
      </HoverPlay>
    </StyleIconButton>
  );
};

const MobileView = () => {
  const snakeIndex = inhouseGames.length;
  const snakeGame = {
    image: SnakeImpactImage,
  }

  return (
    <MobileViewGridContainer container direction="column">
      <Grid justifyContent="center" wrap="nowrap" xs={12} item container>
        {inhouseGames.slice(0, 2).map((game, index) => (
          <Grid item>
            <GameIcon game={game} index={index} />
          </Grid>
        ))}
      </Grid>
      <Grid justifyContent="center" wrap="nowrap" xs={12} item container>
        {inhouseGames.slice(2).map((game, index) => (
          <Grid item>
            <GameIcon game={game} index={index} />
          </Grid>
        ))}
      </Grid>
      <Grid justifyContent="center" wrap="nowrap" xs={12} item container>
        <Grid item>
          <SnakeGameIcon game={snakeGame} index={snakeIndex} />
        </Grid>
      </Grid>
    </MobileViewGridContainer>
  );
};

const TopGames = ({ showTitle = true }: { showTitle?: boolean }) => {
  const { user } = useUserSelector();

  const email = window.btoa(user?.email);

  const navigate = useNavigate();
  const [comingSoon, setComingSoon] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width: 1200px)");
  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <Fragment>
      <ComingSoonModal onClose={() => setComingSoon(false)} open={comingSoon} />
      <Grid container direction="column" gap={1}>
        {showTitle ? (
          <Grid item container alignItems="center" gap={1}>
            <Grid item>
              <img
                height={20}
                width={20}
                src={topGamesIcon}
                alt="top-games-icon"
              />
            </Grid>
            <Grid item>
              <GradientTypography
                sx={{ marginBottom: "6px" }}
                fontSize={20}
                fontWeight="bold"
              >
                Top Games
              </GradientTypography>
            </Grid>
          </Grid>
        ) : null}
        {mobileView ? (
          <MobileView />
        ) : (
          <Grid item>
            <GamesContainer isSmallerScreen={isSmallerScreen}>
              <StyleIconButton>
                <GameImage
                  className="game-image"
                  src={SnakeImpactImage}
                  alt="game-0"
                />
                <HoverPlay
                  onClick={() =>
                    window.open(`https://lucky175.site?username=${email}`)
                  }
                  className="play"
                >
                  <PlayIcon />
                  <GradientTypography fontWeight="bold">
                    Play
                  </GradientTypography>
                </HoverPlay>
              </StyleIconButton>
              {inhouseGames.map((game, index) => {
                return (
                  <StyleIconButton>
                    <GameImage
                      className="game-image"
                      src={game?.image}
                      alt={`game-${index}`}
                    />
                    <HoverPlay
                      onClick={() => navigate(game?.route)}
                      className="play"
                    >
                      <PlayIcon />
                      <GradientTypography fontWeight="bold">
                        Play
                      </GradientTypography>
                    </HoverPlay>
                  </StyleIconButton>
                );
              })}
            </GamesContainer>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default TopGames;

const MobileViewGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    // marginTop: "-90px",
  },
}));
const GamesContainer = styled("div")<{ isSmallerScreen?: boolean }>(
  ({ theme, isSmallerScreen }) => ({
    display: "flex",
    justifyContent: isSmallerScreen ? "center" : "space-between",
    alignItems: "center",
    gap: isSmallerScreen ? 5 : 0,
    wrap: isSmallerScreen ? "wrap" : "nowrap",
    [theme.breakpoints.down("sm")]: {
      // marginTop: "-90px",
    },
  })
);
export const GameImage = styled("img")(({ theme }) => ({
  borderRadius: "15px",
  transition: "scale 0.1s linear",
  height: 220,
  [theme.breakpoints.down("md")]: {
    height: 180,
  },
  [theme.breakpoints.down("sm")]: {
    height: 145,
  },
}));
