import { Fragment, useEffect, useRef } from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import lodashIsEmpty from "lodash/isEmpty";

import bombImage from "../../../Assets/Images/mines-bomb.png";
import Balance from "../../../Components/Common/Balance";
import Button from "../../../Components/Common/Button";
import Modal, { ContentWrapper, SIZES } from "../../../Components/Common/Modal";
import Select from "../../../Components/Common/Select";
import GameBet from "../../../Components/Games/Bet";
import GameContainer, { GCRef } from "../../../Components/Games/Container";
import { CURRENCY_SYMBOL } from "../../../Constants";
import { GAME_CODES } from "../../../Constants/game";
import { commafyNumber, handleApiError } from "../../../Helpers";
import useBetController from "../../../Hooks/useBetController";
import useObjectState from "../../../Hooks/useObjectState";
import useUtilityAction from "../../../Redux/utility/useAction";
import { useConfigSelector } from "../../../Redux/utility/useSelector";
import gameApi from "../../../Service/api/game";
import socket from "../../../Utilities/Websocket";

import { BettingContainer } from "../styles";

import { columns } from "./data";
import MinesGame, { renderTiles } from "./Game";

let tileValues: any[][];
const displayTilesDuration = 2000;
const initialState = {
  bombs: 4,
  forceUpdateCount: 0, // this is just a counter to force update the UI
  gettingSession: true,
  isBetPlaced: false,
  isGotBomb: false,
  placingBet: false,
  revealing: false, // this is for api call loader when tile is clicked
  viewResult: undefined, // this is for viewing the board result on game history
  winStreak: 0, // this will be reset when user got bomb
};
function Mines() {
  const gameContainerRef = useRef<GCRef | null>(null);
  const { toggleSnackBar } = useUtilityAction();
  const config = useConfigSelector();
  const [betState, betHandler] = useBetController();

  const [
    {
      bombs,
      forceUpdateCount,
      gettingSession,
      isBetPlaced,
      isGotBomb,
      placingBet,
      revealing,
      viewResult,
      winStreak,
    },
    updateState,
  ] = useObjectState(initialState);

  // when not yet clicking on the board or got bomb
  const disableCashout = !winStreak || isGotBomb || revealing;
  const disablePlaceBet = placingBet || gettingSession;
  // prettier-ignore
  const disableButton = isBetPlaced ? disableCashout : disablePlaceBet;
  const multipliers = config.mines.multipliers;

  useEffect(() => {
    tileValues = JSON.parse(JSON.stringify(config.mines.data)); // set tileValues from backend config
    _getSession();
    // eslint-disable-next-line
  }, []);

  // behaviour
  const _getSession = async () => {
    const updateObj: any = {};
    const { ok, data }: any = await gameApi.getMinesSession();
    if (ok && !lodashIsEmpty(data)) {
      const { bet_amount, bombs, board_data, wins } = data;
      if (!lodashIsEmpty(board_data)) {
        _changeTileData(board_data);
      }
      updateObj.bombs = bombs;
      updateObj.isBetPlaced = true;
      updateObj.winStreak = wins;
      betHandler.onBetChange(bet_amount);
    }
    updateObj.gettingSession = false;
    updateState(updateObj);
  };

  const _onPlaceBet = async () => {
    const isOk = betHandler.validate();
    if (isOk) {
      const updateObj: any = { placingBet: false };
      const payload = { bet_amount: betState.bet, bombs };
      updateState({ placingBet: true });
      const { ok, data, status }: any = await gameApi.minesPlaceBet(payload);
      if (ok) {
        updateObj.isBetPlaced = true;
        socket.emit(config.webSocketEvents.UPDATE_USER_BALANCE);
      } else {
        const errorMessage = handleApiError(data);
        if (status === 400 && data.redirect) {
          _reloadAfterError(errorMessage);
        } else {
          toggleSnackBar("error", errorMessage);
        }
      }
      updateState(updateObj);
    }
  };

  const _onTileClick = async (column: number, row: number) => {
    const payload = {
      bombs,
      column,
      data: tileValues,
      row,
      wins: winStreak,
    };
    updateState({ revealing: true });
    const { ok, data, status }: any = await gameApi.getTileResult(payload);
    if (ok) {
      const { is_done, result } = data;
      const isDiamond = result === config.mines.legends.diamond;
      if (isDiamond) {
        tileValues[column][row] = result; // show diamond on the board
        updateState({ winStreak: winStreak + 1, revealing: false });
      } else {
        tileValues[column][row] = result; // show bomb on the board
        _onGotBomb();
        updateState({ revealing: false }); // remove revealing flag not diamond
      }
      if (isDiamond && is_done) {
        _onCashout(true);
      }
    } else {
      const errorMessage = handleApiError(data);
      if (status === 400 && data.redirect) {
        _reloadAfterError(errorMessage);
      } else {
        toggleSnackBar("error", errorMessage);
      }
      updateState({ revealing: false }); // remove revealing flag if error occur
    }
  };

  const _onGotBomb = async () => {
    updateState({ isGotBomb: true });
    await _showTiles();
    await new Promise((resolve) => setTimeout(resolve, displayTilesDuration));
    _reset();
  };

  const _onCashout = async (autoEnd?: boolean) => {
    const isOk = await _showTiles();
    if (isOk) {
      // -1 if not auto end because winStreak start from 1
      // no -1 if autoEnd true bcs winStreak is outdated value due to setState dleay
      const wins = autoEnd ? winStreak : winStreak - 1;
      const multiplier = multipliers[bombs][wins];
      const won = betState.bet * multiplier;
      socket.emit(config.webSocketEvents.UPDATE_USER_BALANCE);
      toggleSnackBar(
        "success",
        `You won ${CURRENCY_SYMBOL}${commafyNumber(won, true)} (${multiplier}x)`
      );
      await new Promise((resolve) => setTimeout(resolve, displayTilesDuration));
      _reset();
    }
  };

  const _showTiles = async () => {
    const payload = { bombs, data: tileValues };
    const { ok, data }: any = await gameApi.showTiles(payload);
    if (ok) {
      _changeTileData(data);
      _forceUpdate();
    } else {
      _reloadAfterError(handleApiError(data));
    }
    return ok;
  };

  const _forceUpdate = () => {
    updateState({ forceUpdateCount: forceUpdateCount + 1 });
  };

  const _reset = () => {
    // reset tiles
    _changeTileData(); // pass nothing for reset
    // reset some state
    updateState({ isBetPlaced: false, isGotBomb: false, winStreak: 0 });
    gameContainerRef.current?.refreshHistory(); // refetch game history
  };

  const _changeTileData = (data?: Array<Array<any>>) => {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        for (let x = 0; x < data[i].length; x++) {
          tileValues[i][x] = data[i][x];
        }
      }
    } else {
      for (let i = 0; i < tileValues.length; i++) {
        for (let x = 0; x < tileValues[i].length; x++) {
          tileValues[i][x] = undefined;
        }
      }
    }
  };

  const _reloadAfterError = (msg: string) => {
    toggleSnackBar("error", msg);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const _onViewBoard = (data?: any) => updateState({ viewResult: data });

  // renders
  const _renderProfit = (
    label: string,
    multiplier: number,
    isNext?: boolean
  ) => {
    if (!multiplier) {
      // if no multiplier pass it means user got all the multiplier already
      return null;
    }
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems={{
          xs: isNext ? "flex-end" : "flex-start",
          lg: "flex-start",
        }}
      >
        <Typography variant="subtitle2">{label}</Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={{ xs: isNext ? "fit-content" : "100%", lg: "100%" }}
        >
          <Balance value={Number(betState.bet || 0) * multiplier} normal />
          <ProfitMultiplier>{multiplier.toFixed(2)}x</ProfitMultiplier>
        </Box>
      </Box>
    );
  };

  if (!tileValues) {
    return null;
  }

  return (
    <Fragment>
      <GameContainer
        ref={gameContainerRef}
        gameCode={GAME_CODES.MINES}
        tableColumn={columns(_onViewBoard)}
      >
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={12} lg={9} item>
            <MinesGame
              bombs={bombs}
              data={tileValues}
              wins={winStreak}
              disabled={!isBetPlaced || isGotBomb || revealing}
              onClick={_onTileClick}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={3} mt={2} item>
            <BettingContainer>
              <Grid container pl={2} pr={2} pb={2} spacing={2}>
                {/* Game bet */}
                <GridHideOnBreakpoint
                  xs={12}
                  hide={isBetPlaced.toString()}
                  item
                >
                  <GameBet
                    bets={[20, 50, 100, 500]}
                    containerProps={{ item: true, spacing: 2 }}
                    disabled={isBetPlaced}
                    itemProps={{ xs: 3, sm: 3, md: 3, lg: 6 }}
                    onChange={betHandler.onBetChange}
                    value={betState.bet}
                  />
                </GridHideOnBreakpoint>
                {/* bombs dropdown */}
                <GridHideOnBreakpoint
                  hide={isBetPlaced.toString()}
                  xs={4}
                  lg={12}
                  display="flex"
                  alignItems="center"
                  item
                >
                  <img
                    alt="mines-count"
                    src={bombImage}
                    width="40px"
                    height="auto"
                    style={{ marginRight: 8 }}
                  />
                  <Select
                    options={Array.from({ length: 24 }, (_, i) => i + 1)}
                    value={bombs}
                    getOptionLabel={(option: any) => option}
                    onChange={(evt: any) => {
                      updateState({ bombs: evt.target.value });
                    }}
                    disabled={isBetPlaced}
                    fullWidth
                  />
                </GridHideOnBreakpoint>
                {/* Current Profit */}
                {isBetPlaced && (
                  <Grid xs={6} lg={12} item>
                    {_renderProfit(
                      "Current Profit:",
                      winStreak
                        ? multipliers[bombs as keyof typeof multipliers][
                            winStreak - 1 // -1 bcs, multipliers start at 1 and winstreak start from 0s
                          ]
                        : 1.0
                    )}
                  </Grid>
                )}
                {/* Next Click Profit */}
                {isBetPlaced && (
                  <Grid xs={6} lg={12} item>
                    {_renderProfit(
                      "Next Click Profit:",
                      multipliers[bombs as keyof typeof multipliers][winStreak],
                      true
                    )}
                  </Grid>
                )}
                {/* Place bet / cash out button */}
                <Grid xs={isBetPlaced ? 12 : 8} lg={12} item>
                  <Button
                    variant="contained"
                    color={isBetPlaced ? "success" : "error"}
                    disabled={disableButton}
                    onClick={isBetPlaced ? () => _onCashout() : _onPlaceBet}
                    loading={placingBet}
                    filled
                    fullWidth
                  >
                    {isBetPlaced ? "Cash out" : "Place Bet"}
                  </Button>
                </Grid>
              </Grid>
            </BettingContainer>
          </Grid>
        </Grid>
      </GameContainer>

      <Modal
        size={SIZES.MEDIUM}
        open={!lodashIsEmpty(viewResult)}
        onClose={() => _onViewBoard()}
        title="Board Result"
      >
        <ContentWrapper>
          {renderTiles({
            data: viewResult,
            legends: config.mines.legends,
            view: true,
          })}
        </ContentWrapper>
      </Modal>
    </Fragment>
  );
}

const GridHideOnBreakpoint = styled(Grid)(
  ({ theme, hide }: { theme?: any; hide: string }) => {
    if (hide === "true") {
      return {
        [theme.breakpoints.down("lg")]: {
          display: "none",
        },
      };
    } else {
      return {};
    }
  }
);

const ProfitMultiplier = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export default Mines;
