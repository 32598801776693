import { useEffect, useState } from "react";
import lodashIsEmpty from "lodash/isEmpty";

import useCheckSession from "./useCheckSession";
import useUtilityAction from "../Redux/utility/useAction";
import { useConfigSelector } from "../Redux/utility/useSelector";
import socket from "../Utilities/Websocket";

const useWebsocket = () => {
  const _checkSession = useCheckSession();
  const { toggleSnackBar } = useUtilityAction();
  const config = useConfigSelector();

  const [isWebsocketConnecting, setIsWebsocketConnecting] =
    useState<boolean>(true);

  const onConnect = () => {
    if (isWebsocketConnecting) {
      setIsWebsocketConnecting(false);
    }
  };
  const onDisconnect = () => {
    alert("Disconnected please refresh the page");
    window.location.reload();
  };

  useEffect(() => {
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect");
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!lodashIsEmpty(config)) {
      socket.on(config.webSocketEvents.DISCONNECT_USER, socket.disconnect);
      socket.on(config.webSocketEvents.UPDATE_USER_BALANCE, _checkSession);
      socket.on(config.webSocketEvents.TRIGGER_TOAST, ({ type, message }) => {
        toggleSnackBar(type, message);
      });
    }
    //eslint-disable-next-line
  }, [config]);

  return isWebsocketConnecting;
};

export default useWebsocket;
