import { Grid, styled } from "@mui/material";
import { EmailOutlined, KeyboardArrowLeft } from "@mui/icons-material";
import { FormikValues, FormikHelpers } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

import Button from "../../Common/Button";
import Modal, { SIZES } from "../../Common/Modal";
import Form from "../../Form";
import Input from "../../Form/Input";
import Submit from "../../Form/Submit";
import Validation from "../../../Helpers/Validation";
import useUtilityAction from "../../../Redux/utility/useAction";
import userApi from "../../../Service/api/user";
import { handleApiError } from "../../../Helpers";
import { useRef } from "react";
import { customTheme } from "../../../Utilities/theme";

type Props = {
  open: boolean;
  onClose: () => void;
};

const fontColor = "#888888 !important";
const inputVariant = "outlined";

const validationSchema = Validation.createSchema({
  email: Validation.emailSchema,
});

const initialValues = {
  email: "",
};

function ForgotPassword({ open, onClose }: Props) {
  const captchaRef = useRef(null);
  const { toggleSnackBar } = useUtilityAction();

  const _onSubmit =
    (captchaRef: any) =>
    async (
      formData: FormikValues,
      { setSubmitting, resetForm }: FormikHelpers<FormikValues>
    ) => {
      const token = captchaRef.current.getValue();
      const { ok, data }: any = await userApi.recoverAccount({
        email: formData.email,
        captcha_token: token,
      });
      if (ok) {
        toggleSnackBar("success", handleApiError(data));
        resetForm();
        onClose();
      } else {
        toggleSnackBar("error", handleApiError(data));
      }
      setSubmitting(false);
    };

  return (
    <Modal
      size={SIZES.EXTRA_SMALL}
      open={open}
      onClose={onClose}
      title="Reset Password"
      withGradient
    >
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit(captchaRef)}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                containerStyle={{ marginBottom: 10 }}
                label="Enter your Recover Email"
                name="email"
                type="email"
                labelColor={fontColor}
                LeftIcon={EmailOutlined}
                leftIconColor={fontColor}
                variant={inputVariant}
                fullWidth
              />
            </Grid>

            <Grid xs={12} item>
              {/* <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ""}
              /> */}
            </Grid>

            <Grid item xs={3}>
              <Button
                style={{ height: "40px" }}
                onClick={onClose}
                fullWidth
                defaultOutlineBorder
              >
                <KeyboardArrowLeft
                  style={{ color: "white", marginTop: "5px" }}
                />
              </Button>
            </Grid>

            <Grid item xs={9}>
              <Submit color="primary" filled fullWidth>
                Reset Password
              </Submit>
            </Grid>
          </Grid>
        </Container>
      </Form>
    </Modal>
  );
}

const Container = styled("div")({
  padding: "0px 24px 24px",
});

export default ForgotPassword;
