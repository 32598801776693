import { Grid, Typography, styled, useMediaQuery } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import DataTable from "../../Components/Common/Datatable";
import { commafyNumber, copyToClipboard, handleApiError } from "../../Helpers";
import useDatatablePagination from "../../Hooks/useDatatablePagination";
import useUserSelector from "../../Redux/user/useSelector";
import useUtilityAction from "../../Redux/utility/useAction";
import { useConfigSelector } from "../../Redux/utility/useSelector";
import userApi from "../../Service/api/user";

import GradientTypography from "../../Components/Common/GradientTypography";
import { customTheme } from "../../Utilities/theme";
import { columns } from "./constants";

import { useNavigate } from "react-router-dom";
import affiliateLinkIcon from "../../Assets/icons/affiliate-link-icon.png";
import commisionsIcon from "../../Assets/icons/commisions-icon.png";
import copyIcon from "../../Assets/icons/copy-icon.png";

const statsGridProps = {
  display: "flex",
  flexDirection: "column" as any,
  alignItems: "center",
  xs: 6,
  sm: 4,
  md: 3,
  lg: 2,
  item: true,
};

const LabelWithCustomIcon = ({
  label,
  iconSrc,
}: {
  label: string;
  iconSrc: string;
}) => {
  return (
    <GradientTypography
      fontWeight="bold"
      textTransform="capitalize"
      align="center"
      fontSize={12}
      style={{ display: "flex", alignItems: "center", gap: 8 }}
    >
      <img src={iconSrc} alt={label} /> <span>{label}</span>
    </GradientTypography>
  );
};

const CustomBalanceRender = ({
  value,
  isMoney,
}: {
  value: string;
  isMoney?: boolean;
}) => {
  return (
    <GradientTypography fontWeight="bold" fontSize={20}>
      {isMoney ? "₱" : null}{" "}
      {isMoney ? commafyNumber(Number(value), true) : value}
    </GradientTypography>
  );
};

const statsLabelProps = {
  textAlign: "center" as any,
  marginBottom: "12px",
  variant: "caption" as any,
  fontWeight: "bold",
};

function AffiliateProgramV2() {
  const navigate = useNavigate();
  const downMediumScreen = useMediaQuery("(max-width:900px)");
  const { isLogin, user } = useUserSelector();
  const { toggleSnackBar } = useUtilityAction();
  const config = useConfigSelector();
  const [state, handler] = useDatatablePagination((page: number) => {
    const params = [
      { key: "page", value: page },
      { key: "v2", value: true },
    ];
    return userApi.getReferrals(params);
  });

  const [data, setData] = useState() as any;

  const referralLink = `${window.location.origin}/?ref=${user?.username}`;

  const firstLevelCommissionDecimal = !!user?.commission_percent
    ? user.commission_percent.first_level / 100
    : config.defaultCommissionV2 / 100; // value of this is decimal
  const secondLevelCommissionDecimal = !!user?.commission_percent
    ? user.commission_percent.second_level / 100
    : config.defaultCommissionV2 / 100; // value of this is decimal
  const thirdLevelCommissionDecimal = !!user?.commission_percent
    ? user.commission_percent.third_level / 100
    : config.defaultCommissionV2 / 100; // value of this is decimal

  const active = data?.active || 0;
  const inActive = data?.inactive || 0;

  useEffect(() => {
    if (isLogin) {
      _getReferralCounts();
    } else {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isLogin]);

  const _getReferralCounts = async () => {
    const { ok, data }: any = await userApi.getReferralCounts();
    if (ok) {
      setData(data);
    } else {
      toggleSnackBar("error", handleApiError(data));
    }
  };

  const _onCopy = () => {
    copyToClipboard(referralLink);
    toggleSnackBar("success", "Successfully copied to clipboard");
  };

  return (
    <Fragment>
      <MainGridContainer padding={{ xs: 0, sm: "24px" }}>
        <Grid gap={{ xs: 2, lg: 0 }} container direction="column">
          {/* Title */}
          <Grid sx={{ marginBottom: 4 }} xs={12} item>
            <GradientTypography
              variant="h5"
              textAlign="center"
              textTransform="capitalize"
              fontWeight="bold"
            >
              Affiliate dashboard
            </GradientTypography>
          </Grid>

          {/* Stats row */}
          <Grid xs={12} item>
            <StatsContainer>
              <Grid justifyContent="center" padding="16px" gap={1} container>
                <Grid {...statsGridProps}>
                  <CustomBalanceRender
                    isMoney
                    value={data?.total_deposit || 0}
                  />
                  <Typography {...statsLabelProps}>Total Deposits</Typography>
                </Grid>

                <Grid {...statsGridProps}>
                  <CustomBalanceRender
                    isMoney
                    value={data?.total_withdraw || 0}
                  />
                  <Typography {...statsLabelProps}>Total Withdraw</Typography>
                </Grid>

                <Grid {...statsGridProps}>
                  <CustomBalanceRender
                    isMoney
                    value={data?.total_balance || 0}
                  />
                  <Typography {...statsLabelProps}>
                    Total Affiliate Balance
                  </Typography>
                </Grid>

                <Grid {...statsGridProps}>
                  <CustomBalanceRender
                    isMoney
                    value={data?.total_commissions || 0}
                  />
                  <Typography {...statsLabelProps}>Total Commission</Typography>
                </Grid>

                <Grid {...statsGridProps}>
                  <CustomBalanceRender value={data?.total_affiliate || 0} />
                  <Typography {...statsLabelProps}>Total Affiliates</Typography>
                </Grid>
              </Grid>
            </StatsContainer>
          </Grid>
          {/* Commission percent */}
          <Grid
            wrap={downMediumScreen ? "wrap" : "nowrap"}
            gap={2}
            container
            xs={12}
            item
          >
            <Grid xs={12} md={6} item>
              <MyCommisionsContainer>
                <Typography fontWeight="bold" fontSize={20}>
                  {firstLevelCommissionDecimal * 100}%
                </Typography>
                <LabelWithCustomIcon
                  label="My commission"
                  iconSrc={commisionsIcon}
                />
              </MyCommisionsContainer>
            </Grid>
            <Grid xs={12} md={6} item>
              <MyCommisionsContainer>
                <Typography fontWeight="bold" fontSize={20}>
                  {secondLevelCommissionDecimal * 100}%
                </Typography>
                <LabelWithCustomIcon
                  label="My commission"
                  iconSrc={commisionsIcon}
                />
              </MyCommisionsContainer>
            </Grid>
            <Grid xs={12} md={6} item>
              <MyCommisionsContainer>
                <Typography fontWeight="bold" fontSize={20}>
                  {thirdLevelCommissionDecimal * 100}%
                </Typography>
                <LabelWithCustomIcon
                  label="My commission"
                  iconSrc={commisionsIcon}
                />
              </MyCommisionsContainer>
            </Grid>
          </Grid>
          <AffiliateLinkGrid item xs={12} md={6}>
            <AffiliateLinkItemsFlexWrapper>
              <ReferralLinkContainer>
                <span>{referralLink}</span>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={_onCopy}
                  src={copyIcon}
                  alt="copy-icon"
                />
              </ReferralLinkContainer>
              <LabelWithCustomIcon
                label="My Affiliate Link"
                iconSrc={affiliateLinkIcon}
              />
            </AffiliateLinkItemsFlexWrapper>
          </AffiliateLinkGrid>
        </Grid>
      </MainGridContainer>

      {/* Affiliate List */}
      <Grid container mb={2}>
        <Grid xs={12} item>
          <DataTable
            columns={columns}
            data={state.data}
            error={state.error}
            loading={state.loading}
            page={state.page}
            totalPages={state.totalPage}
            onPageChange={handler.setPage}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

const MainGridContainer = styled(Grid)(() => ({
  color: "#fff",
  width: "100%",
  borderRadius: 24,
  marginBottom: 24,
}));
const StatsContainer = styled("div")({
  backgroundColor: customTheme.transparentBg,
  borderRadius: 24,
});
const MyCommisionsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,
  alignItems: "center",
  justifyContent: "center",
  background: customTheme.transparentBg,
  borderRadius: 24,
  minHeight: 100,
}));
const AffiliateLinkGrid = styled(Grid)(() => ({
  background: customTheme.transparentBg,
  borderRadius: 24,
  minHeight: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "1rem",
}));
const AffiliateLinkItemsFlexWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));
const ReferralLinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  [theme.breakpoints.down("sm")]: {
    span: {
      fontSize: 14,
    },
  },
}));

export default AffiliateProgramV2;
