import DataTable from "../../Components/Common/Datatable";
import useDatatablePagination from "../../Hooks/useDatatablePagination";
import userApi from "../../Service/api/user";

import { columns } from "./utils";

const TransactionHistory = () => {
  const [state, handler] = useDatatablePagination((page: number) => {
    const params = [{ key: "page", value: page }];
    return userApi.getTransactions(params);
  });

  return (
    <DataTable
      title="Transaction History"
      columns={columns}
      data={state.data}
      error={state.error}
      loading={state.loading}
      page={state.page}
      totalPages={state.totalPage}
      onPageChange={handler.setPage}
    />
  );
};

export default TransactionHistory;
