import DataTable from "../../Components/Common/Datatable";
import { columns } from "./utils";
import useGameHistories from "./hooks/useGameHistories";

const GameHistory = () => {
  const [state, handler] = useGameHistories();

  return (
    <DataTable
      title="Game History"
      columns={columns}
      data={state.data}
      error={state.error}
      loading={state.loading}
      page={state.page}
      totalPages={state.totalPage}
      onPageChange={handler.setPage}
    />
  );
};

export default GameHistory;
