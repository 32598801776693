import { CircularProgress, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import Form from "../../Form";
import Input from "../../Form/Input";
import Submit from "../../Form/Submit";
import Modal, { ContentWrapper, SIZES } from "../../Common/Modal";
import { CheckCircle, HighlightOff } from "@mui/icons-material";
import TextLoader from "../../TextLoader";
import Button from "../../Common/Button";
import { fontColor, validationSchema } from "./Configs";
import useArxDepositController from "./hooks/useArxDepositController";

const ArxGcashGateway = () => {
  const [state, handlers] = useArxDepositController();

  return (
    <Fragment>
      {state.isCheckingSession ? (
        <Grid
          sx={{ height: "100%" }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Form
          initialValues={state.initialValues}
          validationSchema={validationSchema}
          onSubmit={handlers.onDeposit}
        >
          <Grid container>
            <Grid xs={12} item>
              <Input
                name="amount"
                label="Enter amount"
                variant="outlined"
                labelColor={fontColor}
                containerStyle={{ marginBottom: 10 }}
                fullWidth
                disabled={state.isProcessing}
              />
            </Grid>

            <Grid xs={12} item>
              <Submit loading={state.isProcessing} variant="contained">
                Submit
              </Submit>
            </Grid>
          </Grid>
        </Form>
      )}

      <Modal
        title="Deposit"
        onClose={
          state.isDepositComplete
            ? handlers.closePaymentProcessingModal
            : () => {}
        }
        size={SIZES.EXTRA_SMALL}
        open={state.isProcessing}
        withGradient
      >
        <ContentWrapper>
          {state.isDepositComplete ? (
            <Grid container alignItems="center" gap={2} justifyContent="center">
              <Grid item>
                {state.depositStatus ? (
                  <CheckCircle fontSize="large" />
                ) : (
                  <HighlightOff fontSize="large" />
                )}
              </Grid>
              <Grid item>
                <Typography>
                  {state.depositStatus
                    ? "Deposit completed"
                    : "Deposit rejected"}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
              gap={2}
            >
              <Grid item>
                <TextLoader text="Waiting for deposit" />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => window.open(state.currentRedirectUrl)}
                >
                  Pay now
                </Button>
              </Grid>
            </Grid>
          )}
        </ContentWrapper>
      </Modal>
    </Fragment>
  );
};

export default ArxGcashGateway;
