import { Rocket } from "@mui/icons-material";
import { Grid, Typography, styled } from "@mui/material";

import chartIcon from "../../../../Assets/icons/chart.png";

import Balance from "../../../Common/Balance";
import GradientTypography from "../../../Common/GradientTypography";
import { customTheme } from "../../../../Utilities/theme";

import { Icon } from "./styles";

type StatisticsProps = {
  data: {
    bets: number;
    biggest_win: number;
    profit: number;
    deposit: number;
    withdrawals: number;
    wager: number;
  };
};

const ProfileStatistics = ({ data }: StatisticsProps) => {
  const content = [
    { name: "total wager", value: data.wager },
    { name: "total bets", value: data.bets, notMoney: true },
    { name: "total deposit", value: data.deposit },
    { name: "total withdrawals", value: data.withdrawals },
    { name: "biggest win", value: data.biggest_win },
  ];

  return (
    <Grid container spacing={2}>
      <Grid container alignItems="center" item>
        <Icon src={chartIcon} />
        <GradientTypography fontWeight="bold">Statistics</GradientTypography>
      </Grid>

      {/* {content.map(renderCardSet)} */}
      {content.map((data, index) => (
        <Grid
          key={index}
          xs={12}
          sm={content.length - 1 === index ? 12 : 6}
          item
        >
          <StatItemContainer>
            <Grid container direction="column" alignItems="center">
              <Grid style={{ color: customTheme.basicBorderColor }} item>
                {data.notMoney ? (
                  <Typography>{data.value}</Typography>
                ) : (
                  <Balance value={data.value} normal />
                )}
              </Grid>
              <Grid item>
                <CardLabel>{data.name}</CardLabel>
              </Grid>
            </Grid>
          </StatItemContainer>
        </Grid>
      ))}
    </Grid>
  );
};

const StatItemContainer = styled("div")(({ theme }) => ({
  background: customTheme.modalCardBg,
  borderRadius: "8px",
  border: `1px solid ${customTheme.basicBorderColor}`,
  padding: theme.spacing(1),
}));

const CardLabel = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
  fontSize: "12px",
  color: "#D9D9D980",
  [theme.breakpoints.down("sm")]: {
    fontSize: "8px",
  },
}));

export default ProfileStatistics;
