import { forwardRef, memo, useImperativeHandle } from "react";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { Wheel } from "react-custom-roulette";
import lodashIsEqual from "lodash/isEqual";
import lodashPick from "lodash/pick";

import wofModelImage from "../../../Assets/Images/wof-model.png";
import GradientTypography from "../../Common/GradientTypography";
import { commafyNumber } from "../../../Helpers";
import useObjectState from "../../../Hooks/useObjectState";
import useUtilityAction from "../../../Redux/utility/useAction";
import { useConfigSelector } from "../../../Redux/utility/useSelector";
import gameApi from "../../../Service/api/game";
import socket from "../../../Utilities/Websocket";

import {
  BetContainer,
  ModelContainer,
  Placeholder,
  Overlay,
  Wrapper,
} from "./Constants";

type LocalProps = {
  selected: {
    multiplier: number;
    bet: number;
  };
  data: Array<any>;
  onDone: () => void;
};

let stopTimeout: NodeJS.Timer;
let savedSelected: any;
const initialState = {
  resultIndex: 0,
  isSpinned: false,
  isSpinning: false,
  isWin: false,
  isLoading: false,
};
const WheelOfFortune = forwardRef(
  ({ data, selected, onDone }: LocalProps, ref) => {
    const { toggleSnackBar } = useUtilityAction();
    const config = useConfigSelector();

    const [
      { resultIndex, isSpinned, isSpinning, isWin, isLoading },
      updateState,
    ] = useObjectState(initialState);

    const _onStartSpin = async (betDetails: any) => {
      updateState({ isWin: false, isSpinned: false });
      if (!isSpinning) {
        updateState({ isLoading: true });
        const { ok, data }: any = await gameApi.wheelOfFortune(betDetails);
        const state = {} as any;
        if (ok) {
          state.resultIndex = data.data;
          state.isSpinning = true;
          state.isSpinned = true;
        } else {
          toggleSnackBar("error", data.message);
          onDone();
        }
        state.isLoading = false;
        updateState(state);
      }
    };

    const _onStopSpinning = () => {
      clearTimeout(stopTimeout);
      stopTimeout = setTimeout(() => {
        const state = {} as any;
        // eslint-disable-next-line
        if (selected.multiplier == data[resultIndex].option) {
          // save selected because when onDone is called selected will be reset
          savedSelected = selected; // we will use it only for showing the win amount
          state.isWin = true;
        } else {
          state.isWin = false;
        }
        state.isSpinning = false;
        updateState(state);
        socket.emit(config.webSocketEvents.UPDATE_USER_BALANCE);
        onDone();
      }, 500);
    };

    const _getPlaceHolder = () => {
      if (isLoading) {
        return <CircularProgress size="3rem" color="inherit" />;
      } else if (isSpinning) {
        return <Text>Spinning...</Text>;
      } else if (isWin) {
        return (
          <Box textAlign="center">
            <Text fontWeight={700}>You WIN</Text>
            <Typography>
              P
              {commafyNumber(
                (savedSelected?.bet || 0) * (savedSelected?.multiplier || 0),
                true
              )}
            </Typography>
          </Box>
        );
      } else if (isSpinned && !isWin) {
        const winValue = data[resultIndex].option;
        const winColor = data[resultIndex].style.backgroundColor;
        return (
          <Box textAlign="center">
            <Text fontWeight={700}>Landed on</Text>
            <Typography color={winColor}>
              {
                config.wof.colors[winValue as keyof typeof config.wof.colors]
                  .name
              }
            </Typography>
          </Box>
        );
      }
      return <Text>Place your bet</Text>;
    };

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
      spinStart: _onStartSpin,
    }));

    return (
      <StyledWrapper>
        <ModelContainer>
          <img alt="wof-model" src={wofModelImage} />
        </ModelContainer>

        <Placeholder>{_getPlaceHolder()}</Placeholder>

        <StyledBetContainer>
          <Overlay style={{ transform: "rotate(45deg)", top: 0, zIndex: 6 }} />
          <Wheel
            data={data}
            innerBorderWidth={1}
            innerRadius={90}
            mustStartSpinning={isSpinning}
            onStopSpinning={_onStopSpinning}
            outerBorderWidth={1}
            prizeNumber={resultIndex}
            radiusLineWidth={1}
            pointerProps={{ style: { display: "none" } }}
          />
        </StyledBetContainer>
      </StyledWrapper>
    );
  }
);

const StyledWrapper = styled(Wrapper)(({ theme }) => ({
  padding: "40px 0",
  marginLeft: 140,
  [theme.breakpoints.down("md")]: {
    padding: 0,
    marginLeft: 0,
  },
}));

const StyledBetContainer = styled(BetContainer)(({ theme }) => ({
  padding: 50,
  marginLeft: -50,
  marginRight: -50,
  // [theme.breakpoints.down("md")]: {
  //   padding: 55,
  // },
  [theme.breakpoints.down(540)]: {
    padding: 50,
  },
  [theme.breakpoints.down(480)]: {
    padding: 45,
  },
  [theme.breakpoints.down(420)]: {
    padding: 40,
  },
  [theme.breakpoints.down(350)]: {
    padding: 35,
  },
  [theme.breakpoints.down(300)]: {
    padding: 35,
  },
  [theme.breakpoints.down(280)]: {
    padding: 25,
  },
}));

const Text = styled(GradientTypography)(({ theme }) => ({
  fontSize: 20,
  textAlign: "center",
}));

const arePropsEqual = (prevProps: any, nextProps: any) => {
  const props1 = lodashPick(prevProps, ["data", "selected"]);
  const props2 = lodashPick(nextProps, ["data", "selected"]);
  return lodashIsEqual(props1, props2);
};

export type WOFRef = {
  spinStart: (args: any) => void;
};

export default memo(WheelOfFortune, arePropsEqual);
