import { Grid, styled } from "@mui/material";
import { inhouseGames } from "../../Components/InHouseGames/Constants";
import {
  HoverPlay,
  StyleIconButton,
} from "../../Components/InHouseGames/Games";
import { GameImage } from "../../Components/TopGames";
import GradientTypography from "../../Components/Common/GradientTypography";
import LiveBetsBar from "../../Components/LiveBets";
import { customTheme } from "../../Utilities/theme";
import { LiveBetsBarWrapper } from "./helpers";

const LiveGamesSection = () => {
  const liveGames = [...inhouseGames];

  return (
    <MainFlexContainer>
      <Grid container alignItems="center" justifyContent="center" gap={1}>
        {liveGames.splice(1, 2).map((game, index) => {
          return (
            <Grid
              style={{ display: "flex", justifyContent: "center" }}
              sm={4}
              item
            >
              <ImageBorder>
                <StyleIconButton>
                  <BlurredGameImage
                    className="game-image"
                    src={game?.image}
                    alt={`game-${index}`}
                  />
                  <HoverPlay
                    // onClick={() => navigate(game?.route)}
                    className="play"
                  >
                    <GradientTypography fontSize="1.5rem" fontWeight="bold">
                      Coming Soon
                    </GradientTypography>
                  </HoverPlay>
                </StyleIconButton>
              </ImageBorder>
            </Grid>
          );
        })}
      </Grid>
      <LiveBetsBarWrapper>
        <LiveBetsBar />
      </LiveBetsBarWrapper>
    </MainFlexContainer>
  );
};

export default LiveGamesSection;

const BlurredGameImage = styled(GameImage)(() => ({
  filter: "blur(10px)",
  "-webkit-filter": "blur(10px)",
}));
const ImageBorder = styled("div")(() => ({
  border: "1px solid " + customTheme.basicBorderColor,
  borderRadius: "15px",
  transition: "opacity 0.5s linear",
  ":hover": {
    border: "none",
  },
}));
const MainFlexContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "40px",
  [theme.breakpoints.down("md")]: {
    marginTop: "170px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "-50px",
  },
}));
