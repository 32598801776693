import arrowRightCircleIcon from "../../../Assets/icons/arrow-circle-right.png";

import IconButton from "../../Common/IconButton";

const NextToggle = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <IconButton disabled={disabled} onClick={onClick}>
      <img
        src={arrowRightCircleIcon}
        style={{ opacity: disabled ? 0.5 : 1, width: 50, height: 50 }}
      />
    </IconButton>
  );
};

export default NextToggle;
