import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import FooterDetails from "./FooterDetails";
import { customTheme } from "../../Utilities/theme";

const Container = styled("main")(({ theme }) => ({
  flexGrow: 1,
  maxHeight: "max-content",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(5),
  borderTop: "2px solid" + customTheme.basicBorderColor,
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(10),
  },
}));

const ContentContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const Footer = ({ open }: { open: boolean }) => {
  return (
    <Container>
      <ContentContainer>
        <FooterDetails />
      </ContentContainer>
    </Container>
  );
};

export default Footer;
