import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../Components/Common/Button";
import GradientTypography from "../../Components/Common/GradientTypography";
import TextField from "../../Components/Common/TextField";
import { getCategoryIcon } from "../../Components/InHouseGames/utils";
import { CASINO_CATEGORIES } from "../../Constants";
import { customTheme } from "../../Utilities/theme";
import { useConfigSelector } from "../../Redux/utility/useSelector";

interface Skin {
  skin: string;
  name: string;
}

interface Provider {
  row_num: number;
  key: string;
  name: string;
  category: string;
  skin: [Skin];
}

interface LocalProps {
  setActiveContent: (content: string) => void;
  setActiveCategory: React.Dispatch<React.SetStateAction<CASINO_CATEGORIES>>;
  activeContent: string;
  activeCategory: string;
  handleGameSearch: (e: any) => void;
  search: string;
}

const SearchBar = ({ handleGameSearch, search, fullWidth }: any) => {
  return (
    <TextField
      value={search}
      onChange={handleGameSearch}
      placeholder="Search for Games"
      LeftIcon={Search}
      leftIconColor="#2a3546"
      size="small"
      fullWidth={fullWidth}
    />
  );
};

const ContentNavigator = ({
  setActiveContent,
  activeContent,
  setActiveCategory,
  activeCategory,
  handleGameSearch,
  search,
}: LocalProps) => {
  const { mixedProviders } = useConfigSelector();
  const { providers } = useSelector(({ casino }) => casino);

  const ref = useRef<any>(null);

  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0);
  const [sliderConstraints, setSliderConstraints] = useState(0);

  useEffect(() => {
    const refLength = ref.current.childNodes.length;
    const totalWidth = Array.from(ref.current.childNodes).reduce(
      (acc, node: any) => acc + node.clientWidth,
      0
    ) as number;

    const sliderChildrenWidth = totalWidth + refLength * 16;

    const calcSliderChildrenWidth = () => {
      setSliderChildrenWidth(sliderChildrenWidth);
    };

    calcSliderChildrenWidth();

    const calcSliderWidth = () => {
      setSliderWidth(ref.current.clientWidth);
    };

    calcSliderWidth();

    window.addEventListener("resize", calcSliderWidth);

    const calcSliderConstraints = () => {
      setSliderConstraints(
        mixedProviders ? sliderChildrenWidth - sliderWidth + 200 : 0
      );
    };

    calcSliderConstraints();

    window.addEventListener("resize", calcSliderConstraints);

    // if sliderChildrenWidth > sliderWidth enable drag and remove justify between
  }, [
    providers,
    sliderWidth,
    sliderChildrenWidth,
    sliderConstraints,
    mixedProviders,
  ]);

  let providerList = [];

  if (providers) {
    let nonMirroredVendors = providers
      .filter(({ mirrored }: any) => mirrored === false)
      .map(({ key }: any) => key);

    providerList = providers.filter(
      ({ enabled, category, mirrored, key }: any) =>
        mixedProviders
          ? (enabled &&
              activeCategory.toLowerCase().includes(category) &&
              !nonMirroredVendors.includes(key) &&
              mirrored === mixedProviders) ||
            (activeCategory.toLowerCase().includes(category) && !mirrored)
          : enabled &&
            activeCategory.toLowerCase().includes(category) &&
            mirrored === false
    );
  }

  return (
    <>
      <Container className="space-y-4">
        <Box className="flex">
          {Object.values(CASINO_CATEGORIES).map(
            (category: CASINO_CATEGORIES, index) => (
              <Button
                key={index}
                filled={category === activeCategory}
                variant="contained"
                color="primary"
                size="large"
                startIcon={getCategoryIcon(
                  category,
                  category === activeCategory
                )}
                defaultOutlineBorder={!(category === activeCategory)}
                onClick={() => setActiveCategory(category)}
                style={{ marginRight: "10px" }}
              >
                {category}
              </Button>
            )
          )}
        </Box>
        <motion.div className="providers-slider-wrapper">
          <motion.div
            className="providers-slider"
            ref={ref}
            drag="x"
            dragConstraints={{
              left: -sliderConstraints,
              right: 0,
            }}
            style={{
              justifyContent: sliderConstraints > 0 ? "space-between" : "left",
            }}
          >
            {providerList.map((item: any, i: number) => {
              return (
                <motion.div key={i} onClick={() => setActiveContent(item)}>
                  <img
                    className="max-w-none"
                    alt={item.key.replace(/_(.*?)/, "-")}
                    src={require(`../../Assets/providers/${item.key}.png`)}
                  />
                </motion.div>
              );
            })}
          </motion.div>
        </motion.div>
        <Box sx={{ width: "200px", marginLeft: "auto" }}>
          <SearchBar {...{ handleGameSearch }} {...{ search }} />
        </Box>
      </Container>
    </>
  );
};

export default ContentNavigator;

const Container = styled("div")(({ theme }: any) => ({
  background: customTheme.appbarBackground,
  maxWidth: theme.breakpoints.values.xl,
  margin: "auto",
  padding: "10px 20px",
}));
