import client from "./client";
import { GAME } from "./endpoints";

// Types
type PlaceBet = {
  bet_amount: Number;
  game: String;
};

type WheelOfFortuneBet = {
  bet: number;
  multiplier: number;
};

type MinesPlaceBet = {
  bet_amount: number;
  bombs: number;
};

type TilesResult = {
  bombs: number;
  column: number;
  data: any[][];
  row: number;
  wins: number;
};

type BoardResult = {
  bombs: number;
  data: Array<Array<any>>;
};

type CrashPoint = {
  bet_amount: number;
  target: number;
};

type HeadsOrTails = {
  bet_amount: number;
  betted_on: number; // 0 = Heads or 1 = Tails
};

function getCrashpoint(payload: CrashPoint) {
  return client.post(GAME.crashPoint, payload);
}
function getHeadsOrTails(payload: HeadsOrTails) {
  return client.post(GAME.headsOrTails, payload);
}

// START Mines
function getMinesSession() {
  return client.get(GAME.minesSession);
}
function getTileResult(payload: TilesResult) {
  return client.post(GAME.tileResult, payload);
}
function minesPlaceBet(payload: MinesPlaceBet) {
  return client.post(GAME.minesPlaceBet, payload);
}
function showTiles(payload: BoardResult) {
  return client.post(GAME.tileShowAll, payload);
}
// END Mines

// START Wheel of Fortune
function wheelOfFortune(data: WheelOfFortuneBet) {
  const { bet: bet_amount, ...rest } = data;
  return client.post(GAME.wheelOfFortune, {
    bet_amount,
    ...rest,
  });
}
// END Wheel of Fortune

function placeBet(payload: PlaceBet) {
  return client.post(GAME.placeBet, payload);
}

function openJiliGame(gameId: number | string) {
  return client.get(GAME.jiliGame, { gameId });
}

function openFaChaiGame(gameId: number | string) {
  return client.get(GAME.faChaiGame, { gameId });
}

function openPragmaticGame(gameId: string, category: string) {
  return client.get(GAME.pragmaticGame, { gameId, category });
}

function openNexusGame(vendor: string, game: string) {
  return client.get(GAME.nexusGame, { vendor, game });
}

const actions = {
  getCrashpoint,
  getHeadsOrTails,
  getMinesSession,
  getTileResult,
  minesPlaceBet,
  showTiles,
  wheelOfFortune,
  placeBet,
  openJiliGame,
  openFaChaiGame,
  openPragmaticGame,
  openNexusGame,
};

export default actions;
