import { useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

import bombImage from "../../../Assets/Images/mines-bomb.png";
import diamondImage from "../../../Assets/Images/mines-diamond.png";
import diamondUnselectImage from "../../../Assets/Images/mines-diamond-unselect.png";
import tilesBackgroundImage from "../../../Assets/Images/mines-tiles-background.png";
import { useConfigSelector } from "../../../Redux/utility/useSelector";

import {
  Wrapper,
  IndicatorMobileContainer,
  IndicatorWebContainer,
  IndicatorImageWebContainer,
  IndicatorWebImage,
  TileContainer,
  TileImage,
  Tile,
  MultiplierWrapper,
  MultiplierContainer,
  MultiplierContentContainer,
  MultiplierItemContainer,
} from "./GameStyles";

type CommonProps = {
  data: any[][];
  disabled?: boolean;
  onClick?: (column: number, row: number) => void;
};

type Props = {
  bombs: number;
  wins: number;
} & CommonProps;

type TilesProps = {
  data: any[][];
  legends: any;
  view?: boolean;
} & CommonProps;

const _renderTiles = ({
  data,
  disabled,
  legends,
  view,
  onClick,
}: TilesProps) => (
  <TileContainer>
    {!view && <TileImage src={tilesBackgroundImage} />}
    {data?.map((columns, columnIndex) => (
      <Box
        display="flex"
        justifyContent={view ? "center" : "flex-start"}
        key={columnIndex}
      >
        {columns.map((rows, rowIndex) => (
          <Tile
            key={rowIndex}
            disabled={disabled}
            value={rows}
            legends={legends}
            onClick={() => onClick?.(columnIndex, rowIndex)}
          />
        ))}
      </Box>
    ))}
  </TileContainer>
);

function MinesGame({ bombs, data, disabled, wins, onClick }: Props) {
  const config = useConfigSelector();
  const multiplierRef = useRef<HTMLDivElement>(null);

  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);

  // prettier-ignore
  const multipliers = config.mines.multipliers;
  const multiplier = multipliers[bombs as keyof typeof multipliers]; // multipliers from backend config
  const totalTilesCount = data?.length * data?.[0]?.length; // column * row
  const diamondTilesCount = totalTilesCount - bombs;
  const bombTilesCount = totalTilesCount - diamondTilesCount;

  // this hooks is for checking the scroll of multiplier when bomb change
  useEffect(() => {
    const observer = new MutationObserver(_onMultiplierScroll);
    if (multiplierRef.current) {
      observer.observe(multiplierRef.current, {
        childList: true,
        subtree: true,
      });
    }
    return () => {
      observer.disconnect();
    };
  }, []); // no need to add bomb here as depencency

  // this hooks is for automatically scroll the active multiplier base on wins
  useEffect(() => {
    const div = multiplierRef.current;
    const item = document.getElementById(`mp-${wins}`);
    if (item) {
      div!.scrollLeft = item.offsetLeft - item.offsetWidth;
    }
  }, [wins]);

  // behaviour
  const _onMultiplierScroll = () => {
    if (multiplierRef.current) {
      const scrollLeft = multiplierRef.current.scrollLeft;
      const maxScrollLeft =
        multiplierRef.current.scrollWidth - multiplierRef.current.clientWidth;
      setDisablePrev(scrollLeft <= 0);
      setDisableNext(scrollLeft >= maxScrollLeft);
    }
  };

  const _onMultiplierMove = (isPrev: boolean) => {
    if (isPrev) {
      multiplierRef.current!.scrollLeft -= multiplierRef.current!.offsetWidth;
    } else {
      multiplierRef.current!.scrollLeft += multiplierRef.current!.offsetWidth;
    }
  };

  // renders
  const _renderWebIndicator = (
    count: number,
    image: string,
    glow: boolean = false
  ) => (
    <IndicatorWebContainer>
      <IndicatorImageWebContainer>
        <IndicatorWebImage alt="indicator" glow={glow} src={image} />
      </IndicatorImageWebContainer>
      <Typography variant="h6" fontWeight={700} color="black">
        {count}
      </Typography>
    </IndicatorWebContainer>
  );

  const _renderMobileIndicator = () => (
    <IndicatorMobileContainer>
      <Box display="flex" alignItems="center">
        <img
          alt="diamond-mobile-indicator"
          src={diamondImage}
          width={31}
          height="auto"
        />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {diamondTilesCount}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography variant="h6" fontWeight={700} mr={1}>
          {bombTilesCount}
        </Typography>
        <img
          alt="bomb-mobile-indicator"
          src={bombImage}
          width={31}
          height="auto"
        />
      </Box>
    </IndicatorMobileContainer>
  );

  const _renderMultiplier = (data: number, index: number) => (
    <MultiplierItemContainer
      isActive={wins - 1 === index}
      isLast={index === multiplier.length - 1}
      key={index}
      id={`mp-${index}`}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="caption" mr={0.5}>
          {index + 1}
        </Typography>
        <img
          alt="multiplier-diamond"
          width={12}
          height={10}
          src={diamondUnselectImage}
        />
      </Box>
      <Typography variant="subtitle2" fontWeight={700}>
        {data}x
      </Typography>
    </MultiplierItemContainer>
  );

  return (
    <Wrapper>
      {_renderMobileIndicator()}

      {/* Multiplier container */}
      <MultiplierWrapper>
        {/* scroll left button */}
        <Box mr={1}>
          <IconButton
            onClick={() => _onMultiplierMove(true)}
            disabled={disablePrev}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Box>

        {/* multiplier list */}
        <MultiplierContainer>
          <MultiplierContentContainer
            className="no-scroll"
            onScroll={_onMultiplierScroll} // check scrolling onload
            onLoad={_onMultiplierScroll} // check scrolling onload
            ref={multiplierRef}
          >
            {multiplier.map(_renderMultiplier)}
          </MultiplierContentContainer>
        </MultiplierContainer>

        {/* scroll right button */}
        <Box ml={1}>
          <IconButton
            onClick={() => _onMultiplierMove(false)}
            disabled={disableNext}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </MultiplierWrapper>

      {/* Tiles */}
      <Box display="flex" alignItems="center">
        {_renderWebIndicator(diamondTilesCount, diamondImage, true)}

        {_renderTiles({
          data,
          disabled,
          legends: config.mines.legends,
          onClick,
        })}

        {_renderWebIndicator(bombTilesCount, bombImage)}
      </Box>
    </Wrapper>
  );
}

export const renderTiles = _renderTiles;
export default MinesGame;
