import { CircularProgress, Grid, styled } from "@mui/material";

import Logo from "../Assets/Logos/logo.png";

function Loader() {
  return (
    <Container
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
      container
    >
      <Grid item>
        <Image src={Logo} />
      </Grid>
      <Grid item>
        <CircularProgress size={60} color="inherit" />
      </Grid>
    </Container>
  );
}

const Container = styled(Grid)({
  height: "100vh",
  paddingLeft: "10vw",
  paddingRight: "10vw",
});

const Image = styled("img")(({ theme }) => ({
  height: 80,
  width: "auto",
  maxWidth: "100%",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    width: "100%",
  },
}));

export default Loader;
