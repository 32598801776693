import { COLUMNS } from "../../../Constants/game";

const breakpoint = 755;

const columns = [
  COLUMNS.result,
  COLUMNS.payout,
  COLUMNS.amount,
  { name: "target", label: "Target" },
  COLUMNS.multiplier,
  { name: "bet_id", label: "Bet ID" },
];

export { breakpoint, columns };
