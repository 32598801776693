import { useState } from "react";
import lodashIsUndefined from "lodash/isUndefined";

import { CURRENCY_SYMBOL } from "../Constants";
import { commafyNumber } from "../Helpers";
import useUtilityAction from "../Redux/utility/useAction";
import { useConfigSelector } from "../Redux/utility/useSelector";
import { useIsLoginSelector } from "../Redux/user/useSelector";

type AdditionalCondition = {
  condition: boolean; // the condition
  message: string; // error message if condition is met
}[];
const useBetController = (): any => {
  const { toggleSnackBar } = useUtilityAction();
  const config = useConfigSelector();
  const isLogin = useIsLoginSelector();

  const [bet, setBet] = useState<number>();

  const _onBetChange = setBet;

  const _validate = (
    additionalCondition: AdditionalCondition = []
  ): boolean => {
    if (!isLogin) {
      toggleSnackBar("error", "Please login first.");
      return false;
    } else if (lodashIsUndefined(bet)) {
      toggleSnackBar("error", "Please place your bet on the input");
      return false;
    } else if (Number(bet) < config.minBet) {
      toggleSnackBar(
        "error",
        `Minimum bet is ${CURRENCY_SYMBOL}${commafyNumber(config.minBet, true)}`
      );
      return false;
    } else if (Number(bet) > config.maxBet) {
      toggleSnackBar(
        "error",
        `Maximum bet is ${CURRENCY_SYMBOL}${commafyNumber(config.maxBet, true)}`
      );
      return false;
    }
    // extendable condition for other game that has special condition aside from above
    for (let i = 0; i < additionalCondition.length; i++) {
      const data = additionalCondition[i];
      if (data.condition) {
        toggleSnackBar("error", data.message);
        return false;
      }
    }
    return true; // return true if all condition is unmet
  };

  const state = {
    bet,
  };

  const handlers = {
    onBetChange: _onBetChange,
    validate: _validate,
  };

  return [state, handlers];
};

export default useBetController;
