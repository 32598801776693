import { Fragment, useEffect, useState } from "react";

import { renderDepositProvider } from "./Helpers";
import TransactionModalHeaderNavigator from "./Components/TransactionModalHeaderNavigator";
import { useConfigSelector } from "../../../Redux/utility/useSelector";
import { Box } from "@mui/material";

const Deposit = ({ onProviderSelect }: { onProviderSelect: Function }) => {
  const config = useConfigSelector();
  const [isProviderSelected, setIsProviderSelected] = useState<string>("");

  const Container = isProviderSelected ? Box : Fragment;

  useEffect(() => {
    onProviderSelect(isProviderSelected);
  }, [isProviderSelected]);

  return (
    <Container>
      <TransactionModalHeaderNavigator
        isProviderSelected={isProviderSelected}
        setProvider={setIsProviderSelected}
      />
      {renderDepositProvider(
        isProviderSelected,
        setIsProviderSelected,
        config.depositGcashMaintenance,
        config.depositPaymayaMaintenance
      )}
    </Container>
  );
};

export default Deposit;
