import { createSlice } from "@reduxjs/toolkit";

import { SIGNIN_FORM } from "./constants";

export const noSessionSlice = createSlice({
  name: "noSession",
  initialState: {
    loginInformation: undefined,
  },
  reducers: {
    rememberLogin: (state, action) => {
      state.loginInformation = action.payload;
    },
  },
});

export interface State {
  noSession: {
    loginInformation: SIGNIN_FORM;
  };
}

export const { rememberLogin } = noSessionSlice.actions;

export default noSessionSlice.reducer;
