import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { styled, keyframes } from "@mui/material";

import CoinFlipHeads from "../../../Assets/Images/coinflip_heads.png";
import CoinFlipTails from "../../../Assets/Images/coinflip_tails.png";
import { CURRENCY_SYMBOL } from "../../../Constants";
import { commafyNumber, handleApiError } from "../../../Helpers";
import useUtilityAction from "../../../Redux/utility/useAction";
import adminApi from "../../../Service/api/game";

type Props = {
  onDone: () => void;
  onError: () => void;
  onRequestDone: () => void;
};
type FlipData = {
  bet_amount: number;
  betted_on: number;
  landed_on?: number;
};
let timeout: NodeJS.Timer;
const animationDuration = 4; // seconds
const HeadsOrTailsGame = forwardRef(
  ({ onDone, onError, onRequestDone }: Props, ref) => {
    const isNotFirstLoad = useRef<boolean>();
    const { toggleSnackBar } = useUtilityAction();

    const [data, setData] = useState<FlipData>();
    const [cName, setCName] = useState(" "); // initial space, intentional

    useEffect(() => {
      if (isNotFirstLoad.current && cName) {
        // if has cName, means its now spinning
        clearTimeout(timeout);
        // wait after the animation
        timeout = setTimeout(() => {
          // show snackbar if win
          if (data?.betted_on === data?.landed_on) {
            toggleSnackBar(
              "success",
              `Congratulation you won ${CURRENCY_SYMBOL}${commafyNumber(
                (data?.bet_amount || 0) * 2,
                true
              )}`
            );
          }
          // then call onDone
          onDone();
        }, (animationDuration + 0.5) * 1000);
      } else if (isNotFirstLoad.current) {
        // when Cname is removed it means when button is pressed, and also not onload
        if (data?.landed_on === 0) {
          setCName("heads");
        } else {
          setCName("tails");
        }
      }
      // eslint-disable-next-line
    }, [cName]);

    useEffect(() => {
      isNotFirstLoad.current = true;
    }, []);

    const _flip = async (payload: FlipData) => {
      const { ok, data }: any = await adminApi.getHeadsOrTails(payload);
      onRequestDone();
      if (ok) {
        setData({ ...payload, landed_on: data.result });
        setCName("");
      } else {
        toggleSnackBar("error", handleApiError(data));
        onError();
      }
    };

    useImperativeHandle(ref, () => ({
      flip: _flip,
    }));

    return (
      <Wrapper className={cName}>
        <Front src={CoinFlipHeads} />
        <Back src={CoinFlipTails} />
      </Wrapper>
    );
  }
);

const flipHeads = keyframes`
  from { transform: rotateY(0); }
  to { transform: rotateY(1800deg); }
}`;

const flipTails = keyframes`
  from { transform: rotateY(0); }
  to { transform: rotateY(1980deg); }
}`;

const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
  margin: "0 auto",
  width: "50%",
  aspectRatio: "1/1",
  transition: "-webkit-transform 1s ease-in",
  WebkitTransformStyle: "preserve-3d",
  img: {
    position: "absolute",
    height: "100%",
    aspectRatio: "1/1",
    WebkitBackfaceVisibility: "hidden",
  },
  "&.heads": {
    animation: `${flipHeads} ${animationDuration}s ease-out forwards`,
  },
  "&.tails": {
    animation: `${flipTails} ${animationDuration}s ease-out forwards`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));
const Front = styled("img")({
  zIndex: 100,
});
const Back = styled("img")({
  WebkitTransform: "rotateY(-180deg)",
});

export type HeadsOrTailsRef = {
  flip: (data: FlipData) => void;
};

export default HeadsOrTailsGame;
