import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled, Grid, Typography, Box } from "@mui/material";
import themeStyles from "../../Utilities/styles";
import earnMoneyBG from "../../Assets/Images/earn-money.png";
import vipBonusBG from "../../Assets/Images/vip-bonus.png";
import freeSpinBG from "../../Assets/Images/free-spin.png";

import { ENABLE_INHOUSE_GAMES } from "../../Constants";
import routes from "../../Routes/Constants";

import Button from "../Common/Button";

import { quickAccessGames } from "./Constants";
import GradientTypography from "../Common/GradientTypography";
import { customTheme } from "../../Utilities/theme";
import useUserSelector from "../../Redux/user/useSelector";
import { LOGIN_SIGNUP_FORMS } from "../../Redux/utility/constants";
import useUtilityAction from "../../Redux/utility/useAction";
import FreeSpin from "../Navigation/Profile/FreeSpin";

const MainGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const ContentGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  position: "relative",
  borderRadius: "8px",
  background: "#535353",
  height: "max-content",
}));

// const Label = styled(Typography)({
//   position: "absolute",
//   cursor: "pointer",
//   maxWidth: 100,
//   fontWeight: 700,
// });

const StyledImage = styled("img")(() => ({
  borderRadius: "8px",
  cursor: "pointer",
  width: "100%",
  aspectRatio: "1/0.315",
}));

const GameQuickAccess = ({
  maintenanceMapper,
  sideImages,
  onClick,
}: {
  maintenanceMapper: any;
  sideImages: any;
  onClick: () => void;
}) => {
  const { setLoginSignupForm } = useUtilityAction();
  const { isLogin } = useUserSelector();

  const [freeSpinOpen, setFreeSpinOpen] = useState(false)

  const navigate = useNavigate();

  const _onClick = (data: any) => {
    if (!isLogin && data.route === "affiliates") {
      return setLoginSignupForm(LOGIN_SIGNUP_FORMS.SIGNIN);
    }
    if (data.route) {
      navigate(data.route);
      onClick();
    }
  };

  const _renderHigh = ({
    leftImage,
    route,
    title,
    subTitle,
    background,
  }: any) => (
    <ContentGrid
      container
      alignItems="center"
      style={{
        cursor: "pointer",
        overflow: "hidden",
        height: "65px",
      }}
      onClick={() => _onClick({ route })}
    >
      {!!leftImage && (
        <Grid item display="flex" alignItems="center" mr={1}>
          <img alt="refereal-left" src={leftImage} height="auto" width="30" />
        </Grid>
      )}

      <Grid container item xs={true} direction="column" zIndex={1}>
        <Typography
          display="block"
          lineHeight={1}
          variant="caption"
          fontWeight={700}
        >
          {title}
        </Typography>
        <Typography lineHeight={1} variant="caption">
          {subTitle}
        </Typography>
      </Grid>

      <Grid item position="absolute" top={0} bottom={0} right={0} left={0}>
        <img
          alt="referral-background"
          src={background}
          width="100%"
          height="100%"
          style={{
            objectFit: "fill",
          }}
        />
      </Grid>
    </ContentGrid>
  );

  return (
    <MainGridContainer gap={1} container direction="column" alignItems="center">
      {_renderHigh({
        leftImage: null,
        title: "Refferal",
        subTitle: "Earn Money",
        background: earnMoneyBG,
        route: routes.AFFILIATE,
      })}

      <Grid container spacing={1}>
        <Grid xs={6} item>
          {_renderHigh({
            title: "VIP",
            subTitle: "Bonus",
            background: vipBonusBG,
            route: routes.VIP_RANKING,
          })}
        </Grid>

        <Grid xs={6} item onClick={() => (setFreeSpinOpen(true))}>
          {_renderHigh({
            title: "Free",
            subTitle: "Spin",
            background: freeSpinBG,
          })}
        </Grid>
      </Grid>
      <FreeSpin
        open={freeSpinOpen}
        onClose={() => (setFreeSpinOpen(false))}
      />
      <ContentGrid
        justifyContent="space-around"
        alignItems="center"
        container
        direction="column"
        gap={1}
        item
        style={{
          background: customTheme.cardDarkBg,
          border: "0.5px solid" + customTheme.basicBorderColor,
        }}
      >
        {ENABLE_INHOUSE_GAMES && (
          <Fragment>
            <Grid item>
              <GradientTypography
                style={{ fontWeight: "bold", fontSize: "14px" }}
              >
                Home Games
              </GradientTypography>
            </Grid>
            {quickAccessGames.map((game, index) => (
              <Grid
                key={index}
                position="relative"
                container
                alignItems="center"
                onClick={() => _onClick(game)}
                overflow="hidden"
                borderRadius="8px"
                sx={{
                  pointerEvents: maintenanceMapper[game.gameCode]
                    ? "none"
                    : "all",
                }}
                item
              >
                <StyledImage
                  src={sideImages[game.gameCode] || game.image}
                  alt="quick-access-game"
                />
                {maintenanceMapper[game.gameCode] && (
                  <Box style={themeStyles.underMaintenance()}>
                    <Typography
                      color="error"
                      fontWeight="bold"
                      variant="caption"
                    >
                      Under Maintenance
                    </Typography>
                  </Box>
                )}
              </Grid>
            ))}
          </Fragment>
        )}
        <Grid style={{ width: "100%" }} item>
          <MenuButton onClick={() => _onClick({ route: routes.HOME })}>
            <GradientTypography
              style={{ fontWeight: "bold", fontSize: "13px" }}
            >
              All Games
            </GradientTypography>
          </MenuButton>
        </Grid>
      </ContentGrid>
    </MainGridContainer>
  );
};

export default GameQuickAccess;

const MenuButton = styled(Button)(() => ({
  boxShadow: "none",
  border: ".5px solid " + customTheme.basicBorderColor,
  padding: "5px",
  width: "100%",
  borderRadius: "5px",
  background: customTheme.cardDarkBg,
  ":hover": {
    background: customTheme.hoverBg,
  },
}));
