import { useSelector } from "react-redux";

import { State } from "./";

function useUserSelector() {
  const result = useSelector((state: State) => ({
    isLogin: state.user.isLogin,
    user: state.user.data,
  }));

  return result;
}

// use this if want only some specific data on utility redux
export function useIsLoginSelector() {
  return useSelector((state: State) => state.user.isLogin);
}

// use this if you need single import for all user redux
export default useUserSelector;
