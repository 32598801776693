export const CURRENCY_SYMBOL = "₱";

export const drawerWidth = 260;

export const PAGE_MAX_WIDTH = 1280;

export const ENABLE_INHOUSE_GAMES = true;

export const GAME_PROVIDERS = {
  FA_CHAI: "FA-CHAI",
  JILI: "JILI",
  PRAGMATIC: "PRAGMATIC",
};

export enum CASINO_CATEGORIES {
  Slots = "Slot",
  Casino = "Casino",
  Live_Games = "Live Games",
}
