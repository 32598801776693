import Loader from "./Loader";

export interface TextLoaderProps {
  text: string;
}
const TextLoader = ({ text }: TextLoaderProps) => {
  // Your main application logic here

  const isLoading = true; // You can set this to true when data is loading

  return (
    <div>
      {isLoading ? <Loader text={text} /> : <div>Your main content here</div>}
    </div>
  );
};

export default TextLoader;
