import Balance from "../../Components/Common/Balance";

export const columns = [
  {
    name: "username",
    label: "Username",
  },
  {
    name: "statistics",
    label: "Total Deposit",
    customBodyRender: (data: any) => (
      <Balance value={data?.deposit || 0} normal />
    ),
  },
];
