import { UrlParams, createUrlParams } from "../../Helpers";

import client from "./client";
import { GAME, USER } from "./endpoints";

type ChangePassword = {
  old_password: string;
  new_password: string;
  confirm_password: string;
};

type CreateAccount = {
  email: string;
  password: string;
  confirm_password: string;
};

type SignIn = {
  email: string;
  password: string;
};

type Withdraw = {
  gateway: string;
  account_number: number;
  account_name: string;
  amount: number;
};

type Deposit = {
  method: {
    label: string;
  };
  amount: number;
};

type DepositGcashCheck = {
  reference_number: string | number;
};

function changePassword(payload: ChangePassword) {
  return client.patch(USER.changePassword, payload);
}

function setRecoveryEmail(payload: { email: string }) {
  return client.post(USER.setRecoveryEmail, payload);
}

function recoverAccount(payload: { email: string; captcha_token: any }) {
  return client.post(USER.recoverAccount, payload);
}

function claimCommission() {
  return client.patch(USER.claimCommissions);
}

function claimRebates() {
  return client.patch(USER.claimRebates);
}

function createAccount(payload: CreateAccount) {
  return client.post(USER.register, payload);
}

function freeSpin() {
  return client.get(GAME.freeSpin);
}

function getDailyFreeSpin() {
  return client.get(USER.dailyFreeSpin);
}

function getDetails() {
  return client.get(USER.me);
}

function getGameHistories(arrParams: UrlParams) {
  const params = createUrlParams(arrParams);
  const path = `${USER.gameHistories}?${params}`;
  return client.get(path);
}

function getReferrals(arrParams: UrlParams) {
  const params = createUrlParams(arrParams);
  const path = `${USER.referrals}?${params}`;
  return client.get(path);
}

function getReferralCounts() {
  return client.get(USER.referralCounts);
}

function getTransactions(arrParams: UrlParams) {
  const params = createUrlParams(arrParams);
  const path = `${USER.transactionHistory}?${params}`;
  return client.get(path);
}

function login(payload: SignIn) {
  return client.post(USER.login, payload);
}

function logout() {
  return client.post(USER.logout);
}

function withdraw(payload: Withdraw) {
  return client.post(USER.withdraw, payload);
}

function withdrawV3(payload: Withdraw) {
  return client.post(USER.withdrawV3, payload);
}

function deposit(payload: Deposit) {
  return client.post(USER.deposit, payload);
}

function depositV3(payload: { amount: number; gateway: string }) {
  return client.post(USER.depositV3, payload);
}

function mockDeposit(payload: { amount: number; gateway: string }) {
  return client.post(USER.mockDeposit, payload);
}

function depositV3CheckActiveDepositSession() {
  return client.get(USER.depositV3CheckActiveDepositSession);
}
function claimCouponCode(payload: { code: string }) {
  return client.patch(USER.claimCoupon, payload);
}

function createDepositSession(payload: { amount: number }) {
  return client.post(USER.createDepositSession, payload);
}

function checkActiveUserDepositSession() {
  return client.get(USER.checkActiveDepositSession);
}

function readNotification() {
  return client.patch(USER.readNotification);
}

function checkDepositTransaction(payload: DepositGcashCheck) {
  return client.post(USER.checkDepositTransaction, payload);
}

const actions = {
  changePassword,
  setRecoveryEmail,
  recoverAccount,
  claimCommission,
  claimRebates,
  createAccount,
  freeSpin,
  getDailyFreeSpin,
  getDetails,
  getGameHistories,
  getReferrals,
  getReferralCounts,
  getTransactions,
  login,
  logout,
  withdraw,
  withdrawV3,
  deposit,
  claimCouponCode,
  createDepositSession,
  checkActiveUserDepositSession,
  readNotification,
  checkDepositTransaction,
  depositV3,
  depositV3CheckActiveDepositSession,
  mockDeposit,
};

export default actions;
