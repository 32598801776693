import { Alert, AlertColor, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import CryptoJS from "crypto-js";
import lodashIsEmpty from "lodash/isEmpty";
import {
  Fragment,
  MutableRefObject,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import DocumentMeta from "react-document-meta";

import Footer from "../Components/Footer";
import Navigation, { MobileTabNavigation } from "../Components/Navigation";
import SignInOrSignUp from "../Components/Navigation/SignInOrSignUp";
import { removeSession } from "../Redux/store";
import useUserAction from "../Redux/user/useAction";
import useUserSelector from "../Redux/user/useSelector";
import useUtilityAction, {
  LOGIN_SIGNUP_FORMS,
} from "../Redux/utility/useAction";
import useUtilitySelector from "../Redux/utility/useSelector";
import MainRoutes from "../Routes/Routes";
import listingApi from "../Service/api/listing";
import userApi from "../Service/api/user";
import { customTheme } from "../Utilities/theme";

import BannerAds, { BannerAdsRef } from "./BannerAds";
import Loader from "./Loader";
import MaintenanceMode from "./MaintenanceMode";
import RecoveryEmailPrompt, { RecoveryEmailRef } from "./RecoveryEmailPrompt";
import ResetPassword from "./ResetPassword";

const ContentWrapper = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  minHeight?: string;
}>(({ minHeight }) => ({
  flexGrow: 1,
  minHeight: minHeight ? minHeight : "100vh",
  maxHeight: "max-content",
  display: "flex",
  justifyContent: "center",
  paddingTop: "10vh",
  paddingBottom: "10vh",
}));

export const MainLayoutContext = createContext({});

interface SnackBarRef {
  type: AlertColor;
  message: string;
}

export function MainLayout() {
  const urlParams = new URLSearchParams(window.location.search);
  const wallet = urlParams.get("wallet");

  const snackBarRef = useRef() as MutableRefObject<SnackBarRef>;
  const bannerAdsRef = useRef<BannerAdsRef | null>(null);
  const recoveryEmailRef = useRef<RecoveryEmailRef | null>(null);
  const { setIsLogin, setUser } = useUserAction();
  const { setConfig, setLoginSignupForm, toggleSnackBar } = useUtilityAction();
  const { isLogin } = useUserSelector();
  const { activeAuthForm, config, snackBar } = useUtilitySelector();

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    _checkSession();
    //eslint-disable-next-line
  }, [isLogin]);

  useEffect(() => {
    if (!lodashIsEmpty(snackBar)) {
      snackBarRef.current = {
        type: snackBar.type,
        message: snackBar.message,
      };
    }
    //eslint-disable-next-line
  }, [snackBar]);

  const _checkSession = async () => {
    const [userDetails, config]: any = await Promise.all([
      userApi.getDetails(),
      listingApi.getConfig(),
    ]);
    // user details
    if (userDetails.ok) {
      setIsLogin(true);
      setUser(userDetails.data);
      if (!userDetails.data?.recovery_email && !wallet) {
        recoveryEmailRef.current!.show();
      }
    } else if (userDetails.status === 401) {
      setIsLogin(false);
      removeSession();
    }
    // ranking
    if (config.ok) {
      const bytes = CryptoJS.AES.decrypt(
        config.data as string,
        process.env.REACT_APP_JWT_SECRET as string
      );
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (!lodashIsEmpty(data.banner_ads)) {
        if (data.banner_ads.link && data.banner_ads.image) {
          bannerAdsRef.current!.show(data.banner_ads);
        }
      }

      setConfig(data as object);
    } else {
      toggleSnackBar("error", "Failed to get config. Refreshing...");
      window.location.reload();
    }
  };

  const _handleDrawerOpen = () => setOpen(true);

  const _handleDrawerClose = () => setOpen(false);

  const _renderMeta = () => {
    if (
      !config?.siteSettings?.site_name ||
      !config?.siteSettings?.site_slogan
    ) {
      return null;
    }
    return (
      <DocumentMeta
        title={`${config?.siteSettings?.site_name} - ${config?.siteSettings?.site_slogan}`}
        description={config?.siteSettings?.site_slogan}
      />
    );
  };

  const _getContent = () => {
    if (lodashIsEmpty(config)) {
      return <Loader />;
    } else if (config.isMaintenance) {
      return (
        <Fragment>
          {_renderMeta()}
          <MaintenanceMode customLogo={config?.siteAssets?.web_logo} />
        </Fragment>
      );
    }
    return (
      <Fragment>
        {_renderMeta()}
        <Navigation
          handleDrawerOpen={_handleDrawerOpen}
          handleDrawerClose={_handleDrawerClose}
          open={open}
        />
        <MainRoutes />
        <MobileTabNavigation
          handleDrawerOpen={_handleDrawerOpen}
          handleDrawerClose={_handleDrawerClose}
          open={open}
        />
        <Footer open={open} />
      </Fragment>
    );
  };

  return (
    <Fragment>
      {_getContent()}

      {/* reset password modal, will show if has url parameter ?reset_token=blahblah */}
      <ResetPassword />

      {/* Modals that control anywhere via redux */}
      <SignInOrSignUp
        defaultForm={activeAuthForm}
        open={!!activeAuthForm}
        onClose={() => setLoginSignupForm(LOGIN_SIGNUP_FORMS.NONE)}
      />

      <BannerAds ref={bannerAdsRef} />

      {!wallet && <RecoveryEmailPrompt ref={recoveryEmailRef} />}

      {/* Snackbar for showing alert message */}
      <Snackbar
        open={snackBar.show}
        autoHideDuration={2000}
        onClose={() => toggleSnackBar()}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          top: { xs: 80, sm: 100 },
          right: { sm: 10 },
          button: { display: "none" },
        }}
      >
        <Alert
          onClose={() => toggleSnackBar()}
          severity={snackBar.type || snackBarRef.current?.type || "success"}
          sx={{
            width: "100%",
            border: `1px solid ${customTheme.basicBorderColor}`,
            backgroundColor: customTheme.darkNavBackground,
            color: "#fff",
          }}
        >
          {snackBar.message || snackBarRef.current?.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
