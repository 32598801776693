import { Grid, styled } from "@mui/material";
import lodashIsEmpty from "lodash/isEmpty";

const StyledImg = styled("img")(() => ({
  height: "auto",
  width: "100%",
}));

const Providers = ({ data }: { data: string[] }) => {
  if (lodashIsEmpty(data)) {
    return null;
  }

  return (
    <Grid spacing={2} alignItems="center" justifyContent="center" container>
      {data.map((partner, index) => (
        <Grid key={index} xs={3} sm={3} md={1} item>
          <StyledImg src={partner} alt="provider-icon" />
        </Grid>
      ))}
    </Grid>
  );
};

export default Providers;
