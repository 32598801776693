import { createSlice } from "@reduxjs/toolkit";

export const casinoSlice = createSlice({
  name: "casino",
  initialState: {
    providers: undefined,
    games: undefined,
  },
  reducers: {
    setProviders: (state, action) => {
      state.providers = action.payload;
    },
    setGames: (state, { payload }) => {
      state.games = payload;
    },
  },
});

export interface Provider {
  category: string;
  enabled: boolean;
  image: string;
  key: string;
  mirrored: boolean;
  name: string;
  provider: string;
}

export interface Providers {
  Providers: Provider[];
}

export interface Game {
  [key: string]: {
    game_id: number;
    provider: string;
    vendor: string;
    image: string;
    category: string;
    name: string;
  };
}

export interface Games {
  Games: Game[];
}

export interface State {
  providers: Providers;
  games: Games;
}

export const { setProviders, setGames } = casinoSlice.actions;

export default casinoSlice.reducer;
