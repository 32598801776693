import { styled } from "@mui/material";

import { useSelector } from "react-redux";
import { useHomepageContextState } from "../../Pages/Home/HomepageV2";
import { LiveBetsBarWrapper } from "../../Pages/Home/helpers";
import LiveBets from "../LiveBets";
import Games from "./Games";

const InHouseGames = () => {
  const casino = useSelector(({ casino }) => casino);
  // get category
  const { states, handlers }: any = useHomepageContextState();
  console.log(states.games);

  return (
    <FlexContainer>
      <Games games={states.search ? states.searchResults : states.games} />
      <LiveBetsBarWrapper>
        <LiveBets />
      </LiveBetsBarWrapper>
    </FlexContainer>
  );
};

export default InHouseGames;

const FlexContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
