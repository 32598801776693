import { Fragment } from "react";
import { Grid, Typography, IconButton, Box } from "@mui/material";
import { CopyAll } from "@mui/icons-material";

import Balance from "../../Common/Balance";
import Button from "../../Common/Button";
import TextField from "../../Common/TextField";
import Form from "../../Form";
import Input from "../../Form/Input";
import Submit from "../../Form/Submit";
import { copyToClipboard } from "../../../Helpers";
import { useConfigSelector } from "../../../Redux/utility/useSelector";

import { fontColor, validationSchema } from "./Configs";
import useGcashV2DepositController from "./hooks/useGcashV2DepositController";

const initialValues = {
  amount: "",
};

const GcashV2 = () => {
  const config = useConfigSelector();
  const [{ step, ...state }, handlers] = useGcashV2DepositController();

  const gcashSettings = config.gcashV2;

  return (
    <Fragment>
      {step === 0 ? (
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handlers.onDeposit}
        >
          <Grid container>
            <Grid xs={12} item>
              <Input
                name="amount"
                label="Enter amount"
                variant="outlined"
                labelColor={fontColor}
                containerStyle={{ marginBottom: 10 }}
                fullWidth
              />
            </Grid>

            <Grid xs={12} item>
              <Submit variant="contained">Submit</Submit>
            </Grid>
          </Grid>
        </Form>
      ) : step === 1 ? (
        <Grid container gap={1}>
          <Grid xs={12} justifyContent="center" container item>
            <Typography align="center" mr={1}>
              Please send exactly
            </Typography>
            <Balance value={Number(state.amount)} normal />
            <Typography ml={1}>to this number or QR code.</Typography>
          </Grid>

          <Grid xs={12} justifyContent="center" container item>
            <img
              alt="gcash-qr-code"
              src={gcashSettings.qr_code}
              width={200}
              style={{
                aspectRatio: "1/1",
                maxWidth: "100%",
              }}
            />
          </Grid>

          <Grid xs={12} item>
            <Typography align="center">{gcashSettings.name}</Typography>
          </Grid>

          <Grid
            alignItems="center"
            gap={1}
            justifyContent="center"
            xs={12}
            container
            item
          >
            <Grid item>
              <Typography>{gcashSettings.number}</Typography>
            </Grid>

            <Grid item>
              <IconButton
                size="small"
                onClick={() => copyToClipboard(gcashSettings.number)}
              >
                <CopyAll style={{ width: 20, height: 20 }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid xs={12} item>
            <form onSubmit={handlers.verifyReferenceNumber}>
              <Box mb={1}>
                <TextField
                  onChange={handlers.onReferenceNumberChange}
                  value={state.referenceNumber}
                  label="Enter Reference Number"
                  labelColor="#fff"
                  fullWidth
                  required
                />
              </Box>

              <Button variant="contained" type="submit" fullWidth>
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      ) : null}
    </Fragment>
  );
};

export default GcashV2;
