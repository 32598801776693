import { Fragment } from "react";
import { Avatar, Grid, MenuItem, Typography, styled } from "@mui/material";

import useUserSelector from "../../../Redux/user/useSelector";
import { removeSession } from "../../../Redux/store";

import ContextMenu from "../../Common/ContextMenu";

import { profileModalNav } from "../Constants";

import useProfileController from "./Hooks/useProfileController";
import ChangePassword from "./ChangePassword";
import ProfileDetails from "./ProfileDetails";
import FreeSpin from "./FreeSpin";
import Rebates from "./Rebates";
import ClaimCoupon from "./ClaimCoupon";

import changePasswordIcon from "../../../Assets/icons/change-password-icon.png";
import claimCouponIcon from "../../../Assets/icons/claim-coupon-icon.png";
import creditCard from "../../../Assets/icons/credit-card.png";
import depositIcon from "../../../Assets/icons/deposit-icon.png";
import freeSpinIcon from "../../../Assets/icons/free-spin-icon.png";
import gameHistoryIcon from "../../../Assets/icons/game-history-icon.png";
import logoutIcon from "../../../Assets/icons/logout-icon.png";
import profileIcon from "../../../Assets/icons/profile-icon.png";
import rebatesIcon from "../../../Assets/icons/rebates-icon.png";
import userIcon from "../../../Assets/icons/user-icon.png";
import { NavIcon } from "../../Sidebar/Constants";
import Button from "../../Common/Button";
import themeColors from "../../../Utilities/colors";

const menuList = [
  {
    label: "My Profile",
    icon: <NavIcon src={userIcon} alt="user-icon" />,
    route: profileModalNav.MY_PROFILE,
  },
  {
    label: "Transaction History",
    icon: <NavIcon src={creditCard} alt="credit-icon" />,
    route: profileModalNav.TRANSACTION_HISTORY,
    isPage: true,
  },
  {
    label: "Game History",
    icon: <NavIcon src={gameHistoryIcon} alt="game-history-icon" />,
    route: profileModalNav.GAME_HISTORY,
    isPage: true,
  },
  {
    label: "Change Password",
    icon: <NavIcon src={changePasswordIcon} alt="change-password-icon" />,
    route: profileModalNav.CHANGE_PASSWORD,
  },
  {
    label: "Free Spin",
    icon: <NavIcon src={freeSpinIcon} alt="free-spin-icon" />,
    route: profileModalNav.FREE_SPIN,
  },
  {
    label: "Rebates",
    icon: <NavIcon src={rebatesIcon} alt="rebates-icon" />,
    route: profileModalNav.REBATES,
  },
  {
    label: "Claim Coupon",
    icon: <NavIcon src={claimCouponIcon} alt="claim-coupon-icon" />,
    route: profileModalNav.CLAIM_COUPON,
  },
  {
    label: "Wallet",
    icon: <NavIcon src={depositIcon} alt="deposit-icon" />,
    isWallet: true,
  },
];

type LocalProps = {
  icon: React.ReactNode;
  isPage?: boolean;
  isWallet?: boolean;
  label: string;
  route?: string;
};

const Profile = () => {
  const { user } = useUserSelector();
  const [state, handlers, menuRef] = useProfileController();

  const _renderMenuItem = (
    { icon, isPage, isWallet, label, route }: LocalProps,
    index: number
  ) => (
    <Grid item key={index}>
      <MenuItem
        onClick={() => handlers.handleItemSelection(route, isPage, isWallet)}
      >
        <Grid alignItems="center" spacing={1} container>
          <Grid item>{icon}</Grid>
          <Grid item>
            <Typography style={{ marginBottom: "5px" }} variant="subtitle2">
              {label}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>
    </Grid>
  );

  return (
    <Fragment>
      <ContextMenu
        ref={menuRef}
        backgroundColor={themeColors.blue500}
        triggerComponent={
          <AvatarContainer>
            <Avatar
              alt="Profile"
              src={profileIcon}
              sx={{ width: 32, height: 32 }}
            />
          </AvatarContainer>
        }
        contentComponent={
          <ContentWrapper>
            <Grid display="flex" flexDirection="column" container>
              <Grid item>
                <ProfileImageContainer>
                  <Avatar
                    alt="Profile"
                    src={profileIcon}
                    sx={{ width: 60, height: 60, marginBottom: "10px" }}
                  />
                  <Typography>{user?.username}</Typography>
                </ProfileImageContainer>
              </Grid>

              <Grid item>
                <ContentContainer>
                  <Grid
                    style={{ paddingTop: "5px", minWidth: "250px" }}
                    display="flex"
                    flexDirection="column"
                    container
                  >
                    {menuList.map(_renderMenuItem)}
                  </Grid>
                </ContentContainer>
              </Grid>

              <Grid sx={{ width: "100%" }} alignSelf="center" item>
                <Button
                  sx={{ marginTop: "5px", width: "100%" }}
                  onClick={() => removeSession()}
                  size="small"
                >
                  <Typography
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    align="center"
                    variant="caption"
                  >
                    <img src={logoutIcon} alt="logout-icon" /> Logout
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </ContentWrapper>
        }
      />

      {/* Modals */}
      <ChangePassword
        open={state.openModal === profileModalNav.CHANGE_PASSWORD}
        onClose={handlers.closeModal}
      />
      <ProfileDetails
        open={state.openModal === profileModalNav.MY_PROFILE}
        onClose={handlers.closeModal}
      />
      <FreeSpin
        open={state.openModal === profileModalNav.FREE_SPIN}
        onClose={handlers.closeModal}
      />
      <Rebates
        open={state.openModal === profileModalNav.REBATES}
        onClose={handlers.closeModal}
      />
      <ClaimCoupon
        open={state.openModal === profileModalNav.CLAIM_COUPON}
        onClose={handlers.closeModal}
      />
    </Fragment>
  );
};

const AvatarContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
  height: 40,
  borderRadius: 20,
});

const ContentWrapper = styled("div")({
  padding: "20px 15px 10px",
});

const ProfileImageContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginBottom: 20,
});

const ContentContainer = styled("div")({
  backgroundColor: themeColors.blue900,
  borderRadius: 10,
});

export default Profile;
