import { Typography } from "@mui/material";

import { COLUMNS } from "../../../Constants/game";

const columns = (colors: any) => [
  COLUMNS.result,
  COLUMNS.payout,
  COLUMNS.amount,
  {
    name: "betted_on",
    label: "Selected Color",
    customBodyRender: (data: string) => (
      <Typography color={colors[data].value} fontWeight={700}>
        {colors[data].name} ({data}x)
      </Typography>
    ),
  },
  {
    name: "bet_result",
    label: "Landed on",
    customBodyRender: (data: string) => (
      <Typography color={colors[data].value} fontWeight={700}>
        {colors[data].name} ({data}x)
      </Typography>
    ),
  },
  { name: "bet_id", label: "Bet ID" },
];

export { columns };
