import { CheckCircle, HighlightOff } from "@mui/icons-material";
import { Grid, Typography, styled } from "@mui/material";
import Button from "../../Common/Button";
import Modal, { ContentWrapper, SIZES } from "../../Common/Modal";
import TextLoader from "../../TextLoader";
import DepositPaymentCountdown from "./DepositPaymentCountdown";

interface LocalProps {
  payment_url: string;
  open: boolean;
  onClose?: () => void;
  isDepositComplete: boolean;
  depositStatus: boolean;
  depositTimestamp: any;
}

const PaymentOngoingModal = ({
  payment_url,
  open,
  onClose,
  isDepositComplete,
  depositStatus,
  depositTimestamp,
}: LocalProps) => {
  return (
    <Modal
      title="Deposit"
      onClose={onClose ? onClose : () => {}}
      size={SIZES.EXTRA_SMALL}
      open={open}
      withGradient
    >
      <ContentWrapper>
        {isDepositComplete ? (
          <Grid
            sx={{ paddingBottom: 4 }}
            container
            alignItems="center"
            gap={2}
            justifyContent="center"
          >
            <Grid item>
              {depositStatus ? (
                <CheckCircle fontSize="large" />
              ) : (
                <HighlightOff fontSize="large" />
              )}
            </Grid>
            <Grid item>
              <Typography>
                {depositStatus ? "Deposit completed" : "Deposit failed"}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            alignItems="center"
            direction="column"
            justifyContent="center"
          >
            <Grid item>
              <TextLoaderContainer>
                <TextLoader text="Waiting for deposit" />
                <DepositPaymentCountdown timestamp={depositTimestamp} />
              </TextLoaderContainer>
            </Grid>
            <Grid item>
              <Button
                sx={{ marginBottom: 4 }}
                filled
                onClick={() => window.open(payment_url)}
              >
                Pay now
              </Button>
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </Modal>
  );
};

export default PaymentOngoingModal;

const TextLoaderContainer = styled("div")(() => ({
  padding: 5,
}));
