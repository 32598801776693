import GradientTypography from "../Common/GradientTypography";

const SectionHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <GradientTypography sx={{ fontSize: "14px", fontWeight: "bold" }}>
      {children}
    </GradientTypography>
  );
};

export default SectionHeader;
