import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Typography } from "@mui/material";
import { useAnimation } from "framer-motion";

import {
  fireCount,
  smokeCount,
  Wrapper,
  Container,
  MultiplierContainer,
  MultiplierItem,
  ContentContainer,
  RocketContainer,
  Rocket,
  RocketFire,
  RocketSmoke,
} from "./GameStyles";
import Stars from "./Stars";

let smokeFrame = 0;
let smokeInterval: NodeJS.Timer;
type Props = {
  onDone: () => void;
};
// eslint-disable-next-line
interface MultiplierItem {
  value: number;
  win: boolean;
}
const LimboGame = forwardRef(({ onDone }: Props, ref) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const multiplierRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const fireControl = useAnimation();
  const smokeControl = useAnimation();

  const [animateRocket, setAnimateRocket] = useState(false);
  const [multipliers, setMultiplier] = useState<Array<MultiplierItem>>([]);

  const initialAnimation = { y: 0, scale: 1 };
  const endAnimation = {
    y: -(wrapperRef?.current!?.clientHeight / 2.7),
    scale: 0.1,
  };

  // animation for rocket fire
  useEffect(() => {
    const fireFrames = Array.from({ length: fireCount }).map((f, index) => {
      // for adjustment change the 11.1 by checking manually on background position on inspect element
      return `-${index * 11.1}% 0px`;
    });
    let currentFrame = 0;
    const interval = setInterval(() => {
      currentFrame = (currentFrame + 1) % fireFrames.length;
      fireControl.set({ backgroundPosition: fireFrames[currentFrame] });
    }, 25);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  // hooks for multiplier auto scroll to end when overflow
  useEffect(() => {
    multiplierRef.current!.scrollLeft += multiplierRef.current!.offsetWidth;
  }, [multipliers]);

  const _animateSmoke = () => {
    smokeControl.set({ display: "block" }); // Show the element when animation start
    const smokeFrames = Array.from({ length: smokeCount }).map((f, index) => {
      // adjust the index / 20 for repositioning or the 8.3 if the image change
      return `-${index * 8.3 + index / 20}% 0px`;
    });
    smokeInterval = setInterval(() => {
      smokeFrame = (smokeFrame + 1) % smokeFrames.length;
      smokeControl.set({
        backgroundPosition: smokeFrames[smokeFrame],
      });
      if (smokeFrame === smokeFrames.length - 1) {
        clearInterval(smokeInterval);
        smokeControl.set({ display: "none" }); // Hide the element when animation end
      }
    }, 50);
  };

  const _onRocketAnimationEnd = () => {
    if (!animateRocket) {
      onDone();
    }
    setAnimateRocket(false);
  };

  const _start = () => {
    _animateSmoke();
    setAnimateRocket(true);
  };

  const _pushMultiplier = (data: MultiplierItem) => {
    setMultiplier((prev) => [...prev, data]);
  };

  useImperativeHandle(ref, () => ({
    multiplierText: textRef.current,
    pushMultiplier: _pushMultiplier,
    start: _start,
  }));

  return (
    <Fragment>
      <Wrapper ref={wrapperRef}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Stars containerRef={wrapperRef} />
        </div>

        <Container>
          <MultiplierContainer className="no-scroll" ref={multiplierRef}>
            {multipliers.map((multi, index) => (
              <MultiplierItem
                isFirst={index === 0}
                isLast={index === multipliers.length - 1}
                isWin={multi.win}
                key={index}
              >
                {multi.value.toFixed(2)}x
              </MultiplierItem>
            ))}
          </MultiplierContainer>

          <ContentContainer>
            <Typography
              ref={textRef}
              variant="h2"
              fontWeight={700}
              letterSpacing={3}
            >
              1.00x
            </Typography>
          </ContentContainer>

          <RocketContainer>
            <Rocket
              initial={initialAnimation}
              animate={animateRocket ? endAnimation : {}}
              transition={{ duration: animateRocket ? 1 : 0 }}
              onAnimationComplete={_onRocketAnimationEnd}
            >
              <RocketFire animate={fireControl} />
            </Rocket>
            <RocketSmoke animate={smokeControl} />
          </RocketContainer>
        </Container>
      </Wrapper>
    </Fragment>
  );
});

export type LimboRef = {
  multiplierText: HTMLDivElement;
  pushMultiplier: (args: MultiplierItem) => void;
  start: () => void;
};

export default LimboGame;
