const USER = {
  login: "/login",
  logout: "/logout",
  me: "/user/me",
  register: "/register",
  changePassword: "/change-password",
  setRecoveryEmail: "/set-recovery-email",
  recoverAccount: "/recover-account",
  withdraw: "/request-withdraw",
  withdrawV3: "/withdraw-v3",
  transactionHistory: "/my-transaction-histories",
  gameHistories: "/my-game-histories",
  dailyFreeSpin: "/get-daily-free-spin",
  claimCommissions: "/claim-commissions",
  claimRebates: "/claim-rebates",
  referrals: "/referrals",
  referralCounts: "/referral-counts",
  deposit: "/deposit",
  claimCoupon: "/claim-coupon",
  createDepositSession: "/create-deposit-session",
  checkActiveDepositSession: "/check-active-deposit-session",
  readNotification: "/read-notification",
  checkDepositTransaction: "/check-deposit-transaction",
  depositV3: "/deposit-v3",
  mockDeposit: "/mock-deposit",
  depositV3CheckActiveDepositSession:
    "/deposit-v3-check-active-deposit-session",
};

const GAME = {
  placeBet: "/place-bet",
  freeSpin: "/free-spin",
  wheelOfFortune: "/wheel-of-fortune",
  minesSession: "/mines-session",
  minesPlaceBet: "/mines-place-bet",
  tileResult: "/tile-result",
  tileShowAll: "/tile-show-all",
  crashPoint: "/crashpoint",
  headsOrTails: "/heads-or-tails",
  jiliGame: "/open-jili-game",
  faChaiGame: "/fa-chai-open-game",
  pragmaticGame: "/pragmatic/open",
  nexusGame: "/nexus/open",
};

const LISTING = {
  biggestWins: "/biggest-wins",
  gameHistories: "/game-histories",
  config: "/config",
  blogs: "/blogs",
  jiliGameList: "/jili-game-list",
  faChaiGameList: "/fa-chai-game-list",
  pragmaticPlayGameList: "/pragmatic/list",
  nexusGameProvider: "/nexus/vendors",
  nexusGameList: "/nexus/games",
};

export { GAME, LISTING, USER };
