import { UrlParams, createUrlParams } from "../../Helpers";

import client from "./client";
import { LISTING } from "./endpoints";

function getBiggestWins() {
  return client.get(LISTING.biggestWins);
}

function getBlogs() {
  return client.get(LISTING.blogs);
}

function getConfig() {
  return client.get(LISTING.config);
}

function getUserHistories(arrParams: UrlParams) {
  const params = createUrlParams(arrParams);
  const path = `${LISTING.gameHistories}?${params}`;
  return client.get(path);
}

function getJiliGames() {
  return client.get(LISTING.jiliGameList);
}

function getFaChaiGames() {
  return client.get(LISTING.faChaiGameList);
}

function getPragmaticPlayGames(arrParams: UrlParams) {
  const params = createUrlParams(arrParams);
  const path = `${LISTING.pragmaticPlayGameList}?${params}`;

  return client.get(path);
}

function getNexusProvider() {
  return client.get(LISTING.nexusGameProvider);
}

function getNexusGames(arrParams: UrlParams) {
  const params = createUrlParams(arrParams, false);
  const path = `${LISTING.nexusGameList}?${params}`;

  return client.get(path);
}

const apiActions = {
  getBiggestWins,
  getBlogs,
  getConfig,
  getUserHistories,
  getJiliGames,
  getFaChaiGames,
  getPragmaticPlayGames,
  getNexusGames,
  getNexusProvider,
};

export default apiActions;
