import { useEffect, useState } from "react";
import ChangePassword from "../Components/Navigation/Profile/ChangePassword";
import { useLocation } from "react-router-dom";

function ResetPassword() {
  const location = useLocation();

  const [show, setShow] = useState(false);

  const params = new URLSearchParams(location.search);
  const resetToken = params.get("reset_token") as string;

  useEffect(() => {
    if (resetToken) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [resetToken]);

  return (
    <ChangePassword
      open={show}
      onClose={() => setShow(false)}
      resetToken={resetToken}
    />
  );
}

export default ResetPassword;
