import { Fragment } from "react";
import { useFormikContext } from "formik";

import TextField from "../Common/TextField";


import { FormikContext, NameRequiredAndRestProps } from "./Constants";
import ErrorMessage from "./ErrorMessage";

function Input({ name, ...rest }: NameRequiredAndRestProps) {
  // prettier-ignore
  const { errors, touched, values, setFieldTouched, setFieldValue } = useFormikContext<FormikContext>();

  return (
    <Fragment>
      <TextField
        value={values[name]}
        onBlur={() => setFieldTouched(name)}
        onChange={(evt: any) => setFieldValue(name, evt.target.value)}
        {...rest}
      />

      <ErrorMessage error={touched[name] ? errors[name] : ""} />
    </Fragment>
  );
}

export default Input;
