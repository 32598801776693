import {
  Box as MuiBox,
  Modal as MuiModal,
  styled,
  Typography,
  Grid,
  Box,
} from "@mui/material";

import { customTheme } from "../../Utilities/theme";
import modalCloseIcon from "../../Assets/icons/modal-close.png";

import GradientTypography from "./GradientTypography";

const enum SIZES {
  EXTRA_SMALL = "extra_small",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

type LocalProps = {
  children: any;
  open: boolean;
  size: SIZES;
  title?: string | React.ReactNode;
  disableAutoFocus?: boolean;
  withGradient?: boolean;
  onClose: () => void;
  isCloseHidden?: boolean;
  boxStyleProps?: any;
};

const Modal = ({
  children,
  open,
  size,
  title,
  onClose,
  isCloseHidden,
  boxStyleProps,
  ...rest
}: LocalProps) => {
  const CloseButton = !!title ? (
    <Grid container alignItems="center" padding="10px 24px 20px">
      <Grid xs={12} item>
        {typeof title === "string" ? (
          <GradientTypography variant="h5" fontWeight="bold" align="center">
            {title}
          </GradientTypography>
        ) : (
          title
        )}
      </Grid>
      {!isCloseHidden ? (
        <Box position="absolute" top={5} right={5}>
          <StyledCloseButton
            onClick={onClose}
            src={modalCloseIcon}
            alt="modal-close"
          />
        </Box>
      ) : null}
    </Grid>
  ) : !isCloseHidden ? (
    <StyledCloseButton
      onClick={onClose}
      src={modalCloseIcon}
      alt="modal-close"
    />
  ) : null;

  return (
    <StyledModal open={open} onClose={onClose} disableAutoFocus {...rest}>
      {(() => {
        if (size === SIZES.EXTRA_SMALL) {
          return (
            <BorderGradient>
              <StyledExtraSmallBox {...boxStyleProps}>
                {CloseButton}
                {children}
              </StyledExtraSmallBox>
            </BorderGradient>
          );
        } else if (size === SIZES.SMALL) {
          return (
            <BorderGradient>
              <StyledSmallBox {...boxStyleProps}>
                {CloseButton}
                {children}
              </StyledSmallBox>
            </BorderGradient>
          );
        } else if (size === SIZES.MEDIUM) {
          return (
            <BorderGradient>
              <StyledMediumBox {...boxStyleProps}>
                {CloseButton}
                {children}
              </StyledMediumBox>
            </BorderGradient>
          );
        } else if (size === SIZES.LARGE) {
          return (
            <BorderGradient>
              <StyledLargeBox {...boxStyleProps}>
                {CloseButton}
                {children}
              </StyledLargeBox>
            </BorderGradient>
          );
        }
        return <Typography>Size is required.</Typography>;
      })()}
    </StyledModal>
  );
};

const BorderGradient = styled("div")(() => ({
  background: customTheme.primaryGradientColor,
  padding: "2px",
  borderRadius: 12,
  backgroundClip: "border-box",
}));

const boxCommonStyle = {
  position: "relative" as const,
  margin: "auto",
  backgroundColor: "black",
  width: "100%",
  borderRadius: 12,
  overflow: "hidden",
};

const StyledModal = styled(MuiModal)(({ theme }) => ({
  display: "flex",
  alignItems: "safe center",
  justifyContent: "safe center",
  maxHeight: "100%",
  overflowY: "auto",
  margin: "auto 0",
  padding: 20,
}));

const StyledExtraSmallBox = styled(MuiBox)({
  maxWidth: 360,
  ...boxCommonStyle,
});

const StyledSmallBox = styled(MuiBox)({
  maxWidth: 540,
  ...boxCommonStyle,
});

const StyledMediumBox = styled(MuiBox)({
  maxWidth: 700,
  ...boxCommonStyle,
});
const StyledLargeBox = styled(MuiBox)({
  maxWidth: 800,
  ...boxCommonStyle,
});

const StyledCloseButton = styled("img")({
  position: "absolute",
  top: 8,
  zIndex: 2,
  right: 8,
  cursor: "pointer",
});

export const ContentWrapper = styled(MuiBox)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
}));
export { SIZES };
export default Modal;
