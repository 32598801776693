import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGames, setProviders } from "../../Redux/Casino";
import listingApi from "../../Service/api/listing";
import { CASINO_CATEGORIES, GAME_PROVIDERS } from "../../Constants";
import { useConfigSelector } from "../../Redux/utility/useSelector";

const useHomepageController = () => {
  const dispatch = useDispatch();

  const { FA_CHAI, JILI, PRAGMATIC } = GAME_PROVIDERS;

  const casino = useSelector((state: any) => state.casino);
  const { mixedProviders } = useConfigSelector();

  const [vendorList, setVendorList] = useState([]);
  const [activeContent, setActiveContent] = useState<any>();
  const [currentCategory, setCurrentCategory] = useState(
    CASINO_CATEGORIES.Slots
  );
  const [activeGameList, setActiveGames] = useState([]) as any[];
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const _getNexusProvider = async () => {
    const { ok, data }: any = await listingApi.getNexusProvider();
    if (ok) {
      return data.result;
    }
  };

  const _getFaChaiGameList = async () => {
    const { ok, data }: any = await listingApi.getFaChaiGames();

    if (ok) {
      return data.result;
    }
  };

  const _getPragmaticGameList = async (isSlot: boolean = false) => {
    const params = [{ key: "isSlot", value: isSlot }];

    const { ok, data }: any = await listingApi.getPragmaticPlayGames(params);
    if (ok) {
      return data.result;
    }
  };

  const _getNexusGames = async (activeContent: string) => {
    if (!activeContent) return;

    const params = [{ key: "vendorKey", value: activeContent }];

    const { ok, data }: any = await listingApi.getNexusGames(params);

    if (ok) {
      return data;
    }
  };

  const _getAllGames = async () => {
    if (!activeContent) return;

    const { key: vendorKey } = activeContent;

    // check if activeContent exist on third party provider
    const isExist =
      vendorList &&
      vendorList.some(
        ({ key, provider }: any) => key === vendorKey && provider
      );

    const vendor = vendorKey.split("_")[0].toUpperCase();
    const category = vendorKey.split("_")[1].toUpperCase();

    let gameList = null;
    console.log(vendor);

    if (
      isExist &&
      mixedProviders &&
      vendor !== GAME_PROVIDERS.PRAGMATIC.toLowerCase() + "_casino"
    )
      gameList = await _getNexusGames(vendor);
    // else get from second party provider.
    else {
      switch (vendor) {
        case JILI:
          gameList = await _getFaChaiGameList();
          break;
        case FA_CHAI:
          gameList = await _getFaChaiGameList();
          break;
        case PRAGMATIC:
          gameList = await _getPragmaticGameList(category === "SLOT");
          break;
        default:
          return;
      }
    }

    if (!gameList) return;

    const games = Object.entries(gameList).map(([vendor, value]: any) => value);

    dispatch(setGames({ ...casino.games, [vendor]: games }));
    setActiveGames(games);
  };

  const handleGameSearch = (e: any) => {
    const keyword = e.target.value?.toLowerCase();

    const searchResults = casino.games[activeContent?.key as string].filter(
      (item: any) => {
        return item?.names?.en?.includes(keyword);
      }
    );

    setSearch(keyword);
    setSearchResult(searchResults);
  };

  useEffect(() => {
    _getNexusProvider().then((value): any => {
      setVendorList(value);
    });
  }, []);

  useEffect(() => {
    if (vendorList) {
      dispatch(setProviders(vendorList));

      let nonMirroredVendors = vendorList
        .filter(({ mirrored }: any) => mirrored === false)
        .map(({ key }: any) => key);

      setActiveContent(
        vendorList.filter(({ enabled, category, mirrored, key }: any) =>
          mixedProviders
            ? (enabled &&
                currentCategory.toLowerCase().includes(category) &&
                !nonMirroredVendors.includes(key) &&
                mirrored === mixedProviders) ||
              (currentCategory.toLowerCase().includes(category) && !mirrored)
            : enabled &&
              currentCategory.toLowerCase().includes(category) &&
              mirrored === mixedProviders
        )[0]
      );
    }
  }, [dispatch, vendorList, currentCategory, mixedProviders]);

  useEffect(() => {
    _getAllGames();
    // eslint-disable-next-line
  }, [activeContent]);

  const handlers = {
    setActiveContent,
    setCategory: setCurrentCategory,
    handleGameSearch,
  };
  const states = {
    activeContent,
    category: currentCategory,
    vendors: vendorList,
    games: activeGameList,
    search,
  };

  return [states, handlers] as const;
};

export default useHomepageController;
