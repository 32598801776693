import { useEffect, useState } from "react";

import { handleApiError } from "../Helpers";

type Props = (page: number) => Promise<any>;

function useDatatablePagination(onRequest: Props) {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    _getData();
    //eslint-disable-next-line
  }, [page]);

  const _getData = async () => {
    setError("");
    setLoading(true);
    const { ok, data }: any = await onRequest(page);
    if (ok) {
      const { total_page, results } = data;
      setData(results);
      setTotalPage(total_page);
    } else {
      setError(handleApiError(data));
    }
    setLoading(false);
  };

  const _refresh = () => {
    if (page === 1) {
      _getData();
    } else {
      setPage(1);
    }
  };

  const state: any = {
    data,
    error,
    loading,
    page,
    totalPage,
  };

  const handler = {
    refresh: _refresh,
    setPage,
  };

  return [state, handler];
}

export default useDatatablePagination;
