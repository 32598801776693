import { create } from "apisauce";

const client = create({
  baseURL: process.env.REACT_APP_API_LINK,
  withCredentials: true,
  timeout: 60000,
  headers: {
    Authorization: sessionStorage.getItem("token") || "loadConfigs",
  },
});

export default client;
