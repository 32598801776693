import React, { useState } from "react";
import { Box, TextField as MuiTextField, styled } from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";

import visibilityOnIcon from "../../Assets/icons/visibility-on.png";
import visbilityOffIcon from "../../Assets/icons/show-pass.png";

import Button from "./Button";
import { customTheme } from "../../Utilities/theme";

const TextField = ({
  containerStyle,
  labelColor,
  leftImage,
  LeftIcon,
  leftIconColor,
  type,
  ...props
}: any) => {
  const [showPassword, setShowPassword] = useState(false);

  // flag checker
  const isPassword = type === "password";
  const hasLeftIcon = !!LeftIcon && !!leftIconColor;
  // initial conditional props
  const inputConditionalProps = {
    InputProps: {
      style: {},
    },
    InputLabelProps: {
      sx: {},
    },
  } as any;
  // construction of conditional props
  if (labelColor) {
    inputConditionalProps.InputLabelProps.sx = {
      ...inputConditionalProps.InputLabelProps.sx,
      color: labelColor,
    };
  }
  if (hasLeftIcon || React.isValidElement(leftImage)) {
    inputConditionalProps.InputLabelProps.sx = {
      ...inputConditionalProps.InputLabelProps.sx,
      left: 25,
      [`&.${inputLabelClasses.shrink}`]: {
        left: 0,
      },
    };
    inputConditionalProps.InputProps.style = {
      ...inputConditionalProps.InputProps.style,
      paddingLeft: 25,
    };
  }
  if (isPassword) {
    inputConditionalProps.InputProps.style = {
      ...inputConditionalProps.InputProps.style,
      paddingRight: 25,
    };
    type = showPassword ? "text" : "password"; // toggle type by overwriting props
  }

  // renders
  return (
    <InputContainer style={containerStyle}>
      {hasLeftIcon && (
        <LeftIcon
          sx={{
            color: leftIconColor,
            position: "absolute",
            left: 10,
          }}
        />
      )}

      {!!leftImage && (
        <Box position="absolute" left="10px" top="17px">
          {leftImage}
        </Box>
      )}

      <StyledTextField type={type} {...inputConditionalProps} {...props} />

      {isPassword && (
        <ShowHidePassword onClick={() => setShowPassword((prev) => !prev)}>
          {showPassword ? (
            <img src={visibilityOnIcon} alt="visibility-on-icon" />
          ) : (
            <img src={visbilityOffIcon} alt="visibility-off-icon" />
          )}
        </ShowHidePassword>
      )}
    </InputContainer>
  );
};

const InputContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: customTheme.basicBorderColor, // Change label color when focused
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: customTheme.basicBorderColor, // Default border color
    },
    "&:hover fieldset": {
      borderColor: customTheme.basicBorderColor, // Change border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: customTheme.basicBorderColor, // Change border color when focused
    },
  },
}));

const ShowHidePassword = styled(Button)({
  position: "absolute",
  right: 10,
  padding: 10,
  minWidth: 0,
});

export default TextField;
