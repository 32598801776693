import { Checkbox as MuiCheckbox, FormControlLabel } from "@mui/material";

type LocalProps = {
  label?: string | React.ReactNode;
  [key: string]: any;
};

function Checkbox({ label, ...rest }: LocalProps) {
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox color="success" sx={{ color: "#202a39" }} {...rest} />
      }
    />
  );
}

export default Checkbox;
