import { IconButton as MuiIconButton, styled } from "@mui/material";

const CustomIconButton = styled(MuiIconButton)(({ theme }) => ({}));

interface IconButtonProps {
  children: React.ReactNode;
  disabled?: boolean; // Make the `disabled` prop optional
  onClick?: () => void;
}

const IconButton = ({ children, ...props }: IconButtonProps) => {
  return <CustomIconButton {...props}>{children}</CustomIconButton>;
};

export default IconButton;
