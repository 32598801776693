import { ChevronLeft, PanoramaSharp } from "@mui/icons-material";
import { IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import GameContainer, { GCRef } from "../../Components/Games/Container";
import { useIsLoginSelector } from "../../Redux/user/useSelector";
import useUtilityAction, {
  LOGIN_SIGNUP_FORMS,
} from "../../Redux/utility/useAction";
import { useConfigSelector } from "../../Redux/utility/useSelector";
import gameApi from "../../Service/api/game";
import { useSearchParams } from "react-router-dom";

const ThirdPartyGamePlayer = () => {
  // TODO: add category property to casino.games
  const casino = useSelector(({ casino }) => casino);

  const gameContainerRef = useRef<GCRef | null>(null);
  const theme = useTheme();
  const config: any = useConfigSelector();
  const gameProviderNames = config?.gameProviderNames;
  let [searchParams, setSearchParams] = useSearchParams();
  const { setLoginSignupForm, toggleSnackBar } = useUtilityAction();
  const isLogin = useIsLoginSelector();

  const [gameSource, setGameSource] = useState<string>("");

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const urlParams = new URLSearchParams(window.location.search);
  const game_id = urlParams.get("game_id") as string;
  const vendor = urlParams.get("game_vendor") as string;
  const category = urlParams.get("game_category") as string;

  const openGame = async () => {
    if (!game_id) return;

    let gameData = null;

    switch (vendor) {
      case gameProviderNames.JILI:
        gameData = await gameApi.openJiliGame(game_id);
        break;
      case gameProviderNames.FA_CHAI:
        gameData = await gameApi.openFaChaiGame(game_id);
        break;
      case gameProviderNames.PRAGMATIC:
        gameData = await gameApi.openPragmaticGame(game_id, category);
        break;
      default:
        gameData = await gameApi.openNexusGame(
          vendor + "_" + category,
          game_id
        );
        break;
    }

    const { status, data: jiliGameData, ok }: any = gameData;

    if (status === 400) {
      return window.location.replace("/");
    }
    if (status === 401) {
      return setLoginSignupForm(LOGIN_SIGNUP_FORMS.SIGNIN);
    }

    if (status === 304) {
      return toggleSnackBar("error", "Insufficient balance");
    }

    if (
      (ok || jiliGameData.code === 0) &&
      (vendor === gameProviderNames.JILI ||
        vendor === gameProviderNames.FA_CHAI ||
        vendor === gameProviderNames.PRAGMATIC)
    ) {
      setGameSource(jiliGameData.data.Data);
    } else setGameSource(jiliGameData.url);
  };

  useEffect(() => {
    openGame();

    const script = document.createElement("script");
    script.src =
      "https://client.pragmaticplaylive.net/desktop/assets/api/fullscreenApi.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.history.replaceState({}, document.title);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLogin) {
      window.location.replace("/");
    }
  }, [isLogin]);

  console.log("params", searchParams);

  return isMobile ? (
    <Fragment>
      <IconButton
        onClick={() => {
          window.history.replaceState({}, document.title);
          window.location.replace("/");
        }}
        sx={{ zIndex: 99999, position: "absolute", top: 15, left: 8 }}
      >
        <ChevronLeft />
      </IconButton>
      <Iframe title="Jili Game" src={gameSource} />
    </Fragment>
  ) : (
    <GameContainer ref={gameContainerRef} gameCode="JILI">
      {gameSource ? (
        <Iframe title={vendor} src={gameSource} allowFullScreen />
      ) : null}
    </GameContainer>
  );
};

export default ThirdPartyGamePlayer;

const Iframe = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "70vh",
  border: "none",
  borderRadius: "10px",
  [theme.breakpoints.down("sm")]: {
    height: "100%",
    width: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 9999,
  },
}));
