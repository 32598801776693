import { FormikValues } from "formik";

import { handleApiError } from "../../../../Helpers";
import useObjectState from "../../../../Hooks/useObjectState";
import useUtilityAction from "../../../../Redux/utility/useAction";
import userApi from "../../../../Service/api/user";

const initialState = {
  amount: 0,
  step: 0,
  referenceNumber: "",
};

const useGcashV2Controller = (): any => {
  const { toggleSnackBar, toggleWallet } = useUtilityAction();

  const [state, setState] = useObjectState(initialState);

  const _onDeposit = (formData: FormikValues) => {
    setState({ amount: formData.amount, step: 1 });
  };

  const _onReferenceNumberChange = (e: any) =>
    setState({ referenceNumber: e.target.value });

  const _verifyReferenceNumber = async (e: any) => {
    e.preventDefault();
    const payload = { reference_number: state.referenceNumber };
    const { ok, data }: any = await userApi.checkDepositTransaction(payload);
    if (ok) {
      toggleSnackBar("success", handleApiError(data));
      toggleWallet();
    } else {
      toggleSnackBar("error", handleApiError(data));
    }
  };

  const handlers = {
    onDeposit: _onDeposit,
    onReferenceNumberChange: _onReferenceNumberChange,
    verifyReferenceNumber: _verifyReferenceNumber,
  };

  return [state, handlers];
};

export default useGcashV2Controller;
