import { Fragment } from "react";
import { useFormikContext } from "formik";

import Button from "../Common/Button";

import { FormikContext, RestProps } from "./Constants";
import ErrorMessage from "./ErrorMessage";

function Submit({ children, showError = true, loading, ...rest }: RestProps) {
  // prettier-ignore
  const { errors, isSubmitting, handleSubmit, setTouched } = useFormikContext<FormikContext>();

  const _onClick = () => {
    setTouched({}, false);
    handleSubmit();
  };

  return (
    <Fragment>
      {!!errors.api && showError && (
        <ErrorMessage error={errors.api} center noMt />
      )}
      <Button
        {...rest}
        disabled={loading || isSubmitting}
        loading={loading || isSubmitting}
        onClick={_onClick}
      >
        {children}
      </Button>
    </Fragment>
  );
}

export default Submit;
