import { styled } from "@mui/material";
import { motion } from "framer-motion";

import BackgroundImage from "../../../Assets/Images/limbo-background.png";
import ObjectsImage from "../../../Assets/Images/limbo-objects.png";
import RocketImage from "../../../Assets/Images/limbo-rocket.png";
import RocketFireImage from "../../../Assets/Images/limbo-rocket-fire.png";
import RocketSmokeImage from "../../../Assets/Images/limbo-rocket-smoke.png";
import colors from "../../../Utilities/colors";

export const fireCount = 10;
export const smokeCount = 13;

export const Wrapper = styled("div")({
  position: "relative",
  width: "auto",
  aspectRatio: "1.4/1",
  backgroundImage: `url(${BackgroundImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  overflow: "hidden",
  borderRadius: 10,
});

export const Container = styled("div")({
  backgroundImage: `url(${ObjectsImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

export const MultiplierContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  overflowX: "auto",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  marginTop: 10,
});

export const MultiplierItem = styled("div")(
  ({
    isFirst,
    isLast,
    isWin,
  }: {
    isFirst: boolean;
    isLast: boolean;
    isWin: boolean;
  }) => ({
    backgroundColor: isWin ? colors.green : colors.red,
    margin: "0 5px",
    padding: "2.5px 5px",
    fontWeight: "bold",
    borderRadius: 5,
    letterSpacing: 1.5,
    ...(isFirst && { marginLeft: 10, marginRight: 5 }),
    ...(isLast && { marginRight: 10, marginLeft: 5 }),
  })
);

export const ContentContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

export const RocketContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  left: "50%",
  transform: "translate(-50%)",
  width: "13%",
  height: "20%",
  bottom: 0,
}));

export const Rocket = styled(motion.div)({
  position: "relative",
  backgroundImage: `url(${RocketImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  aspectRatio: "1/1.3",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "-75%",
  width: "100%",
});

export const RocketFire = styled(motion.div)(({ theme }) => ({
  backgroundImage: `url(${RocketFireImage})`,
  backgroundSize: "cover",
  backgroundPosition: "0px 0px",
  aspectRatio: "1/1",
  width: "70%",
  marginBottom: "-110%",
  marginRight: "13%",
}));

export const RocketSmoke = styled(motion.div)({
  display: "none",
  backgroundImage: `url(${RocketSmokeImage})`,
  backgroundSize: "cover",
  backgroundPosition: "0 0px",
  aspectRatio: "1/1",
  width: "125%",
  position: "absolute",
  left: "50%",
  top: "-25%",
  transform: "translate(-50%)",
  zIndex: 1,
});