import { useDispatch } from "react-redux";

import { reduceFreeSpin, setIsLogin, setUser } from "./";

function useNoSessionAction() {
  const dispatch = useDispatch();

  const _reduceFreeSpin = () => dispatch(reduceFreeSpin());

  const _setIsLogin = (isLogin: boolean) => dispatch(setIsLogin(isLogin));

  const _setUser = (data: any) => dispatch(setUser(data));

  return {
    reduceFreeSpin: _reduceFreeSpin,
    setIsLogin: _setIsLogin,
    setUser: _setUser,
  };
}

export default useNoSessionAction;
