//eslint-disable-next-line
export default {
  background: "#0d131c",
  blue: "#1976d2",
  blue500: "#00163f",
  blue900: "#01102b",
  green: "green",
  red: "#d32f2f",
  white: "white",
};
