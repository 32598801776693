import { Grid, Typography, styled } from "@mui/material";

import cardIcon from "../../Assets/icons/card-with-half-circle.png";
import diamondBig from "../../Assets/icons/diamond-big.png";
import percentCircleIcon from "../../Assets/icons/percent-circle.png";
import userWithStarIcon from "../../Assets/icons/user-with-star-2.png";
import vipHeroImage from "../../Assets/icons/vip-hero.png";
import GradientTypography from "../../Components/Common/GradientTypography";
import { useConfigSelector } from "../../Redux/utility/useSelector";

const VIPRanking = () => {
  const config = useConfigSelector();
  const lastRank = config.vipRanking[config.vipRanking.length - 1];

  const _renderRankSystemItem = (
    imgUrl: string,
    title: string,
    subTitle: string
  ) => (
    <Grid xs={12} item>
      <RankSystemItemContainer
        justifyContent={{ xs: "center", sm: "center", md: "flex-start" }}
        container
      >
        <Grid
          xs={12}
          sm={12}
          md={3}
          flex={{ xs: "0 !important", sm: "0 !important", md: 1 }}
          display="flex"
          justifyContent={{ xs: "center", sm: "center", md: "flex-start" }}
          paddingRight="10px"
          item
        >
          <img alt={title} src={imgUrl} width="66px" height="auto" />
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={9}
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems={{ xs: "center", sm: "center", md: "flex-start" }}
          item
        >
          <GradientTypography
            textTransform="uppercase"
            fontSize={20}
            fontWeight="bold"
          >
            {title}
          </GradientTypography>
          <Typography fontSize={17}>{subTitle}</Typography>
        </Grid>
      </RankSystemItemContainer>
    </Grid>
  );

  return (
    <Grid container position="relative" spacing={2}>
      <Grid sm={12} md={7} textAlign={{ xs: "center", md: "start" }} item>
        <Title>A Lot of Benefits with </Title>
        <TitleHighLight fontSize={35} fontWeight="bold">
          Rank System
        </TitleHighLight>
      </Grid>

      <Grid
        position={{ sm: "relative", md: "absolute" }}
        textAlign={{ xs: "center", md: "end" }}
        height="438px"
        top={0}
        right={0}
        sm={12}
        md={5}
        marginTop={{ xs: 5, sm: 5, md: 0 }}
        marginBottom={{ xs: 5, sm: 5, md: 0 }}
        item
      >
        <img
          alt="ranking-avatar"
          src={config?.siteAssets?.vip_ranking_maskot || vipHeroImage}
          height="auto"
          width="100%"
          style={{ maxHeight: 438, maxWidth: 438 }}
        />
      </Grid>

      <Grid sm={12} md={7} item>
        <Grid container spacing={{ sx: 5, sm: 5, md: 0 }}>
          {_renderRankSystemItem(
            userWithStarIcon,
            "levels & rewards",
            "The more you play - the more you gain"
          )}

          {_renderRankSystemItem(
            cardIcon,
            "increased rebates",
            `Receive up to ${lastRank.cashback} of your coins back`
          )}

          {_renderRankSystemItem(
            percentCircleIcon,
            "exclusive promotions",
            "Reach higher rank to unlock unique benefits"
          )}

          {_renderRankSystemItem(
            diamondBig,
            "vip club",
            "Become a part of VIP Club to get exclusive bonuses"
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const getTitleStyle = (theme: any) => ({
  display: "inline",
  textTransform: "uppercase" as const,
  fontSize: "37px",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
});

const Title = styled(Typography)(({ theme }) => getTitleStyle(theme));

const TitleHighLight = styled(GradientTypography)(({ theme }) =>
  getTitleStyle(theme)
);

const RankSystemItemContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
});

export default VIPRanking;
