import { Fragment, useEffect, useState } from "react";

import TransactionModalHeaderNavigator from "./Components/TransactionModalHeaderNavigator";
import { renderWithdrawProvider } from "./Helpers";
import { Box } from "@mui/material";

const GlobalTrustPayWithdraw = ({
  onProviderSelect,
}: {
  onProviderSelect: Function;
}) => {
  const [selectedProvider, setSelectedProvider] = useState("");

  const Container = selectedProvider ? Box : Fragment;

  useEffect(() => {
    onProviderSelect(selectedProvider);
  }, [selectedProvider]);

  return (
    <Container>
      <TransactionModalHeaderNavigator
        isProviderSelected={selectedProvider}
        setProvider={setSelectedProvider}
      />
      {renderWithdrawProvider(selectedProvider, setSelectedProvider)}
    </Container>
  );
};

export default GlobalTrustPayWithdraw;
