import { Fragment } from "react";
import { Formik } from "formik";

function Form({
  children,
  initialValues,
  validationSchema,
  onSubmit,
  ...rest
}: any) {
  const isChildFunction = typeof children === "function";

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      {...rest}
    >
      {isChildFunction ? children : () => <Fragment>{children}</Fragment>}
    </Formik>
  );
}

export default Form;
