import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

import routes from "../../Routes/Constants";
import listingApi from "../../Service/api/listing";

import { stringToPath } from "../../Helpers";

import SectionHeader from "./SectionHeader";
import Subtitle from "./Subtitle";

const Links = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    listingApi.getBlogs().then(({ ok, data }: any) => {
      if (ok) {
        setBlogs(data.results);
      }
    });
  }, []);

  if (blogs.length === 0) {
    return null;
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <SectionHeader>Links</SectionHeader>
      </Grid>
      {blogs.map((b: any, i) => (
        <Grid key={i} item>
          <Link
            to={`${routes.BLOG}${stringToPath(b.title)}`}
            state={b}
            style={{ textDecoration: "none" }}
          >
            <Subtitle>{b.title}</Subtitle>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default Links;
