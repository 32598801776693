import { Box, Grid, styled } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InHouseGames from "../../Components/InHouseGames";
import { CASINO_CATEGORIES } from "../../Constants";
import useGamesController from "../../Hooks/useGamesController";
import { setProviders } from "../../Redux/Casino";
import ContentNavigator from "./ContentNavigator";
import FeaturesCarousel from "./FeaturesCarousel";
import useHomepageController from "./useHomepageController";
import useUtilityAction from "../../Redux/utility/useAction";
import { useConfigSelector } from "../../Redux/utility/useSelector";
import PreviousToggle from "../../Components/InHouseGames/ProvidersBar/PreviousToggle";
import GradientTypography from "../../Components/Common/GradientTypography";

const HomepageContext: any = createContext({});

const HomepageV2 = () => {
  const dispatch = useDispatch();
  const { mixedProviders } = useConfigSelector();
  const { toggleWallet } = useUtilityAction();
  const urlParams = new URLSearchParams(window.location.search);
  const wallet = urlParams.get("wallet");

  const { providers } = useSelector((state: any) => state.casino);

  const [states, handlers] = useHomepageController(); // pass here the first element of the

  useEffect(() => {
    if (wallet === "true") toggleWallet();
  }, [wallet, toggleWallet]);

  return (
    <HomepageContext.Provider value={{ states, handlers }}>
      <FeaturesCarousel />
      <ContentNavigator
        activeCategory={states.category}
        setActiveCategory={handlers.setCategory}
        activeContent={states.activeContent as string | any}
        setActiveContent={handlers.setActiveContent as any}
        handleGameSearch={handlers.handleGameSearch}
        search={states.search}
      />
      <InHouseGames />
    </HomepageContext.Provider>
  );
};

export default HomepageV2;

const MainContainer = styled(Grid)(() => ({
  position: "absolute",
  left: 0,
  top: "79px",
}));
const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: "700px",
  [theme.breakpoints.down("md")]: {
    marginTop: "285px",
  },
}));

export const useHomepageContextState = () => useContext(HomepageContext);
