import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  Box,
  styled,
  CircularProgress,
  Typography,
  IconButton,
  TableFooter,
  Grid,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import lodashIsEmpty from "lodash/isEmpty";
import GradientTypography from "./GradientTypography";
import { customTheme } from "../../Utilities/theme";
import { PAGE_MAX_WIDTH } from "../../Constants";

interface Column {
  name: string;
  label: string;
  extraProps?: object;
  customBodyRender?: (value: any, row: DataItem) => React.ReactNode;
}

interface DataItem {
  [key: string]: any;
}

interface DataTableProps {
  columns: Column[];
  data: DataItem[];
  loading?: boolean;
  error?: string;
  page?: number;
  totalPages?: number;
  onPageChange?: (newPage: number) => void;
  title?: string;
  fontSize?: number;
}

const DataTable: React.FC<DataTableProps> = ({
  columns,
  data,
  loading,
  error,
  page,
  totalPages,
  onPageChange,
  title,
  fontSize,
}) => {
  const MAX_DISPLAY_PAGES = 10; // Maximum number of displayed page buttons
  const SKIP_PAGES_THRESHOLD = 10; // Number of pages required to enable skipping

  const _renderContent = () => {
    if (error) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          </TableCell>
        </TableRow>
      );
    } else if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <CircularProgress size={20} />
          </TableCell>
        </TableRow>
      );
    } else if (lodashIsEmpty(data)) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <Typography variant="caption">No data to display</Typography>
          </TableCell>
        </TableRow>
      );
    }

    return data.map((item, index) => {
      return (
        <TableRow key={index}>
          {columns.map((column, index) => (
            <TableCell
              style={{
                fontSize: fontSize ? fontSize : 20,
              }}
              key={index}
            >
              {column.customBodyRender
                ? column.customBodyRender(item[column.name], item)
                : item[column.name]}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  };

  const _renderPagination = () => {
    if (page !== undefined && totalPages !== undefined) {
      let displayPages: number[] = [];
      let startPage = 1;
      let endPage = totalPages;

      if (totalPages > MAX_DISPLAY_PAGES) {
        if (page <= SKIP_PAGES_THRESHOLD) {
          endPage = MAX_DISPLAY_PAGES;
        } else if (page >= totalPages - SKIP_PAGES_THRESHOLD) {
          startPage = totalPages - MAX_DISPLAY_PAGES + 1;
        } else {
          const offset = Math.floor(MAX_DISPLAY_PAGES / 2);
          startPage = page - offset;
          endPage = page + offset;
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        displayPages.push(i);
      }

      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <IconButton
              disabled={page === 1}
              onClick={() => onPageChange && onPageChange(page - 1)}
            >
              <KeyboardArrowLeft />
            </IconButton>
            {startPage > 1 && (
              <IconButton
                onClick={() => onPageChange && onPageChange(startPage - 1)}
              >
                ...
              </IconButton>
            )}
            {displayPages.map((pageNumber) => (
              <IconButton
                key={pageNumber}
                disabled={pageNumber === page}
                sx={
                  pageNumber === page
                    ? {
                        backgroundColor: "green !important",
                        color: "#fff !important",
                      }
                    : {}
                }
                onClick={() => onPageChange && onPageChange(pageNumber)}
              >
                {pageNumber}
              </IconButton>
            ))}
            {endPage < totalPages && (
              <IconButton
                onClick={() => onPageChange && onPageChange(endPage + 1)}
              >
                ...
              </IconButton>
            )}
            <IconButton
              disabled={page === totalPages}
              onClick={() => onPageChange && onPageChange(page + 1)}
            >
              <KeyboardArrowRight />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  };

  return (
    <Grid container direction="column" gap={2}>
      <Grid alignSelf="center" item>
        <GradientTypography style={{ fontWeight: "bold", fontSize: "24px" }}>
          {title}
        </GradientTypography>
      </Grid>
      <Grid item>
        <TableWrapper>
          <Table>
            <CustomTableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    style={{
                      background: "none",
                      borderBottom: "none",
                      fontWeight: "bold",
                      fontSize: fontSize ? fontSize : 20,
                    }}
                    key={index}
                    {...column.extraProps}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </CustomTableHead>
            <TableBody>{_renderContent()}</TableBody>
            {totalPages! > 1 && (
              <TableFooter>{_renderPagination()}</TableFooter>
            )}
          </Table>
        </TableWrapper>
      </Grid>
    </Grid>
  );
};

export default DataTable;

const TableWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    width: "90vw",
    overflowY: "auto",
  },
}));
const CustomTableHead = styled(TableHead)(() => ({
  background: "none",
}));
const TableCell = styled(MuiTableCell)(() => ({
  color: customTheme.tablePrimaryTextColor,
  fontWeight: "normal",
}));
const TableBody = styled(MuiTableBody)(() => ({
  position: "relative",
  tr: {
    backgroundColor: customTheme.transparentBg,
  },
}));
