import { Grid } from "@mui/material";
import Button from "../../Common/Button";
import Modal, { ContentWrapper, SIZES } from "../../Common/Modal";
import TextField from "../../Common/TextField";
import useClaimCouponController from "./Hooks/useClaimCouponController";

interface LocalProps {
  open: boolean;
  onClose: () => void;
}

const ClaimCoupon = ({ open, onClose }: LocalProps) => {
  const [state, handlers] = useClaimCouponController(onClose);

  return (
    <Modal
      open={open}
      size={SIZES.EXTRA_SMALL}
      title="Claim Coupon"
      onClose={onClose}
      withGradient
    >
      <ContentWrapper>
        <Grid spacing={2} container>
          <Grid xs={12} item>
            <TextField
              onChange={(e: any) => handlers.setCode(e.target.value)}
              value={state.code}
              labelColor="white"
              label="Enter code"
              disabled={state.isClaimingCode}
              fullWidth
            />
          </Grid>

          <Grid xs={12} item>
            <Button
              onClick={handlers.onCouponClaim}
              variant="contained"
              color="primary"
              disabled={state.isClaimingCode}
              loading={state.isClaimingCode}
              filled
              fullWidth
            >
              Claim
            </Button>
          </Grid>
        </Grid>
      </ContentWrapper>
    </Modal>
  );
};

export default ClaimCoupon;
