import { Badge, Box, Typography, styled } from "@mui/material";
import moment from "moment";
import lodashFilter from "lodash/filter";

import bellIcon from "../../../Assets/icons/bell-icon.png";
import commissionsIcon from "../../../Assets/icons/commisions-icon.png";
import userApi from "../../../Service/api/user";
import themeColors from "../../../Utilities/colors";

import ContextMenu from "../../Common/ContextMenu";
import GradientDivider from "../../Common/GradientDivider";

function Notification({ data = [] }: { data: any[] }) {
  const reverseData = JSON.parse(JSON.stringify(data)).reverse();
  const newNotifCount = lodashFilter(reverseData, { is_viewed: false }).length;

  const _setAsViewed = () => {
    if (newNotifCount > 0) {
      setTimeout(userApi.readNotification, 1000);
    }
  };

  const _renderContent = () => {
    if (reverseData.length !== 0) {
      return reverseData.map((n: any, i: number) => {
        const isViewed = n.is_viewed;
        return (
          <Box key={i}>
            <ItemContainer
              style={{
                backgroundColor: isViewed ? "transparent" : "#9090901a",
              }}
            >
              <Box display="flex" alignItems="center" mb={1}>
                <img
                  src={commissionsIcon}
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                <Typography fontWeight="bold">{n.title}</Typography>
              </Box>
              <Typography display="block" variant="caption" mb={1}>
                {n.description}
              </Typography>
              <Box
                display="flex"
                justifyContent={isViewed ? "flex-end" : "space-between"}
              >
                {!isViewed && (
                  <Typography
                    variant="caption"
                    color="primary"
                    style={{ opacity: 0.8 }}
                  >
                    • new
                  </Typography>
                )}
                <Typography variant="caption" style={{ opacity: 0.8 }}>
                  {moment(n.date).fromNow()}
                </Typography>
              </Box>
            </ItemContainer>
            {i !== data.length && (
              <Box pl="15px" pr="15px">
                <GradientDivider />
              </Box>
            )}
          </Box>
        );
      });
    } else {
      return (
        <ItemContainer>
          <Typography align="center">No notification to show</Typography>
        </ItemContainer>
      );
    }
  };

  return (
    <ContextMenu
      arrow={false}
      backgroundColor={themeColors.blue500}
      buttonProps={{ style: { padding: 5 } }}
      triggerComponent={
        <Badge badgeContent={newNotifCount} color="primary">
          <img src={bellIcon} alt="notif-icon" />
        </Badge>
      }
      contentComponent={
        <ItemWrapper className="no-scroll">{_renderContent()}</ItemWrapper>
      }
      onOpen={_setAsViewed}
    />
  );
}

const ItemWrapper = styled("div")({
  maxHeight: "50vh",
  overflowY: "auto",
});

const ItemContainer = styled("div")(({ theme }) => ({
  padding: "10px 15px",
  width: 320,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default Notification;
