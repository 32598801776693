import { FormikValues, FormikHelpers } from "formik";

import LockIcon from "../../../Assets/icons/change-password-icon.png";
import { handleApiError } from "../../../Helpers";
import Validation from "../../../Helpers/Validation";
import useUtilityAction from "../../../Redux/utility/useAction";
import { removeSession } from "../../../Redux/store";
import userApi from "../../../Service/api/user";

import Modal, { ContentWrapper, SIZES } from "../../Common/Modal";
import GradientTypography from "../../Common/GradientTypography";
import Form from "../../Form";
import Input from "../../Form/Input";
import Submit from "../../Form/Submit";

type LocalProps = {
  open: boolean;
  onClose: () => void;
  resetToken?: string;
};

const fontColor = "#888888 !important";
const inputVariant = "outlined";

const initialValues = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};

function ChangePassword({ open, onClose, resetToken }: LocalProps) {
  const { toggleSnackBar } = useUtilityAction();

  const validationSchema = Validation.createSchema({
    oldPassword: resetToken ? null : Validation.oldPasswordSchema,
    password: Validation.passwordSchema,
    confirmPassword: Validation.confirmPasswordSchema,
  });

  const _onSubmit = async (
    formData: FormikValues,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<FormikValues>
  ) => {
    const payload = {
      old_password: formData.oldPassword,
      new_password: formData.password,
      confirm_password: formData.confirmPassword,
      reset_token: resetToken,
    };
    const { ok, data } = await userApi.changePassword(payload);
    if (ok) {
      toggleSnackBar("success", handleApiError(data));
      resetForm();
      removeSession();
      onClose();
    } else {
      setErrors({ api: handleApiError(data) });
    }
    setSubmitting(false);
  };

  return (
    <Modal
      open={open}
      size={SIZES.SMALL}
      title={
        <GradientTypography
          variant="h5"
          fontWeight="bold"
          align="center"
          width={{ xs: "unset", sm: 400 }}
        >{`${resetToken ? "Reset" : "Change"} Password`}</GradientTypography>
      }
      onClose={onClose}
    >
      <ContentWrapper>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_onSubmit}
        >
          {!resetToken && (
            <Input
              containerStyle={{ marginBottom: 15 }}
              label="Old password"
              name="oldPassword"
              type="password"
              labelColor={fontColor}
              leftImage={<img src={LockIcon} />}
              variant={inputVariant}
              fullWidth
            />
          )}

          <Input
            containerStyle={{ marginBottom: 15 }}
            label={`${resetToken ? "Enter new" : "Enter"} password`}
            name="password"
            type="password"
            labelColor={fontColor}
            leftImage={<img src={LockIcon} />}
            variant={inputVariant}
            fullWidth
          />

          <Input
            containerStyle={{ marginBottom: 15 }}
            label={`${resetToken ? "Repeat new" : "Repeat"} password`}
            name="confirmPassword"
            type="password"
            labelColor={fontColor}
            leftImage={<img src={LockIcon} />}
            variant={inputVariant}
            fullWidth
          />

          <Submit variant="contained" color="error" filled fullWidth>
            CONFIRM
          </Submit>
        </Form>
      </ContentWrapper>
    </Modal>
  );
}

export default ChangePassword;
