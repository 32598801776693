import { forwardRef, useImperativeHandle, useState } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";

type Types = {
  image: string;
  link: string;
};

const BannerAds = forwardRef((_, ref) => {
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [link, setLink] = useState("");

  const _onClose = () => setShow(false);

  const _show = (banner: Types) => {
    setImage(banner.image);
    setLink(banner.link);
    setShow(true);
  };

  useImperativeHandle(ref, () => ({
    show: _show,
  }));

  return (
    <Modal open={show} onClose={_onClose} disableAutoFocus>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        display="flex"
        flexDirection="column"
        maxHeight="80%"
        maxWidth="80%"
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        {/* link & image */}
        {/* eslint-disable-next-line */}
        <a href={link} target="_blank">
          {/* eslint-disable-next-line */}
          <img src={image} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </a>

        {/* close button */}
        <IconButton onClick={_onClose}>
          <Close />
        </IconButton>
      </Box>
    </Modal>
  );
});

export type BannerAdsRef = {
  show: (args: Types) => void;
};

export default BannerAds;
