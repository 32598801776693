import { Fragment, useState } from "react";
import { Box, Grid, IconButton, Typography, styled } from "@mui/material";
import { ArrowCircleDown, ArrowCircleUp } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

import WalletTitleBackground from "../../../Assets/icons/wallet-title-background.png";
import GcashBanner from "../../../Assets/icons/gcash-banner.png";
import MayaBanner from "../../../Assets/icons/maya-banner.png";
import Balance from "../../Common/Balance";
import Button from "../../Common/Button";
import Modal, { SIZES } from "../../Common/Modal";
import GradientTypography from "../../Common/GradientTypography";

import useUtilityAction, {
  WALLET_NAVIGATION,
} from "../../../Redux/utility/useAction";
import useUserSelector from "../../../Redux/user/useSelector";
import { useShowWalletModalSelector } from "../../../Redux/utility/useSelector";

import coinIcon from "../../../Assets/icons/coin-icon.png";

import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import { customTheme } from "../../../Utilities/theme";
import GradientDivider from "../../Common/GradientDivider";
import Divider from "../../Common/Divider";

const navigationList = [
  {
    label: "Deposit",
    icon: <ArrowCircleDown />,
    route: WALLET_NAVIGATION.DEPOSIT,
    component: Deposit,
  },
  {
    label: "Withdraw",
    icon: <ArrowCircleUp />,
    route: WALLET_NAVIGATION.WITHDRAW,
    component: Withdraw,
  },
];

const Wallet = () => {
  const location = useLocation();
  const showWalletModal = useShowWalletModalSelector();
  const { user } = useUserSelector();
  const { toggleWallet } = useUtilityAction();

  const [activeRoute, setActiveRoute] = useState<string>(
    WALLET_NAVIGATION.DEPOSIT
  );
  const [provider, setProvider] = useState<string>("");

  // prettier-ignore
  const NavigationContent = navigationList.filter((e) => e.route === activeRoute)[0].component; // get component of the active route
  const isGcash = provider === "GCASH v3";
  const isMaya = provider === "Maya";

  const _getTitle = () => {
    if (isGcash) {
      return (
        <TitleContainer style={{ backgroundImage: `url("${GcashBanner}")` }} />
      );
    } else if (isMaya) {
      return (
        <TitleContainer style={{ backgroundImage: `url("${MayaBanner}")` }} />
      );
    }
    return (
      <TitleContainer
        style={{ backgroundImage: `url("${WalletTitleBackground}")` }}
      >
        <Typography
          color={customTheme.basicBorderColor}
          fontWeight="bold"
          mb={1}
          fontSize={25}
        >
          Wallet
        </Typography>
        <Box
          border={`1px solid ${customTheme.basicBorderColor}`}
          borderRadius="10px"
          padding={1}
        >
          <GradientTypography>
            <Balance
              value={user?.balance}
              fontSize={30}
              fontWeight="bold"
              letterSpacing={2}
              normal
            />
          </GradientTypography>
        </Box>
      </TitleContainer>
    );
  };

  return (
    <Fragment>
      {/* Trigger to show modal */}
      <WalletAndBalanceContainer>
        <Box pr={{ xs: 0, sm: "18px" }}>
          <IconButton onClick={toggleWallet}>
            <Balance
              pesoIcon={coinIcon}
              value={location?.state?.inPlay ? "(In Play)" : user?.balance}
            />
          </IconButton>
        </Box>
        <Box display={{ xs: "none", sm: "block" }}>
          <Button
            filled
            variant="contained"
            color="error"
            onClick={toggleWallet}
          >
            Wallet
          </Button>
        </Box>
      </WalletAndBalanceContainer>

      {/* Modal when trigger pressed */}
      <Modal
        size={SIZES.MEDIUM}
        open={showWalletModal}
        onClose={toggleWallet}
        title={_getTitle()}
      >
        <Grid container>
          {/* Deposit/Withdraw Button */}
          {!provider && (
            <Grid xs={12} item>
              <Grid container spacing={1} pl={1} pr={1}>
                {navigationList.map((nav, index) => {
                  const isActive = nav.route === activeRoute;
                  return (
                    <Grid xs={12 / navigationList.length} item key={index}>
                      <NavigationButton
                        variant={isActive ? "contained" : "text"}
                        onClick={() => setActiveRoute(nav.route)}
                        style={{
                          marginBottom: 8,
                          backgroundColor: isActive
                            ? customTheme.modalCardBg
                            : "transparent",
                          borderRadius: 5,
                          padding: 30,
                        }}
                        fullWidth
                      >
                        <Typography
                          color={customTheme.basicBorderColor}
                          fontSize={20}
                          fontWeight="bold"
                        >
                          {nav.label}
                        </Typography>
                      </NavigationButton>
                      {isActive && <GradientDivider />}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}

          {/* Divider */}
          {!provider && (
            <Grid xs={12} item>
              <Divider />
            </Grid>
          )}

          {/* Content of navigation */}
          <Grid xs={12} item>
            <Container>
              <NavigationContent onProviderSelect={setProvider} />
            </Container>
          </Grid>
        </Grid>
      </Modal>
    </Fragment>
  );
};

const WalletAndBalanceContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#202a39",
  borderRadius: 10,
  paddingLeft: 12,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 6,
    paddingRight: 6,
  },
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: 475,
  [theme.breakpoints.down("sm")]: {
    minHeight: 375,
  },
}));

const NavigationButton = styled(Button)({
  borderRadius: 20,
});

const TitleContainer = styled("div")({
  backgroundSize: "cover",
  backgroundPosition: "center",
  margin: "-10px -24px",
  padding: "20px 0 10px",
  height: 130,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
});

export default Wallet;
