import { useNavigate } from "react-router";
import { Box, Grid, Link, Typography } from "@mui/material";
import moment from "moment";
import lodashKeys from "lodash/keys";
import lodashMap from "lodash/map";
import lodashPickBy from "lodash/pickBy";

import freeSpinBackground from "../../../Assets/icons/free-spin-background.png";
import { handleApiError } from "../../../Helpers";
import useUserAction from "../../../Redux/user/useAction";
import useUserSelector from "../../../Redux/user/useSelector";
import useUtilityAction from "../../../Redux/utility/useAction";
import { useConfigSelector } from "../../../Redux/utility/useSelector";
import routes from "../../../Routes/Constants";
import userApi from "../../../Service/api/user";

import Button from "../../Common/Button";
import Countdown from "../../Common/Countdown";
import FreeSpinGame from "../../Games/Circle/FreeSpin";
import Modal, { ContentWrapper, SIZES } from "../../Common/Modal";

import { useState } from "react";
import GradientTypography from "../../Common/GradientTypography";

type LocalProps = {
  open: boolean;
  onClose: () => void;
};

function FreeSpin({ open, onClose }: LocalProps) {
  const navigate = useNavigate();
  const { setUser } = useUserAction();
  const { user } = useUserSelector();
  const { toggleSnackBar } = useUtilityAction();
  const config = useConfigSelector();

  const [isClaiming, setClaiming] = useState(false);
  const [showClaim, setShowClaim] = useState(
    !user?.last_free_spins_claim ? true : false
  );

  const fromDate = moment(user?.last_free_spins_claim).toDate();
  const toDate = moment(user?.last_free_spins_claim).add(24, "hour").toDate();
  const worstIndexes = lodashMap(
    lodashKeys(lodashPickBy(config.freeSpinData, { option: "0" })),
    Number
  );

  const _onGetMoreSpin = (evt: any) => {
    evt.preventDefault();
    onClose();
    navigate(routes.VIP_RANKING);
  };

  const _onCountdownEnd = () => setShowClaim(true);

  const _claimDailyFreeSpin = async () => {
    setClaiming(true);
    const { ok, data }: any = await userApi.getDailyFreeSpin();
    if (ok) {
      setUser(data);
      setShowClaim(false);
      toggleSnackBar("success", "Congratulation you got your daily free spin!");
    } else {
      toggleSnackBar("error", handleApiError(data));
    }
    setClaiming(false);
  };

  return (
    <Modal
      open={open}
      size={SIZES.MEDIUM}
      title="Free Spin"
      boxStyleProps={{
        style: {
          background: `url("${freeSpinBackground}")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        },
      }}
      onClose={onClose}
    >
      <ContentWrapper>
        <Grid gap={2} container>
          <Grid xs={12} direction="column" alignItems="center" container item>
            <FreeSpinGame
              buttonProps={{ variant: "contained" }}
              data={config.freeSpinData}
              lowestRewards={worstIndexes}
            />
          </Grid>

          <Grid container item spacing={1} justifyContent="space-between">
            <Grid xs={12} sm={9} spacing={1} container item>
              <Grid xs={12} item>
                <Typography display="inline">Free Spins: </Typography>
                <GradientTypography display="inline" fontWeight="bold">
                  {user?.free_spins || 0}
                </GradientTypography>
              </Grid>
              {user?.vip_benefits?.level > 1 && (
                <Grid xs={12} item>
                  {showClaim ? (
                    <Button
                      variant="contained"
                      onClick={_claimDailyFreeSpin}
                      loading={isClaiming}
                      disabled={isClaiming}
                      filled
                    >
                      Claim your daily free spin
                    </Button>
                  ) : (
                    <Box display="flex" alignItems="center">
                      <GradientTypography mr={1}>
                        Next claim:
                      </GradientTypography>
                      <GradientTypography fontWeight="bold">
                        <Countdown
                          fromDate={fromDate}
                          toDate={toDate}
                          onCountdownEnd={_onCountdownEnd}
                        />
                      </GradientTypography>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid xs={12} sm={3} alignItems="flex-end" container item>
              <Button onClick={_onGetMoreSpin} filled fullWidth>
                Get more spin
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ContentWrapper>
    </Modal>
  );
}

export default FreeSpin;
