import Balance from "../../Components/Common/Balance";
import { GAME_MAPPER } from "../../Constants/game";

export const columns = [
  {
    name: "payout",
    label: "Payout",
    customBodyRender: (data: any) => {
      const isWin = data >= 0;
      return <Balance value={isWin ? data : 0} normal />;
    },
  },
  {
    name: "bet_amount",
    label: "Bet",
    customBodyRender: (data: any) => <Balance value={data} normal />,
  },
  {
    name: "game_code",
    label: "Game",
    customBodyRender: (data: keyof typeof GAME_MAPPER) => {
      return GAME_MAPPER[data]?.name || data;
    },
  },
  { name: "bet_id", label: "Bet ID" },
];
