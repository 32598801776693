import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

interface Star {
  size: number;
  x: number;
  y: number;
}

const generateRandomStars = (
  containerWidth: number,
  containerHeight: number,
  starCount: number
): Star[] => {
  const stars: Star[] = [];

  for (let i = 0; i < starCount; i++) {
    const size = Math.random() * 3 + 1; // Random size between 1 and 4 pixels
    const x = Math.random() * containerWidth; // Random X position within the container
    const y = Math.random() * containerHeight; // Random Y position within the container

    stars.push({ size, x, y });
  }

  return stars;
};

interface StarsProps {
  containerRef: React.RefObject<HTMLDivElement>;
}

const Stars: React.FC<StarsProps> = ({ containerRef }) => {
  const [stars, setStars] = useState<Star[]>([]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const { width, height } = containerRect;

    const starCount = Math.floor((width * height) / 5000); // Adjust density as needed

    const generatedStars = generateRandomStars(width, height, starCount);
    setStars(generatedStars);
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {stars.map((star, index) => (
        <motion.div
          key={index}
          style={{
            position: "absolute",
            top: star.y,
            left: star.x,
            width: star.size,
            height: star.size,
            borderRadius: "50%",
            background: "white",
          }}
          animate={{
            opacity: [1, 0, 1], // Keyframes for opacity animation
          }}
          transition={{
            duration: Math.random() * 2 + 0.5, // Random duration between 0.5 and 2.5 seconds
            repeat: Infinity, // Repeat the animation indefinitely
            repeatType: "mirror", // Reverse the animation after each iteration
            delay: star.size * 0.5, // Delay the start of each star's animation based on its size
          }}
        />
      ))}
    </div>
  );
};

export default Stars;
