import { Grid } from "@mui/material";

import useUserSelector from "../../../../Redux/user/useSelector";
import { useConfigSelector } from "../../../../Redux/utility/useSelector";

import Divider from "../../../Common/Divider";
import GradientDivider from "../../../Common/GradientDivider";
import Modal, { ContentWrapper, SIZES } from "../../../Common/Modal";

import ProfileHeader from "./ProfileHeader";
import ProfileStatistics from "./ProfileStatistics";

interface ProfileDetailsProps {
  open: boolean;
  onClose: () => void;
}

const ProfileDetails = ({ open, onClose }: ProfileDetailsProps) => {
  const { user } = useUserSelector();
  const config = useConfigSelector();

  return (
    <Modal
      open={open}
      size={SIZES.SMALL}
      onClose={onClose}
      title="User Profile"
    >
      <ContentWrapper>
        <Grid spacing={2} container>
          <Grid xs={12} item>
            <ProfileHeader
              customImage={config?.siteAssets?.user_profile_image}
              email={user?.email}
              level={user?.vip_benefits?.level}
              nextProgress={user?.vip_benefits?.nextProgress}
              recoverEmail={user?.recovery_email}
              username={user?.username}
            />
          </Grid>

          <Grid xs={12} item>
            <GradientDivider />
          </Grid>

          <Grid xs={12} item>
            <ProfileStatistics data={user?.statistics} />
          </Grid>
        </Grid>
      </ContentWrapper>
    </Modal>
  );
};

export default ProfileDetails;
