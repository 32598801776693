import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";

import affiliatesIcon from "../../Assets/icons/affiliates.png";
import vipIcon from "../../Assets/icons/vip-icon.png";
import telegramIcon from "../../Assets/icons/telegram-icon.png";
import supportIcon from "../../Assets/icons/support-icon.png";

import routes from "../../Routes/Constants";
import { GAME_CODES, GAME_MAPPER } from "../../Constants/game";
import { customTheme } from "../../Utilities/theme";

import { createCountryOptionLabelRender } from "./utils";

declare var Tawk_API: any;

export const countryOptions = [
  {
    label: createCountryOptionLabelRender("Phillipines", <EmojiFlagsIcon />),
    value: "Phillipines",
  },
  {
    label: createCountryOptionLabelRender("Brazil", <EmojiFlagsIcon />),
    value: "Brazil",
  },
];

const gamesWithoutFreeSpin = Object.keys(GAME_CODES).filter(
  (e) => GAME_CODES[e as keyof typeof GAME_CODES] !== GAME_CODES.FREE_SPIN
);
export const quickAccessGames = gamesWithoutFreeSpin.map((gc) => ({
  label: GAME_MAPPER[GAME_CODES[gc as keyof typeof GAME_CODES]].name,
  image: GAME_MAPPER[GAME_CODES[gc as keyof typeof GAME_CODES]].sideBarImage,
  route: GAME_MAPPER[GAME_CODES[gc as keyof typeof GAME_CODES]].link,
  gameCode: GAME_CODES[gc as keyof typeof GAME_CODES],
}));

export const NavIcon = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <img
      style={{
        color: customTheme.primaryGradientColor,
      }}
      src={src}
      alt={alt}
    />
  );
};

export const menu = (telegramLink: string) => [
  {
    label: "Affiliates",
    pathname: routes.AFFILIATE,
    icon: () => <NavIcon src={affiliatesIcon} alt="affiliates" />,
  },
  {
    label: "VIP RANK SYSTEM",
    pathname: routes.VIP_RANKING,
    icon: () => <NavIcon src={vipIcon} alt="vip-icon" />,
  },
  {
    label: "Telegram",
    icon: () => <NavIcon src={telegramIcon} alt="telegram-icon" />,
    callback: () => window.open(telegramLink, "_blank"),
  },
  {
    label: "Support",
    icon: () => <NavIcon src={supportIcon} alt="support-icon" />,
    callback: () => Tawk_API.maximize(),
  },
];
export const IOS_SHARE_INSTRUCTIONS =
  "This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.";
export const IOS_SHARE_STEP_1 =
  "1) Press the 'Share' button on the menu bar below.";
export const IOS_SHARE_STEP_2 = "2) Press 'Add to Home Screen'";
