const routes = {
  HOME: "/",
  ALL_GAMES: "all-games",
  AFFILIATE: "affiliates",
  VIP_RANKING: "vip-ranking",
  SUPPORT: "support",
  TRANSACTION_HISTORY: "transaction-history",
  GAME_HISTORY: "game-history",
  BLOG: "blog",
  GAME: {
    HEADS_OR_TAILS: "top-or-bottom",
    LIMBO: "rocket",
    MINES: "bomba",
    WHEEL_OF_FORTUNE: "spot-my-g",
    PLINKO: "plinko",
    THIRD_PARTY_GAME_PLAYER: "play",
  },
};

export default routes;
