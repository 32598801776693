import { Typography } from "@mui/material";

import Balance from "../Components/Common/Balance";

import Circle from "../Assets/Images/2.png";
import routes from "../Routes/Constants";

import wofThumbnail from "../Assets/Images/spot-my-g.jpg";
import coinFlipThumbnail from "../Assets/Images/coinflip-thumbnail.jpg";
import mineThumbnail from "../Assets/Images/Mine-Games-Card.jpg";
import limboThumbnail from "../Assets/Images/pet-mo-may-rocket.jpg";
import spotMyG from "../Assets/banners/spot-my-g.jpg";
import coinFlipSidebar from "../Assets/banners/coinflip-thumbnail.jpg";
import bomba from "../Assets/banners/Mine-Games-Card.jpg";
import petMoMayRocket from "../Assets/banners/pet-mo-may-rocket.jpg";

export const MIN_BET = 1;

export const COLUMNS = {
  result: {
    name: "result",
    label: "Result",
    customBodyRender: (data: any, otherFields: any) => {
      const isWin = otherFields.payout > 0;
      return (
        <Typography color={isWin ? "green" : "error"} fontWeight={700}>
          {isWin ? "Win" : "Lose"}
        </Typography>
      );
    },
  },
  payout: {
    name: "payout",
    label: "Payout",
    customBodyRender: (data: number) => {
      const isWin = data >= 0;
      return <Balance value={isWin ? data : 0} normal />;
    },
  },
  amount: {
    name: "bet_amount",
    label: "Bet",
    customBodyRender: (amount: number) => <Balance value={amount} normal />,
  },
  multiplier: {
    name: "multiplier",
    label: "Multiplier",
  },
};

export const GAME_CODES = {
  FREE_SPIN: "free_spin",
  LIMBO: "limbo",
  HEADS_OR_TAILS: "heads_or_tails",
  MINES: "mines",
  WHEEL_OF_FORTUNE: "wheel_of_fortune",
  // PLINKO: "plinko",
};

export const GAME_MAPPER = {
  [GAME_CODES.FREE_SPIN]: {
    name: "Free Spin",
    image: Circle,
    link: "", // not applicable
  },
  [GAME_CODES.HEADS_OR_TAILS]: {
    name: "Top or Bottom",
    image: coinFlipThumbnail,
    sideBarImage: coinFlipSidebar,
    link: routes.GAME.HEADS_OR_TAILS,
  },
  [GAME_CODES.LIMBO]: {
    name: "Rocket",
    image: limboThumbnail,
    sideBarImage: petMoMayRocket,
    link: routes.GAME.LIMBO,
  },
  [GAME_CODES.MINES]: {
    name: "Bomba",
    image: mineThumbnail,
    sideBarImage: bomba,
    link: routes.GAME.MINES,
  },
  [GAME_CODES.WHEEL_OF_FORTUNE]: {
    name: "Spot My G",
    image: wofThumbnail,
    sideBarImage: spotMyG,
    link: routes.GAME.WHEEL_OF_FORTUNE,
  },
  // [GAME_CODES.PLINKO]: {
  //   name: "Plinko",
  //   image: Circle,
  //   sideBarImage:
  //     "https://kubi.rescdns.com/static/cdnimg/v4/2A99C0B21B97B7DC02529251B425BDD9",
  //   link: routes.GAME.PLINKO,
  // },
};
