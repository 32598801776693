import { useRef, useState } from "react";
import { Grid, styled, Typography } from "@mui/material";

import Button from "../../../Components/Common/Button";
import GameBet from "../../../Components/Games/Bet";
import GameContainer, { GCRef } from "../../../Components/Games/Container";
import { GAME_CODES } from "../../../Constants/game";
import useBetController from "../../../Hooks/useBetController";
import { useConfigSelector } from "../../../Redux/utility/useSelector";
import socket from "../../../Utilities/Websocket";
import themeColors from "../../../Utilities/colors";

import { BettingContainer } from "../styles";

import { columns } from "./data";
import HeadsOrTailsGame, { HeadsOrTailsRef } from "./Game";

function HeadsOrTails() {
  const gameContainerRef = useRef<GCRef | null>(null);
  const headsOrTailsRef = useRef<HeadsOrTailsRef | null>(null);
  const config = useConfigSelector();

  const [betState, betHandler] = useBetController();

  const [fliping, setFlipping] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const _placeBet = (headOrTails: number) => () => {
    const isOk = betHandler.validate();
    if (isOk) {
      setFlipping(true);
      setSubmitting(true);
      headsOrTailsRef.current!.flip({
        bet_amount: betState.bet,
        betted_on: headOrTails,
      });
    }
  };

  const _onDone = () => {
    socket.emit(config.webSocketEvents.UPDATE_USER_BALANCE); // update user details
    gameContainerRef.current?.refreshHistory(); // refetch game history
    setFlipping(false);
  };

  const _onError = () => setFlipping(false);

  const _onRequestDone = () => setSubmitting(false);

  return (
    <GameContainer
      ref={gameContainerRef}
      gameCode={GAME_CODES.HEADS_OR_TAILS}
      tableColumn={columns}
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={8} item>
          <HeadsOrTailsGame
            ref={headsOrTailsRef}
            onDone={_onDone}
            onError={_onError}
            onRequestDone={_onRequestDone}
          />
        </Grid>
        <Grid xs={12} sm={4} item>
          <BettingContainer>
            <Grid container pl={2} pr={2} pb={2} spacing={2}>
              <GameBet
                bets={[20, 50, 100, 500]}
                containerProps={{ item: true, spacing: 2 }}
                disabled={fliping}
                itemProps={{ xs: 3, sm: 6 }}
                value={betState.bet}
                onChange={betHandler.onBetChange}
              />
              <Grid xs={12} item>
                <BetButton
                  style={{ backgroundColor: themeColors.blue }}
                  onClick={_placeBet(0)}
                  loading={submitting}
                  disabled={fliping}
                  fullWidth
                >
                  <Typography>Top</Typography>
                  <Typography>(2x)</Typography>
                </BetButton>
              </Grid>

              <Grid xs={12} item>
                <BetButton
                  style={{ backgroundColor: themeColors.red }}
                  onClick={_placeBet(1)}
                  loading={submitting}
                  disabled={fliping}
                  fullWidth
                >
                  <Typography>Bottom</Typography>
                  <Typography>(2x)</Typography>
                </BetButton>
              </Grid>
            </Grid>
          </BettingContainer>
        </Grid>
      </Grid>
    </GameContainer>
  );
}

const BetButton = styled(Button)({
  display: "flex",
  justifyContent: "space-between",
});

export default HeadsOrTails;
