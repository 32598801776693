import { useDispatch } from "react-redux";

import { LOGIN_SIGNUP_FORMS } from "./constants";
import {
  hideSnackBar,
  setConfig,
  setLoginSignupModalForm,
  showSnackBar,
  toggleWalletModal,
} from "./";

function useUtilityAction() {
  const dispatch = useDispatch();

  const _setLoginSignupForm = (form: LOGIN_SIGNUP_FORMS) => {
    return dispatch(setLoginSignupModalForm(form));
  };

  const _setConfig = (data: object) => dispatch(setConfig(data));

  const _toggleSnackBar = (type?: string, message?: string) => {
    if (type || message) {
      dispatch(showSnackBar({ type, message }));
    } else {
      dispatch(hideSnackBar());
    }
  };

  const _toggleWallet = (intended?: any) =>
    dispatch(toggleWalletModal(intended));

  return {
    setConfig: _setConfig,
    setLoginSignupForm: _setLoginSignupForm,
    toggleSnackBar: _toggleSnackBar,
    toggleWallet: _toggleWallet,
  };
}

export * from "./constants";

export default useUtilityAction;
