import { CSSProperties } from "react";
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import lodashDebounce from "lodash/debounce";
import { customTheme } from "../../Utilities/theme";
import GradientTypography from "./GradientTypography";

const CustomButton = styled(MuiButton)<{
  filled?: boolean;
  defaultOutlineBorder?: boolean;
}>(({ filled, defaultOutlineBorder }) => ({
  color: filled ? "black" : "",
  borderRadius: "8px",
  padding: "10px 14px",
  fontSize: "14px",
  height: defaultOutlineBorder ? "" : "40px",
  textTransform: "capitalize",
  background: filled ? customTheme.primaryGradientColor : "",
  border: defaultOutlineBorder
    ? "0.5px solid " + customTheme.basicBorderColor
    : "0.5px solid transparent",
  ...(!filled && {
    ":hover": {
      border: "0.5px solid " + customTheme.basicBorderColor,
      borderRadius: "10px",
      background: customTheme.hoverBg,
    },
  }),
}));

type LocalProps = {
  addDebounce?: boolean;
  loading?: boolean;
  filled?: boolean;
  defaultOutlineBorder?: boolean;
};

const Button = ({
  addDebounce,
  children,
  disabled,
  loading,
  style,
  onClick,
  variant,
  filled,
  defaultOutlineBorder,
  ...props
}: LocalProps & ButtonProps) => {
  const _onClick = lodashDebounce((args) => onClick?.(args), 500);

  const disabledStyle = disabled ? { pointerEvents: "none", opacity: 0.4 } : {};

  return (
    <CustomButton
      defaultOutlineBorder={defaultOutlineBorder}
      filled={filled}
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : null
      }
      onClick={addDebounce ? _onClick : onClick}
      style={
        {
          ...style,
          ...disabledStyle,
        } as CSSProperties
      }
      {...props}
    >
      {defaultOutlineBorder ? (
        <GradientTypography fontSize={12} fontWeight="bold">
          {children}
        </GradientTypography>
      ) : (
        children
      )}
    </CustomButton>
  );
};

export default Button;
