import { useSelector } from "react-redux";

import { State } from "./index";

function useUtilitySelector() {
  const result = useSelector((state: State) => ({
    activeAuthForm: state.utility.activeAuthForm,
    showWalletModal: state.utility.showWalletModal,
    snackBar: state.utility.snackBar,
    config: state.utility.config,
  }));
  return result;
}

// use this if want only some specific data on utility redux
export function useActiveAuthFormSelector() {
  return useSelector((state: State) => state.utility.activeAuthForm);
}
export function useShowWalletModalSelector() {
  return useSelector((state: State) => state.utility.showWalletModal);
}
export function useSnackBarSelector() {
  return useSelector((state: State) => state.utility.snackBar);
}
export function useConfigSelector() {
  return useSelector((state: State) => state.utility.config);
}
export function useUserDetailsSelector() {
  return useSelector((state: State) => state.user.data);
}

// use this if you need single import for all utility redux
export default useUtilitySelector;
