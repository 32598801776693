import { Typography } from "@mui/material";
import { customTheme } from "../../Utilities/theme";

const Subtitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      sx={{
        wordBreak: "break-word",
        color: customTheme.footerFontColor,
        fontSize: "12px",
      }}
    >
      {children}
    </Typography>
  );
};

export default Subtitle;
