import useUserAction from "../Redux/user/useAction";
import { removeSession } from "../Redux/store";

import userApi from "../Service/api/user";

const useCheckSession = () => {
  const { setIsLogin, setUser } = useUserAction();

  const _checkSession = async () => {
    const { ok, data, status } = await userApi.getDetails();
    if (ok) {
      setIsLogin(true);
      setUser(data);
    } else if (status === 401) {
      setIsLogin(false);
      removeSession();
    }
  };

  return _checkSession;
};

export default useCheckSession;
