import { useRef, useState } from "react";
import { Grid } from "@mui/material";

import Button from "../../../Components/Common/Button";
import GameBet from "../../../Components/Games/Bet";
import GameContainer, { GCRef } from "../../../Components/Games/Container";
import WheelOfFortune, {
  WOFRef,
} from "../../../Components/Games/Circle/WheelOfFortune";
import { GAME_CODES } from "../../../Constants/game";
import useBetController from "../../../Hooks/useBetController";
import { useConfigSelector } from "../../../Redux/utility/useSelector";

import { columns } from "./data";
import { BettingContainer } from "../styles";

function Circle() {
  const gameContainerRef = useRef<GCRef | null>(null);
  const spinTheWheelRef = useRef<WOFRef | null>(null);
  const config = useConfigSelector();
  const [betState, betHandler] = useBetController();

  const [multiplier, setMultiplier] = useState<number>(0);
  const [isBetPlaced, setIsBetPlaced] = useState<boolean>(false);

  const { data, colors } = config.wof;

  const _onDone = () => {
    setIsBetPlaced(false);
    gameContainerRef.current?.refreshHistory(); // refetch game history
  };

  const _onMultiplierPressed = (mp: number) => {
    const isOk = betHandler.validate();
    if (isOk) {
      setMultiplier(mp);
      setIsBetPlaced(true);
      spinTheWheelRef.current!.spinStart({ bet: betState.bet, multiplier: mp }); // spin the wheel
    }
  };

  const _renderBetButton = (multiply: number) => {
    return (
      <Grid item xs={3} sm={3} md={12}>
        <Button
          variant="contained"
          style={{
            backgroundColor:
              colors[multiply.toString() as keyof typeof colors].value,
            minWidth: "unset",
          }}
          onClick={() => _onMultiplierPressed(multiply)}
          disabled={isBetPlaced}
          fullWidth
        >
          {multiply}X
        </Button>
      </Grid>
    );
  };

  return (
    <GameContainer
      ref={gameContainerRef}
      gameCode={GAME_CODES.WHEEL_OF_FORTUNE}
      tableColumn={columns(colors)}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid
          position="relative"
          justifyContent="center"
          sm={12}
          md={9}
          container
          item
        >
          <WheelOfFortune
            ref={spinTheWheelRef}
            selected={{ multiplier, bet: betState.bet }}
            data={data}
            onDone={_onDone}
          />
        </Grid>

        <Grid item sm={12} md={3}>
          <BettingContainer>
            <Grid container pl={2} pr={2} pb={3} spacing={2}>
              <GameBet
                bets={[20, 50, 100, 500]}
                containerProps={{ item: true, spacing: 2 }}
                itemProps={{ xs: 3, sm: 3, md: 6 }}
                onChange={betHandler.onBetChange}
                value={betState.bet}
                disabled={isBetPlaced}
              />
              <Grid container item spacing={2}>
                {_renderBetButton(2)}
                {_renderBetButton(3)}
                {_renderBetButton(5)}
                {_renderBetButton(30)}
              </Grid>
            </Grid>
          </BettingContainer>
        </Grid>
      </Grid>
    </GameContainer>
  );
}

export default Circle;
