import { useEffect } from "react";

import userApi from "../../../../Service/api/user";
import useObjectState from "../../../../Hooks/useObjectState";
import { useConfigSelector } from "../../../../Redux/utility/useSelector";
import { FormikValues } from "formik";
import useUtilityAction from "../../../../Redux/utility/useAction";
import socket from "../../../../Utilities/Websocket";

const fieldsEmptyValues = {
  bank: "",
  amount: "",
};
const initialState = {
  initialValues: {
    bank: "",
    amount: "",
  },
  showConfirmation: false,
  isProcessing: false,
  isCheckingSession: true,
  isDepositComplete: false,
  depositStatus: true,
  currentRedirectUrl: "",
};

const useArxDepositController = () => {
  const [state, setState] = useObjectState(initialState);
  const { toggleSnackBar, toggleWallet } = useUtilityAction();

  const { webSocketEvents, webHookStatuses }: any = useConfigSelector();

  const _closePaymentProcessingModal = () => {
    setState({
      initialValues: fieldsEmptyValues,
      isProcessing: false,
      isDepositComplete: false,
      isCheckingSession: false,
    });
  };
  const _checkForDepositSession = async () => {
    const { ok, data }: any = await userApi.checkActiveUserDepositSession();

    if (ok) {
      setState({
        initialValues: {
          bank: data.method,
          amount: data.amount,
        },
        isCheckingSession: false,
        isProcessing: true,
        currentRedirectUrl: data.redirect_url,
      });
    } else {
      setState({
        isCheckingSession: false,
      });
    }
  };

  const _onDeposit = async (formData: FormikValues) => {
    const payload = {
      // method: "Gcash",
      amount: formData.amount,
    };

    const { ok: createSessionOk, data: createSessionData }: any =
      await userApi.createDepositSession(payload);

    if (!createSessionOk) {
      return toggleSnackBar("error", createSessionData?.message || null);
    }

    setState({
      isProcessing: true,
      currentRedirectUrl: createSessionData.redirectUrl,
    });
  };

  const _handleDepositStatus = (status: string) => {
    if (status === webHookStatuses.APPROVED) {
      setState({
        depositStatus: true,
        isDepositComplete: true,
      });
      toggleSnackBar("success", "Deposit Successfully");

      setTimeout(() => {
        toggleWallet(false);
      }, 5000);
    } else if (
      status === webHookStatuses.FAILED ||
      status === webHookStatuses.REJECTED ||
      status === webHookStatuses.ERROR ||
      status === webHookStatuses.TRANSACION_ALREADY_MADE
    ) {
      setState({
        depositStatus: false,
        isDepositComplete: true,
      });

      toggleSnackBar("error", "Deposit " + status);

      setTimeout(() => {
        setState({
          isProcessing: false,
        });

        toggleWallet(false);
      }, 5000);
    }

    setState({
      currentRedirectUrl: "",
    });
  };

  useEffect(() => {
    _checkForDepositSession();

    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (state.currentRedirectUrl) {
      window.open(state.currentRedirectUrl);
    }
  }, [state.currentRedirectUrl]);
  useEffect(() => {
    socket.on(webSocketEvents.DEPOSIT_REQUEST_STATUS, _handleDepositStatus);

    return () => {
      socket.off(webSocketEvents.DEPOSIT_REQUEST_STATUS, _handleDepositStatus);
    };
    //eslint-disable-next-line
  }, []);

  const handlers = {
    onDeposit: _onDeposit,
    closePaymentProcessingModal: _closePaymentProcessingModal,
  };

  return [state, handlers];
};

export default useArxDepositController;
