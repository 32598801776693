import { Grid, styled, Avatar, Chip, Typography, Box } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import profileIcon from "../../../../Assets/icons/profile-icon.png";
import diamondIcon from "../../../../Assets/icons/diamond.png";
import userIcon from "../../../../Assets/icons/user.png";
import userWithStarIcon from "../../../../Assets/icons/user-with-star.png";
import { customTheme } from "../../../../Utilities/theme";

import GradientTypography from "../../../Common/GradientTypography";

import { Icon } from "./styles";

type LocalProps = {
  customImage?: string;
  email: string; // email or phone
  level: number;
  nextProgress: number;
  recoverEmail?: string; // email or phone
  username: string;
};

const ProfileHeader = ({
  customImage,
  email,
  level,
  nextProgress,
  recoverEmail,
  username,
}: LocalProps) => {
  return (
    <Grid justifyContent="center" spacing={2} container>
      <Grid item>
        <Grid item justifyContent="center" container mb={1}>
          <Avatar
            alt="profile-photo"
            src={customImage || profileIcon}
            sx={{ width: 80, height: 80 }}
          />
        </Grid>

        <Grid item>
          <Chip
            label={
              <GradientTypography fontWeight="bold">
                {username}
              </GradientTypography>
            }
            style={{ border: `1px solid ${customTheme.basicBorderColor}` }}
            size="small"
          />
        </Grid>
      </Grid>

      <Grid xs={true} justifyContent="space-between" spacing={1} container item>
        <Grid container spacing={1} item>
          <Grid xs={12} container item>
            <Icon src={diamondIcon} />
            <GradientTypography mr={1}>VIP Rank Level: </GradientTypography>
            <Typography>{level}</Typography>
          </Grid>

          <Grid xs={12} item>
            <Box display="flex" alignItems="center">
              <Icon src={userWithStarIcon} />
              <GradientTypography mr={1}>EXP</GradientTypography>
              <Box flex={1} mr={1}>
                <BorderLinearProgress
                  variant="determinate"
                  value={nextProgress}
                />
              </Box>
              <Typography>{nextProgress}%</Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid spacing={1} container item>
          <Grid container item>
            <Icon src={userIcon} />
            <GradientTypography variant="body2" fontWeight={700} mr={1}>
              Login:
            </GradientTypography>
            <Typography variant="body2">{email}</Typography>
          </Grid>

          {!!recoverEmail && (
            <Grid container item>
              <Icon src={userIcon} />
              <GradientTypography variant="body2" fontWeight={700} mr={1}>
                Recovery Email:
              </GradientTypography>
              <Typography variant="body2">{recoverEmail}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const BorderLinearProgress = styled(LinearProgress)({
  height: 15,
  width: "100%",
  borderRadius: 15,
  backgroundColor: "transparent",
  border: `0.5px solid ${customTheme.basicBorderColor}`,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
    background: customTheme.primaryGradientColor,
  },
});

ProfileHeader.defaultProps = {
  level: 0,
  nextProgress: 0,
  username: "",
};

export { BorderLinearProgress };
export default ProfileHeader;
