import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import casinoReducer from "./Casino";
import noSessionReducer from "./noSession";
import userReducer from "./user";
import utilityReducer from "./utility";

const rootConfig = {
  key: "userRoot",
  storage,
};

const noSessionConfig = {
  key: "userNoSession",
  storage,
};

const persistedNoSession = persistReducer(noSessionConfig, noSessionReducer);
const persistedUser = persistReducer(rootConfig, userReducer);

export { noSessionConfig, rootConfig };

export default combineReducers({
  noSession: persistedNoSession,
  user: persistedUser,
  utility: utilityReducer,
  casino: casinoReducer,
});
