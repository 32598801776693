import {
  Gamepad,
  House,
  Microwave,
  Stream,
  Phishing,
  Casino,
  SportsEsports,
  MonetizationOn,
} from "@mui/icons-material";

import jiliProviderLogo from "../../Assets/Logos/jili-provider-logo.png";
import faChaiLogo from "../../Assets/Logos/fc-logo.png";

import { ENABLE_INHOUSE_GAMES } from "../../Constants";
import { GAME_CODES, GAME_MAPPER } from "../../Constants/game";

const gamesWithoutFreeSpin = Object.keys(GAME_CODES).filter(
  (e) => GAME_CODES[e as keyof typeof GAME_CODES] !== GAME_CODES.FREE_SPIN
);

export const pageVariants = {
  initial: {
    opacity: 0,
    x: "-100%",
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: "100%",
  },
};
export const providersList = [
  { id: "jili", icon: jiliProviderLogo },
  { id: "fa-chai", icon: faChaiLogo },
];
export const inhouseGames = ENABLE_INHOUSE_GAMES
  ? gamesWithoutFreeSpin.map((gc: any) => ({
      image: GAME_MAPPER[GAME_CODES[gc as keyof typeof GAME_CODES]].image,
      route: GAME_MAPPER[GAME_CODES[gc as keyof typeof GAME_CODES]].link,
      name: GAME_MAPPER[GAME_CODES[gc as keyof typeof GAME_CODES]].name,
      isInhouseGame: true,
      gameCode: GAME_CODES[gc as keyof typeof GAME_CODES],
    }))
  : [];

export const slotGames = [];
export const liveGames = [];
export const fishingGames = [];
export const gameFilters = [
  {
    label: "All Games",
    icon: Gamepad,
    count: [...inhouseGames, ...slotGames, ...liveGames, ...fishingGames]
      .length,
  },
  { label: "In-House", icon: House, count: inhouseGames.length },
  { label: "Slots", icon: Microwave, count: slotGames.length },
  { label: "Live", icon: Stream, count: liveGames.length },
  { label: "Fishing", icon: Phishing, count: fishingGames.length },
  { label: "Casino", icon: Casino, count: fishingGames.length },
  { label: "Lobby", icon: SportsEsports, count: fishingGames.length },
  { label: "Poker", icon: MonetizationOn, count: fishingGames.length },
];
