import { createTheme } from "@mui/material/styles";
import customOverrides from "./Overrides/customOverrides";
import typography from "./Overrides/typography";
import breakpoints from "./Overrides/breakpoints";

export const customTheme = {
  primaryGradientColor:
    "linear-gradient(253.36deg, #8C6A28 0%, #FFEBA8 48.94%, #94681D 100%)",
  backgroundShadowGradient:
    "linear-gradient(0deg, rgba(32, 32, 32, 0.8), rgba(32, 32, 32, 0.8))",
  basicBorderColor: "#FFEBA8",
  hoverBg: "#484848",
  cardBgColor: "#535353",
  cardDarkBg: "#272727",
  modalCardBg: "#151515",
  footerFontColor: "#7f7f7f",
  transparentBg: "rgba(255, 255, 255, 0.05)",
  tablePrimaryTextColor: "#BCBCBC",
  transparentBgForBlurred: "#FFFFFF0D",
  cardContentBackground: "#FFFFFF1A",
  darkNavBackground: "#171717",
  appbarBackground: '#2A412F8F'
};

const theme = createTheme({
  palette: {
    text: {
      primary: "#fff",
    },
  },
  typography,
  breakpoints,
  ...customOverrides(customTheme),
});

export default theme;
