import { useSelector } from "react-redux";

import { State } from "./";

function useNoSessionSelector() {
  const result = useSelector((state: State) => ({
    loginInformation: state.noSession.loginInformation,
  }));

  return result;
}

export default useNoSessionSelector;
