import { useEffect, useState } from "react";
import socket from "../../Utilities/Websocket";

import { useConfigSelector } from "../../Redux/utility/useSelector";
import listing from "../../Service/api/listing";

function useLiveBetsController(): any {
  const config = useConfigSelector();

  const [liveBets, setLiveBets] = useState([]);

  useEffect(() => {
    _getLiveBets();
    socket.on(config.webSocketEvents.BIGGEST_WIN, _getLiveBets);
    return () => {
      socket.off(config.webSocketEvents.BIGGEST_WIN);
    };
    //eslint-disable-next-line
  }, []);

  const _getLiveBets = async () => {
    const { ok, data }: any = await listing.getBiggestWins();
    if (ok) {
      setLiveBets(data);
    }
  };

  const state: any = { liveBets };
  const handler = {};

  return [state, handler];
}

export default useLiveBetsController;
