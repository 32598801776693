import { Fragment, useState, useEffect } from "react";
import { Box, ButtonProps } from "@mui/material";
import { Wheel } from "react-custom-roulette";

import MobileLogo from "../../../Assets/Logos/mobile-logo.png";
import { commafyNumber } from "../../../Helpers";
import useUserAction from "../../../Redux/user/useAction";
import useUtilityAction from "../../../Redux/utility/useAction";
import { useConfigSelector } from "../../../Redux/utility/useSelector";
import userApi from "../../../Service/api/user";
import socket from "../../../Utilities/Websocket";

import Button from "../../Common/Button";

import { Container, Overlay, Wrapper, ImageContainer } from "./Constants";
import styled from "@emotion/styled";

type LocalProps = {
  buttonProps: ButtonProps;
  data: Array<any>;
  lowestRewards: Array<number>;
};

let emitOnUnmount = false;
const FreeSpin = (props: LocalProps) => {
  const { reduceFreeSpin } = useUserAction();
  const { toggleSnackBar } = useUtilityAction();
  const config = useConfigSelector();

  const [resultIndex, setResultIndex] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const _onStartSpin = async () => {
    if (!isSpinning) {
      emitOnUnmount = true; // mark as true bcs when user close the free spin, pull user data
      reduceFreeSpin();
      setIsLoading(true);
      const { ok, data }: any = await userApi.freeSpin();
      if (ok) {
        setResultIndex(data.data);
        setIsSpinning(true);
      }
      setIsLoading(false);
    }
  };

  const _onStopSpinning = async () => {
    setTimeout(() => {
      setIsSpinning(false);
      if (props.lowestRewards.includes(resultIndex)) {
        toggleSnackBar("error", "Better luck next time");
      } else {
        emitOnUnmount = false; // mark as false bcs we dont need to pull user on unmount we already did here
        const win = props.data[resultIndex].option;
        socket.emit(config.webSocketEvents.FREE_SPIN);
        toggleSnackBar(
          "success",
          `You won PHP ${commafyNumber(Number(win), true)}`
        );
      }
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (emitOnUnmount) {
        socket.emit(config.webSocketEvents.FREE_SPIN);
      }
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Wrapper style={{ padding: 20, margin: "0 -20px" }}>
        <Overlay />
        <Container>
          <Wheel
            data={props.data}
            innerRadius={20}
            mustStartSpinning={isSpinning}
            onStopSpinning={_onStopSpinning}
            outerBorderWidth={0}
            prizeNumber={resultIndex}
            radiusLineWidth={1}
            pointerProps={{ style: { display: "none" } }}
          />
        </Container>

      </Wrapper>

      <Box width="100%" maxWidth="400px">
        <Button
          {...props.buttonProps}
          onClick={_onStartSpin}
          disabled={isSpinning}
          loading={isLoading}
          addDebounce
          filled
          fullWidth
        >
          Spin
        </Button>
      </Box>
    </Fragment>
  );
};

const StyledImageContainer = styled(ImageContainer)`
  background: url(${MobileLogo});  
`

export default FreeSpin;
