import { FormikValues } from "formik";
import { useEffect } from "react";

import { handleApiError } from "../../../../Helpers";
import useObjectState from "../../../../Hooks/useObjectState";
import useUtilityAction from "../../../../Redux/utility/useAction";
import {
  useConfigSelector,
  useShowWalletModalSelector,
} from "../../../../Redux/utility/useSelector";
import userApi from "../../../../Service/api/user";
import socket from "../../../../Utilities/Websocket";

const initialState = {
  isProcessing: false,
  activePaymentUrl: "",
  isDepositComplete: false,
  depositStatus: false,
  depositTimestamp: null,
};

const useGlobalTrustPayController = (gateway: string) => {
  const config = useConfigSelector();
  const websocketEvents = config?.webSocketEvents;
  const [state, setState] = useObjectState(initialState);
  const { toggleSnackBar, toggleWallet } = useUtilityAction();
  const walletState = useShowWalletModalSelector();

  const handleActiveSession = (paymentUrl: string, timestamp: Date) => {
    setState({
      isProcessing: true,
      activePaymentUrl: paymentUrl,
      depositTimestamp: timestamp,
    });
    window.open(paymentUrl, "_blank");
  };

  const handleMockActiveSession = () =>
    setState({
      isProcessing: false,
    });

  const _onDeposit = async (formData: FormikValues) => {
    const { ok, data }: any = await userApi.depositV3({
      amount: formData.amount,
      gateway,
    });
    if (ok) {
      handleActiveSession(data.payment_url, data.countdownTimestamp);
    } else {
      toggleSnackBar("error", handleApiError(data));
    }
  };

  const _mockDeposit = async (formData: FormikValues) => {
    const { ok, data }: any = await userApi.mockDeposit({
      amount: formData.amount,
      gateway,
    });
    if (ok) {
      handleMockActiveSession();
    } else {
      toggleSnackBar("error", handleApiError(data));
    }
  };

  const _onCloseModal = async () => {
    setState({ isProcessing: false });
    toggleWallet(false);
  };

  const handleDepositStatus = (status: string) => {
    if (status === config.depositV3PaymentStatuses.SUCCESS && walletState) {
      setState({
        depositStatus: true,
        isDepositComplete: true,
      });

      setTimeout(() => {
        setState({ isProcessing: false });
        toggleWallet(false);
      }, 5000);
    }
    if (status === config.depositV3PaymentStatuses.FAILED && walletState) {
      setState({
        depositStatus: false,
        isDepositComplete: true,
      });

      setTimeout(() => {
        setState({ isProcessing: false });
        toggleWallet(false);
      }, 5000);
    }
  };
  useEffect(() => {
    // _checkForDepositSession();

    //initialize socket listeners
    socket.on(
      websocketEvents.GLOBAL_TRUST_PAY_DEPOSIT_STATUS,
      handleDepositStatus
    );
    return () => {
      socket.off(
        websocketEvents.GLOBAL_TRUST_PAY_DEPOSIT_STATUS,
        handleDepositStatus
      );
    };
    //eslint-disable-next-line
  }, []);

  const handlers = {
    onDeposit: _onDeposit,
    closeModal: _onCloseModal,
    mockDeposit: _mockDeposit,
  };

  return [state, handlers];
};

export default useGlobalTrustPayController;
