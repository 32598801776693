import { Fragment } from "react";
import { useFormikContext } from "formik";

import Checkbox from "../Common/Checkbox";

import { FormikContext, NameRequiredAndRestProps } from "./Constants";
import ErrorMessage from "./ErrorMessage";

function FormCheckbox({ name, ...rest }: NameRequiredAndRestProps) {
  // prettier-ignore
  const { errors, touched, values, setFieldTouched, setFieldValue } = useFormikContext<FormikContext>();

  return (
    <Fragment>
      <Checkbox
        checked={values[name]}
        onClick={() => {
          setFieldTouched(name);
          setFieldValue(name, !values[name]);
        }}
        {...rest}
      />

      <ErrorMessage error={touched[name] ? errors[name] : ""} noMt />
    </Fragment>
  );
}

export default FormCheckbox;
