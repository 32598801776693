import { Typography, styled } from "@mui/material";
import { customTheme } from "../../Utilities/theme";

const GradientTypography = styled(Typography)(() => ({
  background: customTheme.primaryGradientColor,
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
}));

export default GradientTypography;
