import { Fragment, useEffect, useState } from "react";
import { Grid, GridProps, Typography, styled } from "@mui/material";
import lodashIsUndefined from "lodash/isUndefined";

import { numberOnly } from "../../Helpers";

import Button from "../Common/Button";
import TextField from "../Common/TextField";

type Props = {
  bets: [number, number, number, number];
  containerProps?: GridProps;
  disabled?: boolean;
  itemProps?: GridProps;
  value?: number;
  onChange: (args: any) => void;
};

const GameBet = ({
  bets,
  containerProps,
  disabled,
  itemProps,
  value,
  onChange,
}: Props) => {
  const [bet, setBet] = useState<number>();
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const isBetNotOnBets = !bets.includes(Number(bet));

  // prettier-ignore
  const mobileGapOrSpacing = containerProps?.spacing || containerProps?.gap || 2

  // this hooks is for setting the default bet
  useEffect(() => {
    // if value is not undefined and bet is not equal to value
    //eslint-disable-next-line
    if (!lodashIsUndefined(value) && bet != Number(value)) {
      setBet(Number(value));
    }
    //eslint-disable-next-line
  }, [value]);

  // this hooks is for calling onChange callback when bet value change
  useEffect(() => {
    onChange(bet);
    //eslint-disable-next-line
  }, [bet]);

  // this hooks is for handling the removal of selection of quick bet
  useEffect(() => {
    if (bet) {
      // prettier-ignore
      const selectedValueOnBets = lodashIsUndefined(selectedIndex) ? undefined : bets[selectedIndex];
      if (isBetNotOnBets) {
        setSelectedIndex(undefined);
        // eslint-disable-next-line
      } else if (bet != selectedValueOnBets) {
        setSelectedIndex(undefined);
      }
    }
    //eslint-disable-next-line
  }, [bets, isBetNotOnBets]);

  const _onSelect = (index: number, bet: number) => () => {
    setSelectedIndex(index);
    setBet(bet);
  };

  const _renderButton = (bet: number, index: number) => (
    <Grid key={index} item {...itemProps}>
      <Button
        variant={selectedIndex === index ? "contained" : "outlined"}
        onClick={_onSelect(index, bet)}
        disabled={disabled}
        style={{ minWidth: "unset" }}
        fullWidth
      >
        {bet}
      </Button>
    </Grid>
  );

  const _renderInput = (props?: any) => (
    <TextField
      value={bet === undefined ? "" : bet}
      onChange={numberOnly(setBet)}
      disabled={disabled}
      fullWidth
      {...props}
    />
  );

  return (
    <Fragment>
      {/* For mobile or small screen */}
      <GridMobile container {...containerProps}>
        <Grid xs={3} item container direction="column" gap={mobileGapOrSpacing}>
          {bets.slice(0, 2).map(_renderButton)}
        </Grid>
        <Grid xs={6} item container direction="column" justifyContent="center">
          <Typography variant="body2" align="center" mb={mobileGapOrSpacing}>
            Place your bet below
          </Typography>
          {_renderInput()}
        </Grid>
        <Grid xs={3} item container direction="column" gap={mobileGapOrSpacing}>
          {bets
            .slice(2, 4)
            .map((data, index) => _renderButton(data, index + 2))}
        </Grid>
      </GridMobile>

      {/* For web or wide screen */}
      <GridWeb container {...containerProps}>
        <Grid xs={12} item>
          {_renderInput({
            label: "Place your bet here",
            labelColor: "#fff",
          })}
        </Grid>

        {bets.map(_renderButton)}
      </GridWeb>
    </Fragment>
  );
};

const breakpoint = 500;
const GridWeb = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down(breakpoint)]: {
    display: "none",
  },
}));
const GridMobile = styled(Grid)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down(breakpoint)]: {
    display: "flex",
  },
}));

export default GameBet;
