import { Fragment } from "react";
import { styled } from "@mui/material";

import RankSystemBenefits from "./RankSystemBenefits";
import HowDoesItWork from "./HowDoesItWork";
import Levels from "./Levels";
import About from "./About";

const VIPRanking = () => {
  return (
    <Fragment>
      <RankSystemBenefits />
      <Separator />
      <HowDoesItWork />
      <Separator />
      <Levels />
      <Separator />
      <About />
    </Fragment>
  );
};

const Separator = styled("div")({
  height: 100,
});

export default VIPRanking;
