import { styled } from "@mui/material";

import backgroundImage from "../../../Assets/Images/mines-background.png";
import mobileBackgroundImage from "../../../Assets/Images/mines-tiles-background.png";
import diamondUnselectImage from "../../../Assets/Images/mines-diamond-unselect.png";
import tileContainerUnselectImage from "../../../Assets/Images/mines-tile-container-unselect.png";
import bombImage from "../../../Assets/Images/mines-bomb.png";
import diamondImage from "../../../Assets/Images/mines-diamond.png";
import legendIndicatorImage from "../../../Assets/Images/mines-legened-indicator.png";
import Button from "../../../Components/Common/Button";

import { breakpoint } from "./data";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "auto",
  backgroundImage: `url("${backgroundImage}")`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  padding: "20px 40px",
  width: 700,
  height: "auto",
  [theme.breakpoints.down(breakpoint)]: {
    width: "auto",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "20px 10px",
    backgroundImage: `url("${mobileBackgroundImage}")`,
  },
}));

export const IndicatorMobileContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "none",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: 15,
  maxWidth: 315,
  paddingLeft: 10,
  paddingRight: 10,
  [theme.breakpoints.down(breakpoint)]: {
    display: "flex",
  },
}));

export const IndicatorWebContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexDirection: "column",
  backgroundImage: `url("${legendIndicatorImage}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  height: 85,
  width: 56,
  marginLeft: 10,
  marginRight: 10,
  paddingBottom: 22,
  [theme.breakpoints.down(breakpoint)]: {
    display: "none",
  },
}));

export const IndicatorImageWebContainer = styled("div")({
  animation: "moveUpDown 5s ease-in-out infinite",
});

export const IndicatorWebImage = styled("img")(
  ({ glow }: { glow: boolean }) => ({
    ...(glow && { filter: "drop-shadow(0 4.9958px 27.4042px #0cb927)" }),
    width: 56,
    height: "auto",
  })
);

export const TileContainer = styled("div")(({ theme }) => ({
  position: "relative",
  margin: "auto",
  padding: 20,
  [theme.breakpoints.down(breakpoint)]: {
    padding: 0,
  },
}));

export const TileImage = styled("img")(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  height: "100%",
  width: "100%",
  pointerEvents: "none",
  [theme.breakpoints.down(breakpoint)]: {
    display: "none",
  },
}));

const tileSelectedStyle = {
  // backgroundColor: "#3737a7", // no background for now
  pointerEvents: "none",
} as object;
const tileShowStyle = {
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  pointerEvents: "none",
} as object;
const tileBeforeStyle = {
  content: '""',
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  transform: "translate(-50%,-50%)",
  position: "absolute",
  left: "50%",
  top: "50%",
};
export const Tile = styled(Button)(
  ({ theme, value, legends }: { theme?: any; value: any; legends: any }) => ({
    position: "relative",
    height: 68,
    width: 68,
    margin: 5,
    [theme.breakpoints.down(breakpoint)]: {
      height: 53,
      width: 53,
      minWidth: "auto",
    },
    ...((value === undefined || value === null) && {
      backgroundImage: `url("${tileContainerUnselectImage}")`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      "&:before": {
        backgroundImage: `url("${diamondUnselectImage}")`,
        height: 23,
        width: 29,
        ...tileBeforeStyle,
      },
    }),
    ...(value === legends.bomb && {
      ...tileSelectedStyle,
      "&:before": {
        backgroundImage: `url("${bombImage}")`,
        height: 35,
        width: 41,
        ...tileBeforeStyle,
      },
    }),
    ...(value === legends.diamond && {
      ...tileSelectedStyle,
      "&:before": {
        backgroundImage: `url("${diamondImage}")`,
        height: 30,
        width: 39,
        ...tileBeforeStyle,
      },
    }),
    ...(value === legends.showDiamond && {
      backgroundImage: `url("${tileContainerUnselectImage}")`,
      ...tileShowStyle,
      "&:before": {
        backgroundImage: `url("${diamondImage}")`,
        height: 23,
        width: 29,
        ...tileBeforeStyle,
      },
    }),
    ...(value === legends.showBomb && {
      backgroundImage: `url("${tileContainerUnselectImage}")`,
      ...tileShowStyle,
      "&:before": {
        backgroundImage: `url("${bombImage}")`,
        height: 25,
        width: 29,
        ...tileBeforeStyle,
      },
    }),
  })
);

export const MultiplierWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 20,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 5,
  },
}));

export const MultiplierContainer = styled("div")(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  borderWidth: 2,
  width: 430,
  [theme.breakpoints.down(breakpoint)]: {
    width: 220,
  },
  "&:before": {
    content: '""',
    position: "absolute",
    backgroundColor: "#180e58",
    backdropFilter: "blur(8.22614px)",
    right: -5,
    top: -5,
    left: -5,
    bottom: -5,
    borderRadius: 14,
    zIndex: -1,
  },
}));

export const MultiplierContentContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  overflowX: "scroll",
  overflowY: "hidden",
  scrollBehavior: "smooth",
});

export const MultiplierItemContainer = styled("div")(
  ({
    theme,
    isActive,
    isLast,
  }: {
    theme?: any;
    isActive: boolean;
    isLast: boolean;
  }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    borderRadius: 8,
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.down(breakpoint)]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    ...(isActive && {
      backgroundColor: "#019a16",
    }),
    ...(!isLast && {
      "&:before": {
        content: '""',
        background: "#252460",
        position: "absolute",
        bottom: 8,
        right: isActive ? -5 : 0,
        top: 8,
        borderRadius: 5,
        width: 2,
      },
    }),
  })
);
