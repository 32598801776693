import { useDispatch } from "react-redux";

import { SIGNIN_FORM } from "./constants";
import { rememberLogin } from "./";

function useNoSessionAction() {
  const dispatch = useDispatch();

  const _rememberLogin = (data: SIGNIN_FORM) => dispatch(rememberLogin(data));

  return {
    rememberLogin: _rememberLogin,
  };
}

export * from "./constants";

export default useNoSessionAction;
