import { useRef } from "react";
import { useNavigate } from "react-router";

import useObjectState from "../../../../Hooks/useObjectState";
import useUtilityAction from "../../../../Redux/utility/useAction";

interface InitialState {
  openModal: string;
}

interface MenuRef {
  closeMenu: () => void;
}

const initialState: InitialState = {
  openModal: "",
};

const useProfileController = (): any => {
  const menuRef = useRef<MenuRef | null>(null);
  const navigate = useNavigate();
  const { toggleWallet } = useUtilityAction();

  const [state, setState] = useObjectState(initialState);

  const closeModal = () => setState({ openModal: "" });

  const handleItemSelection = (
    route: string,
    isPage?: boolean,
    isWallet?: boolean
  ) => {
    menuRef.current!.closeMenu(); // close menu first before action
    if (isPage) {
      navigate(route);
    } else if (isWallet) {
      toggleWallet();
    } else {
      setState({ openModal: route });
    }
  };

  const handlers = {
    closeModal,
    handleItemSelection,
  };

  return [state, handlers, menuRef];
};

export default useProfileController;
