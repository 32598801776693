import { useState, useEffect } from "react";
import { formatNumberWithLeadingZero } from "./Helpers";

interface DepositPaymentCountdownProps {
  timestamp: any;
}

const DepositPaymentCountdown = ({
  timestamp,
}: DepositPaymentCountdownProps) => {
  const calculateRemainingTime = (timestamp: Date) => {
    const expirationTime: any = new Date(timestamp);
    const currentTime: any = new Date();

    const timeDifference: any = currentTime - expirationTime;

    const remainingMinutes =
      2 - Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const remainingSeconds =
      60 - Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      minutes:
        remainingSeconds === 60 && remainingMinutes !== 30
          ? formatNumberWithLeadingZero(remainingMinutes + 1)
          : formatNumberWithLeadingZero(remainingMinutes),
      seconds:
        remainingSeconds === 60
          ? "00"
          : formatNumberWithLeadingZero(remainingSeconds),
    };
  };

  const [remainingTime, setRemainingTime] = useState(
    calculateRemainingTime(timestamp)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(calculateRemainingTime(timestamp));
    }, 1000);

    return () => clearInterval(intervalId);
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <p style={{ textAlign: "center", fontWeight: "bold" }}>
        {remainingTime.minutes}: {remainingTime.seconds}
      </p>
    </div>
  );
};

export default DepositPaymentCountdown;
