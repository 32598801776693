import { useEffect, useState } from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { green } from "@mui/material/colors";
import { motion } from "framer-motion";

import rankLevelBackground from "../../Assets/icons/rank-level-background.png";
import { pageVariants } from "../../Components/InHouseGames/Constants";
import NextToggle from "../../Components/InHouseGames/ProvidersBar/NextToggle";
import PreviousToggle from "../../Components/InHouseGames/ProvidersBar/PreviousToggle";
import { commafyNumber } from "../../Helpers";
import usePaginate, { PaginationState } from "../../Hooks/usePaginate";
import useScreenSize from "../../Hooks/useScreenSize";
import useUserSelector from "../../Redux/user/useSelector";
import { useConfigSelector } from "../../Redux/utility/useSelector";

import { GradientTitle, SubTitle } from "./styles";
import { customTheme } from "../../Utilities/theme";

const Level = () => {
  const [limit, setLimit] = useState(3);
  const { user } = useUserSelector();
  const config = useConfigSelector();
  const screenWidth = useScreenSize();
  const {
    disableNext,
    disablePrev,
    elements,
    page,
    onNextPage,
    onPrevPage,
  }: PaginationState = usePaginate(
    limit,
    config.vipRanking,
    (item: any, index: number) => {
      const isActive = user?.vip_benefits?.level === item.lvl;
      // prettier-ignore
      const bgSrc = isActive ? rankLevelBackground : rankLevelBackground;

      const GridItem = ({ children }: { children: React.ReactNode }) => (
        <Grid
          display="flex"
          flexDirection="column"
          alignItems="center"
          xs={12}
          item
        >
          {children}
        </Grid>
      );

      return (
        <Grid xs={true} key={index} position="relative" item pb="16px">
          <LevelItemBackground src={bgSrc} />
          <LevelItemBadgeContainer>
            <img alt="level" src={item.image} height="100%" width="auto" />
          </LevelItemBadgeContainer>

          <Grid container gap={1} mt="10px">
            <GridItem>
              <Typography fontWeight={600}>{item.title}</Typography>
            </GridItem>

            <GridItem>
              <SmallTitle>Cashback</SmallTitle>
              <Typography>{item.cashback}</Typography>
            </GridItem>

            <GridItem>
              <SmallTitle>Total Bets</SmallTitle>
              <Box display="flex" flexDirection="row">
                <Typography sx={{ color: green[400] }}>
                  {commafyNumber(item.totalWager, true)}
                </Typography>
                <Typography ml="6px">PHP</Typography>
              </Box>
            </GridItem>

            <GridItem>
              <SmallTitle>Free Spin</SmallTitle>
              <Typography sx={{ color: green[400] }}>
                {item.freeSpin}
              </Typography>
            </GridItem>
          </Grid>

          {isActive && (
            <MyRankContainer>
              <Typography
                color={customTheme.basicBorderColor}
                fontWeight={600}
                variant="caption"
              >
                My Rank
              </Typography>
            </MyRankContainer>
          )}
        </Grid>
      );
    }
  );

  useEffect(() => {
    if (screenWidth <= 500) {
      setLimit(1);
    } else if (screenWidth <= 1040) {
      setLimit(2);
    } else if (screenWidth >= 1041) {
      setLimit(3);
    }
  }, [screenWidth]);

  return (
    <Grid container>
      <Grid xs={12} item mb="8px">
        <Grid direction="row" justifyContent="center" container>
          <GradientTitle display="inline" mr="12px">
            You can reach
          </GradientTitle>
          <GradientTitle display="inline" color="error">
            {config.vipRanking.length} levels
          </GradientTitle>
        </Grid>
      </Grid>

      <Grid xs={12} item>
        <SubTitle>
          Reach the high top Rank - BF LEGEND to get a 25% rebates bonus and
          open all the benefits of the platform. Stay with{" "}
          {config?.siteSettings?.site_name} to receive the best gaming
          experience and excellent service!
        </SubTitle>
      </Grid>

      <Grid xs={12} item>
        <motion.div
          key={page}
          variants={pageVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <Grid container gap={2} wrap="nowrap" alignItems="center">
            {elements}
          </Grid>
        </motion.div>
      </Grid>

      <Grid xs={12} item>
        <ControlContainer>
          <PreviousToggle onClick={onPrevPage} disabled={disablePrev} />
          <div style={{ padding: "4px" }} />
          <NextToggle onClick={onNextPage} disabled={disableNext} />
        </ControlContainer>
      </Grid>
    </Grid>
  );
};

const LevelItemBackground = styled("img")({
  height: "calc(100% - 20px)",
  left: 0,
  verticalAlign: "bottom",
  position: "absolute",
  top: 20,
  width: "100%",
});

const LevelItemBadgeContainer = styled("div")({
  position: "relative",
  margin: "auto",
  height: 80,
  width: 80,
});

const SmallTitle = styled(Typography)({
  color: "#93acd3",
  fontSize: "10px",
  fontWeight: 500,
});

const MyRankContainer = styled("div")({
  // prettier-ignore
  // backgroundImage: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFUAAAAQCAYAAABnYDOFAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFRSURBVHgB7ZjNTQMxEIXfOJcsnOjAoYKkAlqACkg6CAUEdok4IzrYdAAdLBWwDQAuYQ9EiZDswV4kpKAkax8T+5N2L/NsaZ5m/EewyIevIQxJAi7B4oKIJRK+NPZbGHAFLWqVZ4q2qeT9amwDd8ncvTTEdPN+m5X/A7RrhMxXUvT4Ea56ExswqGLdv1I5Ndvi1DXBYL4sCXSNRAuDF5+z0/E+jUDXJPp7ykwKCQsp1ifTLlWnqSo/a5gwQQLEKHa1/IYOnpzPl29WPkS0kPqYZQMfZWel/mH4FTFj+MVX6m2qEb1nRIwR5J2/f6XqdY3fg26UqFlW+Wq9TW03LFCNCHHn0hC9f6XC7X7+68oxEZp3kKnGUJTramjeQaa6x4LQVjh07G2ydHmHjAky1cEak3huWKS0RoFAgk1tq9WsR7Ypno7VXGYo+y+M7o9Cq9TxA7LVfuYNX2buAAAAAElFTkSuQmCC)",
  // backgroundRepeat: "no-repeat",
  // backgroundPosition: "50%",
  backgroundColor: '#100f0c',
  borderBottomLeftRadius: 30,
  borderBottomRightRadius: 30,
  transform: "translate(-50%)",
  position: "absolute",
  left: "50%",
  bottom: -23,
  width: 85,
  textAlign: "center",
});

const ControlContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: 30,
});

Level.defaultProps = {
  wager: 0,
};

export default Level;
