import { styled, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import logo from "../../Assets/Logos/logo.png";
import mLogo from "../../Assets/Logos/mobile-logo.png";
import { useConfigSelector } from "../../Redux/utility/useSelector";

const StyledImage = styled("img")(({ theme }) => ({
  height: "40px",
  zIndex: 1,
  cursor: "pointer",
  marginTop: "8px",
}));

const MainLogo = () => {
  const config = useConfigSelector();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = () => navigate("/");

  const webLogo = config?.siteAssets?.web_logo || logo;
  const mobileLogo = config?.siteAssets?.mobile_logo || mLogo;

  return (
    <StyledImage
      onClick={handleClick}
      src={isMobile ? mobileLogo : webLogo}
      alt="site-logo"
    />
  );
};

export default MainLogo;
