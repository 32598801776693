import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material";
import { drawerWidth } from "../../Constants";
import { SidebarContent } from "./SidebarContent";
import { customTheme } from "../../Utilities/theme";

const StyledDrawer = styled(SwipeableDrawer)(() => ({
  width: drawerWidth,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    background: customTheme.backgroundShadowGradient,
    borderRight: "1px solid " + customTheme.basicBorderColor,
  },
}));

export interface SidebarProps {
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  open: boolean;
}

export function Sidebar({
  handleDrawerOpen,
  handleDrawerClose,
  open,
}: SidebarProps) {
  return (
    <StyledDrawer
      onOpen={handleDrawerOpen}
      onClose={handleDrawerClose}
      anchor="left"
      open={open}
    >
      <SidebarContent handleDrawerClose={handleDrawerClose} />
    </StyledDrawer>
  );
}
