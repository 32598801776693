const customOverrides = (customTheme: any) => ({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // Remove the box shadow and border
          boxShadow: "none",
          border: "none",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          background: "#161f2c",
          ":last-child": { paddingBottom: "15px" },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // backgroundColor: customTheme.transparentBg,
          padding: "25px",
          borderBottom: "1px solid " + customTheme.basicBorderColor,
          color: "background: rgba(188, 188, 188, 1)",
          fontWeight: "bold",
        },
      },
    },
  },
});

export default customOverrides;
