import userApi from "../../../../Service/api/user";

import { handleApiError } from "../../../../Helpers";
import useNoSessionAction, {
  SIGNIN_FORM,
} from "../../../../Redux/noSession/useAction";
import { useConfigSelector } from "../../../../Redux/utility/useSelector";
import socket from "../../../../Utilities/Websocket";

type SignUpFormProps = {
  emailOrNumber: string;
  password: string;
  confirmPassword: string;
  code?: string;
};

type onSubmitSecondParams = {
  setSubmitting: Function;
  setErrors: Function;
  resetForm: Function;
};

function useController() {
  const { rememberLogin } = useNoSessionAction();
  const config = useConfigSelector();

  const _onSignIn = async (
    formData: SIGNIN_FORM,
    { setSubmitting, setErrors }: onSubmitSecondParams
  ) => {
    const payload = {
      email: formData.emailOrNumber,
      password: formData.password,
    };
    const { ok, data }: any = await userApi.login(payload);
    if (ok) {
      socket.emit(config.webSocketEvents.ON_LOGIN, data.user_data);
      if (formData.remember) {
        // if remember me is checked, saved it on redux persist
        // TODO - save email and password to redux persist
        await rememberLogin(formData);
      }

      sessionStorage.setItem("token", data.user_data.access_token);
      window.location.href = "/"; // reload page
    } else {
      setErrors({ api: handleApiError(data) });
    }
    setSubmitting(false);
  };

  const _onSignUp =
    (captchaRef: any) =>
    async (
      formData: SignUpFormProps,
      { setSubmitting, setErrors, resetForm }: onSubmitSecondParams
    ) => {
      // const token = captchaRef.current.getValue();
      const payload = {
        email: formData.emailOrNumber,
        password: formData.password,
        confirm_password: formData.confirmPassword,
        referral_code: formData.code,
        captcha_token: '',
      };
      const { ok, data } = await userApi.createAccount(payload);
      if (ok) {
        // after successful registration, lets login user
        _onSignIn(
          {
            emailOrNumber: formData.emailOrNumber,
            password: formData.password,
            remember: false,
          },
          { setSubmitting, setErrors, resetForm }
        );
      } else {
        setErrors({ api: handleApiError(data) });
        setSubmitting(false);
      }
      captchaRef.current.reset();
    };

  return {
    onSignIn: _onSignIn,
    onSignUp: _onSignUp,
  };
}

export default useController;
