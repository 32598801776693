import { Home, MonetizationOn } from "@mui/icons-material";
import { inhouseGames } from "./Constants";
import routes from "../../Routes/Constants";
import activeAllGamesIcon from "../../Assets/icons/all-games-icon.png";
import inActiveAllGamesIcon from "../../Assets/icons/all-games-icon-inactive.png";
import inactiveSlotsIcon from "../../Assets/icons/slots-icon.png";
import activeSlotsIcon from "../../Assets/icons/slots-icon-active.png";
import inactiveFishingIcon from "../../Assets/icons/fishing-icon.png";
import activeFishingIcon from "../../Assets/icons/fishing-icon-active.png";
import inactiveCasinoIcon from "../../Assets/icons/casino-icon.png";
import activeCasinoIcon from "../../Assets/icons/casino-icon-active.png";
import inactiveInhouseGamesIcon from "../../Assets/icons/inhouse-games-inactive.png";
import activeInhouseGamesIcon from "../../Assets/icons/inhouse-games-active.png";

export const resolveGamePlayer = (game: any) => {
  const { vendor, game_id, category } = game;
  console.log(game);

  return {
    route:
      routes.GAME.THIRD_PARTY_GAME_PLAYER +
      `?game_id=${game_id}&game_vendor=${vendor}&game_category=${category}`,
    state: {
      inPlay: true,
      gameProvider: vendor,
    },
  };
};
export const resolveGameImage = (
  vendor: string,
  game: any,
  gameProviderNames: any
) => {
  switch (vendor) {
    case gameProviderNames.FA_CHAI:
      return game.image;
    case gameProviderNames.JILI:
      return process.env.REACT_APP_API_LINK + "images/" + game.image;
    case gameProviderNames.PRAGMATIC:
      return `${process.env.REACT_APP_PRAGMATIC_GAME_IMAGE}/${game.game_id}.png`;
    default:
      return null;
  }
};
export const resolveGameList = (
  activeGameFilter: string,
  jiliGameList: any
) => {
  switch (activeGameFilter) {
    case "All Games":
      return jiliGameList["All Games"]?.list || [];
    case "In-House":
      return inhouseGames;
    default:
      return jiliGameList[activeGameFilter]?.list || [];
  }
};
export const getCategoryIcon = (categoryName: string, isActive?: boolean) => {
  switch (categoryName) {
    case "All Games":
      return (
        <GameFilterImage
          src={isActive ? activeAllGamesIcon : inActiveAllGamesIcon}
          alt="all-games-icon"
        />
      );
    case "In-House":
      return (
        <GameFilterImage
          src={isActive ? activeInhouseGamesIcon : inactiveInhouseGamesIcon}
        />
      );
    case "Fishing":
      return (
        <GameFilterImage
          src={isActive ? activeFishingIcon : inactiveFishingIcon}
          alt="fishing-icon"
        />
      );
    case "Slot":
      return (
        <GameFilterImage
          src={isActive ? activeSlotsIcon : inactiveSlotsIcon}
          alt="slots-icon"
        />
      );
    case "Poker":
      return <MonetizationOn />;
    case "Casino":
      return (
        <GameFilterImage
          src={isActive ? activeCasinoIcon : inactiveCasinoIcon}
          alt="casino-icon"
        />
      );
    case "Lobby":
      return <Home />;
    default:
      return null;
  }
};

const GameFilterImage = ({ alt, src }: any) => (
  <img src={src} alt={alt} height={18} />
);
