// import BankImage from "../../../Assets/Images/depositBank.png";
// import CryptoImage from "../../../Assets/Images/depositCrypto.png";
import GcashImage from "../../../Assets/icons/gcash-logo.png";
import MayaImage from "../../../Assets/icons/maya-logo.png";
export const bankOptions = [
  // {
  //   label: "GCASH",
  //   image: GcashImage,
  // },
  // {
  //   label: "GCASH v2",
  //   image: GcashImage,
  // },
  {
    label: "GCASH v3",
    image: GcashImage,
    isMaintenance: false,
    size: {
      width: 100,
      height: 100,
    },
  },
  {
    label: "Maya",
    image: MayaImage,
    isMaintenance: false,
    size: {
      width: 180,
      height: 53,
    },
  },
  // {
  //   label: "BANK",
  //   image: BankImage,
  // },
  // {
  //   label: "CRYPTO",
  //   image: CryptoImage,
  // },
];

export const banksWithdraw = [
  { label: "Gcash" },
  { label: "Paymaya (Maya)" },
  // { label: "Union Bank" },
  // { label: "Coins.ph" },
  // { label: "Seabank" },
  // { label: "AllBank" },
  // { label: "Asia United Bank" },
  // { label: "BDO" },
  // { label: "BDO Network Bank" },
  // { label: "BPI/BPI Family Savings Bank" },
  // { label: "BanKo (A subsidiary of BPI)" },
  // { label: "Bangko Mabuhay" },
  // { label: "Bank of Commerce" },
  // { label: "CTBC Bank (Philippines Corp.)" },
  // { label: "Cebuana Lhuillier Rural Bank, Inc." },
  // { label: "China Bank" },
  // { label: "China Bank Savings" },
  // { label: "Development Bank of the Phils." },
  // { label: "Dungganon Bank" },
  // { label: "East West Bank" },
  // { label: "EastWest Rural Bank / Komo" },
  // { label: "Equicom Savings Bank" },
  // { label: "Grab Pay" },
  // { label: "ING Bank N.V." },
  // { label: "Isla Bank Inc." },
  // { label: "Land Bank of the Philippines" },
  // { label: "Maybank Phils. Inc." },
  // { label: "Metrobank" },
  // { label: "OmniPay" },
  // { label: "PNB" },
  // { label: "PSBank" },
  // { label: "Partner Rural Bank" },
  // { label: "Phil. Bank of Communication" },
  // { label: "Phil. Business Bank" },
  // { label: "Phil. Trust Company" },
  // { label: "Philippine Veterans Bank" },
  // { label: "Queszon Capital Rural Bank" },
  // { label: "RCBC/DiskarTech" },
  // { label: "Robinsons Bank" },
  // { label: "Security Bank Corporation" },
  // { label: "Starpay" },
  // { label: "Sterling Bank of Asia" },
  // { label: "Sun Savings Bank" },
  // { label: "UCPB" },
  // { label: "UCPB Savings Bank" },
  // { label: "Wealth Development Bank" },
];
