/**
  Formats a number by adding commas and optional currency sign.
  @param num - The number to format.
  @param asCurrency - Indicates whether to commafy as currency or not. if currency it will show .00 at the end
  @param fixed - The number of decimal places to fix the number to (default: 2).
  @returns The formatted number as a string.
*/
function commafyNumber(num: number, asCurrency: boolean, fixed = 2) {
  if (typeof num !== "number" || isNaN(num)) return num;
  // this function is to fixed the number to 2 decimal places without rounding up from 1.99 to 2
  // note: 1.4555 will not round to 1.46 also 1.999 will not round up to 2. last trailing number after 'fixed' will be removed only
  // reference: https://stackoverflow.com/a/11818658
  let re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  let numStr = num?.toString?.()?.match?.(re)?.[0];
  numStr = Number(numStr).toLocaleString();
  if (asCurrency) {
    // add floating point in the end, this only handle if no floating point at the end such as 1, 2, 99 etc.
    // but condition below should not get executed if the number has already floating point such as 1.19, 2.01, 99.99 etc
    // expect if floating point has only 1 digit we should add 0 at the end if decimal has only 1 digit e.g 1.9 to 1.90
    let [number, decimal] = numStr.split(".");
    if (!decimal) {
      numStr = `${number}.00`;
    } else if (decimal.length === 1) {
      numStr = `${number}.${decimal}0`;
    }
    return numStr;
  }
  return numStr;
}

async function copyToClipboard(textToCopy: string) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";
    // append to body
    document.body.prepend(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}
function mergeGameLists(gameObjects: any[]) {
  let gameObject: any = {};

  gameObjects.forEach((gameObj: any) => {
    Object.entries(gameObj).forEach((categoryEntry: any) => {
      const key = categoryEntry[0];
      const value = categoryEntry[1];
      let mainGameObjectEntry = gameObject[key];

      if (mainGameObjectEntry) {
        gameObject[key] = {
          list: [...mainGameObjectEntry.list, ...value.list],
          count: [...mainGameObjectEntry.list, ...value.list].length,
        };
      } else {
        gameObject[key] = {
          list: [...value.list],
          count: [...value.list].length,
        };
      }
    });
  });

  return gameObject;
}
function createGameCategoriesArr(gameList: any) {
  const games = [
    ...Object.keys(gameList)
      .splice(3)
      .map((key) => ({
        category: key,
        count: gameList[key].count,
      })),
    ...Object.keys(gameList)
      .splice(0, 3)
      .map((key) => ({
        category: key,
        count: gameList[key].count,
      })),
  ];
  return games.filter((e) => e);
}

/**
 * Creates URL parameters from an array of key-value pairs.
 *
 * @param params - An array of objects containing key-value pairs.
 * @returns The URL parameters as a string.
 * NOTE: this function is generated from ChatGPT DO NOT MODIFY
 * TO MODIFY: paste the code to chat gpt and request for modification
 */
export type UrlParams = {
  key: string;
  value: any;
}[];
function createUrlParams(params: UrlParams, addLimit: boolean = true) {
  // Initialize an empty array to store the encoded key-value pairs.
  const pageLimit = 10;
  const encodedParams: string[] = addLimit ? [`limit=${pageLimit}`] : [];
  // Iterate through the params array.
  for (const param of params) {
    // Encode the key and value using encodeURIComponent to handle special characters.
    const encodedKey = encodeURIComponent(param.key);
    const encodedValue = encodeURIComponent(param.value);
    // Concatenate the encoded key-value pair with '=' in between.
    const encodedPair = `${encodedKey}=${encodedValue}`;
    // Push the encoded pair to the encodedParams array.
    encodedParams.push(encodedPair);
  }
  // Join all the encoded key-value pairs with '&' to create the URL parameters string.
  const urlParams = encodedParams.join("&");
  // Return the URL parameters string.
  return urlParams;
}

/**
 * Handles the parsing of an API error response and returns a string.
 * @param {any} response - The API error response to be handled.
 * @returns {string} - The error message extracted from the API response.
 */
function handleApiError(response: any) {
  const error = typeof response === "string" ? response : response?.message;
  return error || "An error occurred. Please try again later.";
}

/**
 * This function filters out non-numeric characters from the input value and updates the state using the provided setState function.
 * @param setState - A function to update the state with the filtered numeric value.
 * @returns A function that takes an event object and extracts the numeric value from the event target's value, then updates the state.
 * example usage: onChange={numberOnly(setBet)} where setBet is for update the state const [bet, setBet] = useState()
 */
function numberOnly(setState: (args?: any) => void) {
  return function (evt: any) {
    const value = evt.target.value;
    let numbers = value.replace(/[^\d.]+|(\..*\.)/g, "$1");

    const firstDotIndex = numbers.indexOf(".");
    if (firstDotIndex !== -1) {
      const decimalPart = numbers
        .slice(firstDotIndex + 1)
        .replace(/\./g, "")
        .slice(0, 2);
      numbers = numbers.slice(0, firstDotIndex + 1) + decimalPart;
    }

    setState(numbers);
  };
}

/**
 * Transforms all snake case keys to camel case keys in an object.
 * @param obj - The object to transform.
 * @returns The transformed object with camel case keys.
 * NOTE: this function is generated from ChatGPT DO NOT MODIFY MANUALY
 * TO MODIFY: paste the code to ChatGPT and request for modification
 */
function snakeKeysToCamelKeys(obj: object) {
  const transformedObj: Record<string, any> = {};
  Object.keys(obj).forEach((key) => {
    const camelKey = key.replace(/_([a-z])/g, (_, char) => char.toUpperCase());
    transformedObj[camelKey] = obj[key as keyof typeof obj];
  });
  return transformedObj;
}

/**
 * Return string as path
 * @param str - a string to convert to path
 * @returns A function a string path e.g: test url -> /test-url
 */
function stringToPath(str: string) {
  return `/${str.toLowerCase().replace(/\s/g, "-")}`;
}

function getMaintenanceMapper(config: any) {
  return {
    [config?.gameCodes?.LIMBO]: config?.disableLimbo,
    [config?.gameCodes?.WHEEL_OF_FORTUNE]: config?.disableWof,
    [config?.gameCodes?.MINES]: config?.disableMines,
    [config?.gameCodes?.HEADS_OR_TAILS]: config?.disableHot,
  };
}

/**
 * @param defaultImage - web site image for default image of game
 * @param image - game image
 * @returns image path as string
 */
const resolveThirdPartyGameImage = (defaultImage: any, image?: string) => {
  if (!image) {
    return defaultImage;
  }
  const hasProtocol = image.includes("http");
  // prettier-ignore
  return hasProtocol ? image : `${process.env.REACT_APP_API_LINK}/images/${image}`;
};
const isIOS = () => {
  const userAgent = navigator.userAgent;

  // Check if the user agent contains "iPhone" or "iPad"
  return /iPhone|iPad/.test(userAgent);
};

export {
  isIOS,
  commafyNumber,
  copyToClipboard,
  createUrlParams,
  handleApiError,
  numberOnly,
  snakeKeysToCamelKeys,
  stringToPath,
  createGameCategoriesArr,
  mergeGameLists,
  getMaintenanceMapper,
  resolveThirdPartyGameImage,
};
