import { useState, Fragment } from "react";
import { Grid, ListItemButton, Typography } from "@mui/material";
import downloadIcon from "../../Assets/icons/download-icon.png";

import {
  IOS_SHARE_INSTRUCTIONS,
  IOS_SHARE_STEP_1,
  IOS_SHARE_STEP_2,
  NavIcon,
  countryOptions,
} from "./Constants";
import { isIOS } from "../../Helpers";
import Modal, { ContentWrapper, SIZES } from "../Common/Modal";
import { AddBoxOutlined, IosShare } from "@mui/icons-material";
import { CustomGridContainer } from "./SidebarContent";

const AdditionalAccess = () => {
  // eslint-disable-next-line
  const [country, setCountry] = useState<any>(countryOptions[0]);
  const [downloadInstructionsModal, setDownloadInstructionsModal] =
    useState(false);
  const isAppleDevice = isIOS();

  const handleAddToHomeScreen = () => {
    window.open("https://playhub.live/api/uploads/playhub.apk");
  };

  return (
    <Fragment>
      <ListItemButton onClick={handleAddToHomeScreen}>
        <CustomGridContainer container spacing={1}>
          <Grid item>
            <NavIcon src={downloadIcon} alt="download-icon" />
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
              Download App
            </Typography>
          </Grid>
        </CustomGridContainer>
      </ListItemButton>

      <Modal
        open={downloadInstructionsModal}
        onClose={() => setDownloadInstructionsModal(false)}
        title={
          <Typography
            fontWeight="bold"
            variant="h5"
            fontSize="1.2rem"
            p="0 24px"
          >
            Add to Home Screen
          </Typography>
        }
        size={SIZES.MEDIUM}
        withGradient
      >
        <ContentWrapper>
          {isAppleDevice ? (
            <AddToHomeScreenIOSInstructions />
          ) : (
            <AddToHomeScreenAndroid />
          )}
        </ContentWrapper>
      </Modal>
      {/* <Grid item xs={12}>
          <Select
            value={country}
            onChange={handleChange}
            fullWidth
            options={countryOptions}
          />
        </Grid> */}
    </Fragment>
  );
};

export default AdditionalAccess;

const AddToHomeScreenIOSInstructions = () => {
  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <Typography>{IOS_SHARE_INSTRUCTIONS}</Typography>
      </Grid>
      <Grid wrap="nowrap" gap={2} item container alignItems="center">
        <Grid item>
          <IosShare />
        </Grid>
        <Grid item>
          <Typography>{IOS_SHARE_STEP_1}</Typography>
        </Grid>
      </Grid>
      <Grid wrap="nowrap" gap={2} item container alignItems="center">
        <Grid item>
          <AddBoxOutlined />
        </Grid>
        <Grid item>
          <Typography>{IOS_SHARE_STEP_2}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
const AddToHomeScreenAndroid = () => {
  return (
    <Grid
      style={{ marginTop: "8px" }}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography>Coming Soon</Typography>
      </Grid>
    </Grid>
  );
};
