import { Box, Grid, IconButton, Typography } from "@mui/material";

import ArrowLeft from "../../../../Assets/icons/arrow-square-left.png";
import GradientTypography from "../../../Common/GradientTypography";

interface LocalProps {
  isProviderSelected: string;
  setProvider: (provider: string) => void;
}

const TransactionModalHeaderNavigator = ({
  isProviderSelected,
  setProvider,
}: LocalProps) => {
  return isProviderSelected ? (
    <IconButton onClick={() => setProvider("")}>
      <img src={ArrowLeft} style={{ width: 24, height: 24, marginRight: 8 }} />
      <GradientTypography>Back</GradientTypography>
    </IconButton>
  ) : null;
};

export default TransactionModalHeaderNavigator;
