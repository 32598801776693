import { Select as MuiSelect, SelectProps, MenuItem } from "@mui/material";
import { customTheme } from "../../Utilities/theme";

type LocalProps = {
  getOptionLabel?: Function;
  labelColor?: string;
  options: Array<any>;
  renderOption?: Function;
};

const Select = ({
  getOptionLabel,
  label,
  labelColor,
  options,
  renderOption,
  ...rest
}: any) => {
  return (
    <MuiSelect
      MenuProps={{
        PaperProps: {
          style: { backgroundColor: customTheme.darkNavBackground },
        },
        MenuListProps: { style: { maxHeight: 316 } },
      }}
      sx={{
        ".MuiSelect-select": {
          color: rest.value ? undefined : labelColor,
        },
        ".Mui-focused": {
          borderColor: "red",
        },
        ".MuiSvgIcon-root": {
          color: "black",
        },
      }}
      {...rest}
    >
      {/* Label */}
      {label && (
        <MenuItem value="" disabled>
          {label}
        </MenuItem>
      )}
      {/* Option Items */}
      {options?.map((d: any, i: number) => {
        if (renderOption) {
          return renderOption(d, i);
        }
        return (
          <MenuItem value={d} key={i} style={{ whiteSpace: "normal" }} divider>
            {d.label || getOptionLabel?.(d)}
          </MenuItem>
        );
      })}
    </MuiSelect>
  );
};

export default Select;
