import { useState } from "react";

import useUtilityAction from "../../../../Redux/utility/useAction";
import userApi from "../../../../Service/api/user";
import useCheckSession from "../../../../Hooks/useCheckSession";

const useClaimCouponController = (onClose: () => void): any => {
  const [code, setCode] = useState<string>("");
  const [isClaimingCode, setIsClaimingCode] = useState<boolean>(false);
  const { toggleSnackBar } = useUtilityAction();
  const checkSession = useCheckSession();

  const onCouponClaim = async () => {
    setIsClaimingCode(true);
    const { ok, data }: any = await userApi.claimCouponCode({ code });

    if (ok) {
      await checkSession();
      setIsClaimingCode(false);
      onClose();
      toggleSnackBar("success", data?.message || "success");
    } else {
      toggleSnackBar("error", data?.message || "error");
      setIsClaimingCode(false);
    }
  };

  const state = {
    isClaimingCode,
    code,
  };
  const handlers = {
    setCode,
    onCouponClaim,
  };

  return [state, handlers];
};

export default useClaimCouponController;
