import { styled, IconButton } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import lodashIsEmpty from "lodash/isEmpty";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import carouselNextIcon from "../../Assets/icons/carousel-next-icon.png";
import carouselPrevIcon from "../../Assets/icons/carousel-prev-icon.png";

import banner1 from "../../Assets/banners/banner1.jpg";
import banner2 from "../../Assets/banners/banner2.jpg";
import banner3 from "../../Assets/banners/banner3.jpg";
import banner4 from "../../Assets/banners/banner4.jpg";
import banner5 from "../../Assets/banners/banner5.jpg";
import banner6 from "../../Assets/banners/banner6.jpg";
import banner7 from "../../Assets/banners/banner7.jpg";

const CustomCarousel = styled(Carousel)(({ theme }) => ({marginBottom: '16px'}));

const FeaturesCarousel = ({ data = [] }: { data?: string[] }) => {
  const carouselImages = lodashIsEmpty(data)
    ? [
        { src: banner1, type: "image" },
        { src: banner2, type: "image" },
        { src: banner3, type: "image" },
        { src: banner4, type: "image" },
        { src: banner5, type: "image" },
        { src: banner6, type: "image" },
        { src: banner7, type: "image" },
        // insert new banner: https://drive.google.com/drive/folders/1xcfUPVO_vdnR0IYGtU2oRd0Qdcz6XLAA?fbclid=IwAR2M3NfkwlsgOfaB2NNeJMOcjIvBSKM618M9sTo8uupY1raB6w0zp-hZBw8
      ]
    : data;

  return (
    <CustomCarousel
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      autoPlay
      infiniteLoop
      renderArrowPrev={(
        clickHandler: () => void,
        hasPrev: boolean,
        label: string
      ) => (
        <CarouselNavIconPrev onClick={hasPrev ? clickHandler : () => {}}>
          <img src={carouselPrevIcon} alt="carousel-prev-icon" />
        </CarouselNavIconPrev>
      )}
      renderArrowNext={(
        clickHandler: () => void,
        hasNext: boolean,
        label: string
      ) => (
        <CarouselNavIconRight onClick={hasNext ? clickHandler : () => {}}>
          <img src={carouselNextIcon} alt="carousel-next-icon" />
        </CarouselNavIconRight>
      )}
    >
      {carouselImages.map((d: any, i) => (
        <div key={i}>
          <img
            alt="carousel-img"
            src={d.src}
            style={{ width: "100%", maxHeight: "600px" }}
          />
        </div>
      ))}
    </CustomCarousel>
  );
};

export default FeaturesCarousel;

const CarouselNavIconPrev = styled(IconButton)(() => ({
  position: "absolute",
  zIndex: 1,
  top: "200px",
}));
const CarouselNavIconRight = styled(IconButton)(() => ({
  position: "absolute",
  zIndex: 1,
  top: "200px",
  right: 0,
}));
