import { Box, Typography } from "@mui/material";
import lodashIsEmpty from "lodash/isEmpty";

function ErrorMessage({ center, error, noMt }: any) {
  if (lodashIsEmpty(error)) {
    return null;
  }
  return (
    <Box
      mt={noMt ? 0 : "-12px"}
      mb="10px"
      textAlign={center ? "center" : "start"}
    >
      <Typography variant="caption" color="error">
        {error}
      </Typography>
    </Box>
  );
}

export default ErrorMessage;
