import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const Snake = () => {
  //make username dynamic

  const username = window.btoa("adriancleofe@gmail.com");

  return (
    <MainContainer container direction="column">
      <button
        onClick={() => {
          window.open(`https://lucky175.site?username=${username}`);
        }}
      >
        Test
      </button>
    </MainContainer>
  );
};

const MainContainer = styled(Grid)(
  () =>
    ({
      position: "absolute",
      left: 0,
      top: "79px",
    } as any)
);

export default Snake;
