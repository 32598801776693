import Button from "../../../Components/Common/Button";
import { COLUMNS } from "../../../Constants/game";

const breakpoint = 755;

const columns = (callback: (args: Array<Array<any>>) => void) => {
  return [
    COLUMNS.result,
    COLUMNS.payout,
    COLUMNS.amount,
    COLUMNS.multiplier,
    { name: "bet_id", label: "Bet ID" },
    {
      name: "mines_board",
      label: "Board Result",
      customBodyRender: (board: Array<Array<any>>) => (
        <Button onClick={() => callback(board)}>View</Button>
      ),
    },
  ];
};


export { breakpoint, columns };
