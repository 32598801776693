// To DELETE

import { styled } from "@mui/material";
import LiveBetsBar from "../../Components/LiveBets";
import TopGames from "../../Components/TopGames";
import AllGames from "../AllGames";
import LiveGamesSection from "./LiveGamesSection";
import { homepageNavItems } from "./constants";
//bookmark
export const renderActiveContent = (content: string) => {
  switch (content) {
    case homepageNavItems[1].code:
      return <AllGames />;
    case homepageNavItems[2].code:
      return <AllGames />;
    case homepageNavItems[3].code:
      return <AllGames />;
    case homepageNavItems[4].code:
      return <LiveGamesSection />;
    default:
      return (
        <HomeGamesFlexCotainer>
          <HomeGamesTopGamesWrapper>
            <TopGames showTitle={false} />
          </HomeGamesTopGamesWrapper>
          <LiveBetsBarWrapper>
            <LiveBetsBar />
          </LiveBetsBarWrapper>
        </HomeGamesFlexCotainer>
      );
  }
};

export const LiveBetsBarWrapper = styled("div")(({ theme }) => ({
  overflow: "hidden",
  width: "85%",
}));
const HomeGamesTopGamesWrapper = styled("div")(({ theme }) => ({
  width: "100%",
}));
const HomeGamesFlexCotainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
