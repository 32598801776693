import { forwardRef, Fragment, useImperativeHandle, useState } from "react";
import { Menu, styled } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import Button from "../Common/Button";
import { customTheme } from "../../Utilities/theme";

type LocalProps = {
  arrow?: boolean;
  backgroundColor?: string;
  buttonProps?: object;
  contentComponent: React.ReactNode;
  triggerComponent: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
};

const ContextMenu = forwardRef(
  (
    {
      arrow,
      backgroundColor,
      buttonProps,
      contentComponent,
      triggerComponent,
      onOpen,
      onClose,
    }: LocalProps,
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const showMenu = !!anchorEl;
    const caretStyle = { width: 24, height: 24 };

    const _closeMenu = () => {
      onClose?.();
      setAnchorEl(null);
    };

    const _openMenu = (event: React.MouseEvent<HTMLElement>) => {
      onOpen?.();
      setAnchorEl(event.currentTarget);
    };

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
      closeMenu: _closeMenu,
    }));

    return (
      <Fragment>
        <StyledButton
          onClick={_openMenu}
          {...(arrow && {
            endIcon: showMenu ? (
              <KeyboardArrowUp sx={caretStyle} />
            ) : (
              <KeyboardArrowDown sx={caretStyle} />
            ),
          })}
          {...buttonProps}
        >
          {triggerComponent}
        </StyledButton>

        <StyledMenu
          anchorEl={anchorEl}
          open={showMenu}
          onClose={_closeMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: backgroundColor || "black",
              border: "1px solid " + customTheme.basicBorderColor,
            },
          }}
        >
          {contentComponent}
        </StyledMenu>
      </Fragment>
    );
  }
);

const StyledMenu = styled(Menu)(({ theme }) => ({
  //eslint-disable-next-line
  ["& .MuiPaper-root"]: {
    marginTop: theme.spacing(1),
    boxShadow: "0px 0px 1px 0px #000000",
    borderRadius: 12,
  },
}));

const StyledButton = styled(Button)({
  padding: 0,
  minWidth: 0,
});

export default ContextMenu;
